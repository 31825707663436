import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  ContractorService,
  OrganizationUserService,
  UserService,
} from "@propertelligent/client-api";
import FilterComponent from "../contractors/FilterComponent";
import UsersRowComponent from "./UsersRowComponent";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import { GetImages } from "../../utils/GetImages";
import searchIcon from "../../assets/searchIcon.png";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SimpleSpinner from "../loader";
import EmptyData from "../common/EmptyData";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  // const [currentSortDirection, setCurrentSortDirection] = useState("asc");

  useEffect(() => {
    if (searchValue !== "") {
      SetFullyQualifiedFilter(
        `Address.StreetAddress.ToLower().Contains("${searchValue.toLowerCase()}")`
      );
    } else {
      SetFullyQualifiedFilter(null);
    }
  }, [searchValue]);

  // const handleSort = () => {
  //   const newSortDirection = currentSortDirection === "asc" ? "desc" : "asc";
  //   setCurrentSortDirection(newSortDirection);
  // };

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  };
  const { rows, loading, hasMore, rowCount, fetchData,currentSortDirection, handleSort, currentPage } =
    infiniteScrollHook({
      httpFetchDataFn:
        OrganizationUserService.getApiOrganizationUserOrganizationlist,
      FullyQualifiedFilters: `User.FirstName.ToLower().Contains("${searchValue.toLowerCase()}")or User.LastName.ToLower().Contains("${searchValue.toLowerCase()}")`,
    });

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasMore, // This can be dynamic based on your API response
    onLoadMore: loadMoreRecords,
    rootMargin: "0px 0px 100px 0px", // Adjust root margin as per your requirement
  });

  return (
    <div>
      <div className="tenantsTopBar">
        <div>
          <p className="tenantHeading">{rowCount} Users</p>
        </div>

        <div className="rightSideButtonsTenant">
          <div className="searchInputContainer">
            <img src={searchIcon} />

            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search by User Name"
            />
          </div>

          <div>
            <Button
              onClick={(e) => {
                navigate("create");
              }}
              className="inviteButton"
            >
              Add Users
            </Button>
          </div>
        </div>
      </div>
      <div className="subTopBar1">
        <FilterComponent />

        <div>
        <div className="subTopBarComponents" onClick={handleSort}>
            <img src={GetImages.sort} />
            <p className="addFiltersButton">
              {currentSortDirection === "asc" ? "Sort by: A-Z" : "Sort by: Z-A"}
            </p>
          </div>
          {/* <div className="subTopBarComponents">
            <img src={GetImages.sort} />
            <p className="addFiltersButton">
              {"asc" === "asc" ? "Sort by: A-Z" : "Sort by: Z-A"}
            </p>
          </div> */}
        </div>
      </div>

      {rows?.length == 0 && !loading ? (
        <EmptyData
          mainText={"No users found"}
          subText={undefined}
          button={undefined}
        />
      ) : (
        <div className="tenantListContainer d-flex flex-column gap-2">
          <UsersRowComponent
            rows={rows}
            loading={loading}
            fetchData={fetchData}
          />
        </div>
      )}

      {loading && (
        <>
          <SimpleSpinner myClassName={rows?.length == 0 ? "h-50" : "h_70"} />
        </>
      )}
      <div ref={sentryRef} />
    </div>
  );
};

export default ManageUsers;
