import { Route, Routes } from "react-router";
import Emailview from "./Email/emailview";
import ChatView from "./Chat/ChatView";
import "./Email/email.css"
import "./Chat/chat.css"

export const Messaging = () => {
  return (
    <Routes>
      <Route path="/email" element={<Emailview />} />
      <Route path="/chat" element={<ChatView />} />
    </Routes>
  );
};
