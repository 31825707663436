import AccountsView from "./view";
import { Route, Routes } from "react-router";
import "./accounting.css"
export const OrganizationAccounts = () => {
  return (
    <Routes>
      <Route index element={<AccountsView />} />
    </Routes>
  );
};
