import { Dropdown, Form } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";

const AccountingDropDown = ({
  selectedType,
  handleTypeChange,
  data,
  handleCategoryChange,
  selectedCategories,
  name,
  sortName,
  isListType = false,
}) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="none" id="filterDropdown">
          {isListType ? (
            <span className="addFiltersButton">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
              >
                <path
                  d="M2.60257 7.43619V2.58234L0.712817 4.47209L0 3.76954L3.10255 0.666992L6.2051 3.76954L5.4923 4.47209L3.60255 2.58234V7.43619H2.60257ZM6.891 13.3336L3.78845 10.2311L4.50125 9.52853L6.39102 11.4183V6.56443H7.39098V11.4183L9.28075 9.52853L9.99355 10.2311L6.891 13.3336Z"
                  fill="#111928"
                />
              </svg>
              {selectedType[sortName] === "asc"
                ? "Sort by: A-Z"
                : "Sort by: Z-A"}
            </span>
          ) : (
            <img src={GetImages.filter} />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="accountingDropdwon">
          <div className=" filter-dropdown-accounting d-flex flex-column justify-content-between p-3">
            <div>
              <p className="FW500_LH20_sub_text">Sort By</p>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label=""
                  value="asc"
                  id="ascRadio"
                  name={sortName}
                  className="FW400_LH20_FS14_label d-flex flex-row gap-2 clickable form-check"
                  checked={selectedType[sortName] === "asc"}
                  onChange={(event) => handleTypeChange(event, sortName)}
                />

                <label
                  className="clickable FW400_LH20_FS14_label"
                  htmlFor="ascRadio"
                >
                  Sort by A to Z
                </label>
              </div>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  value="desc"
                  name={sortName}
                  id="descRadio"
                  className="FW400_LH20_FS14_label d-flex flex-row gap-2 clickable form-check"
                  checked={selectedType[sortName] === "desc"}
                  onChange={(event) => handleTypeChange(event, sortName)}
                />
                <label
                  className="clickable FW400_LH20_FS14_label"
                  htmlFor="descRadio"
                >
                  Sort by Z to A
                </label>
              </div>
            </div>
            <div className="accountingDropdwonFilterMain">
              {!data ? "" : <p className="FW500_LH20_sub_text">Filter By</p>}

              {data?.map((item, i) => (
                <Form.Check
                  key={i}
                  type="checkbox"
                  label={item}
                  value={item}
                  name={name}
                  className="FW400_LH20_FS14_label d-flex flex-row gap-2"
                  checked={selectedCategories.includes(item)}
                  onChange={handleCategoryChange}
                />
              ))}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default AccountingDropDown;
