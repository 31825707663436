// import {
//   PayPaymentDueAccountVM,
//   PaymentTypesEnum,
// } from "@propertelligent/client-api";
// import React, { useState } from "react";
// import { Button, Col, Form, Row } from "react-bootstrap";
// import {
//   isValidPositiveNumber,
// } from "../../../utils/helper";
// import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
// import { GetImages } from "../../../utils/GetImages";
// import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";

// const PayForm = ({ onConfirm, paymentMethodsArr, onClose }) => {
  
//   const [values, setValues] = useState<PayPaymentDueAccountVM>();

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     onConfirm(values);
//   };

//   return (
//     <>
//       <div className="paymentContainer">
//         <Form className="paymentForm" onSubmit={(e) => handleFormSubmit(e)}>
//           {/* <div className="paymentInputsContainer mt-5"> */}
//             <Row className="paymentinputrow">
//               <Col>
//                 <Form.Group controlId="exampleForm.ControlInput1">
//                   <Form.Label className="overview-sub-text">Amount</Form.Label>
//                   <Form.Control
//                     className="overview-sub-text"
//                     required
//                     type="number"
//                     value={values?.amount}
//                     onChange={(e) =>
//                       setValues({
//                         ...values,
//                         amount: isValidPositiveNumber(e?.target?.value),
//                       })
//                     }
//                     placeholder="$   Enter Amount"
//                   />
//                 </Form.Group>
//               </Col>
//               <Col>
//                 <Form.Group controlId="exampleForm.ControlInput1">
//                   <Form.Label className="overview-sub-text">
//                     Payment Method
//                   </Form.Label>
//                   <Form.Select
//                     className="overview-sub-text"
//                     aria-label="Default select Payee Account"
//                     required
//                     value={values?.fromPaymentMethodId}
//                     onChange={(e) =>
//                       setValues({
//                         ...values,
//                         fromPaymentMethodId: Number(e.target.value),
//                       })
//                     }
//                   >
//                     <option value="">Select Payment Method</option>
//                     {paymentMethodsArr?.map((item) => (
//                       <option key={item.id} value={item.id}>
//                         {item.accountName
// }
//                       </option>
//                     ))}
//                   </Form.Select>
//                 </Form.Group>
//               </Col>
//             </Row>
//           {/* </div> */}
//           <div  className="editFormFooter">
//             <div className="footerRightButtons">
//             <AddTraspButton  bname="Cancel" iconPosition={undefined} imageValue={undefined} onClick={onClose} border={false}/>
//               <AddButtonWithArrow
//                 buttonname="Add Payment"
//                 disabled={undefined}
//                 onClick={undefined}
//                 iconDirection="left"  Icon={GetImages.WhiteForwardArrow}
//               />
//             </div>
//           </div>
//         </Form>
//       </div>
//     </>
//   );
// };

// export default PayForm;


import {
  PayPaymentDueAccountVM,
  PaymentTypesEnum,
} from "@propertelligent/client-api";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isValidPositiveNumber } from "../../../utils/helper";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";

const PayForm = ({ onConfirm, paymentMethodsArr, onClose }) => {
  const [values, setValues] = useState<PayPaymentDueAccountVM>();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onConfirm(values);
  };

  return (
    <>
      <div className="paymentContainer">
        <Form className="paymentForm" onSubmit={(e) => handleFormSubmit(e)}>
          {/* <div className="paymentInputsContainer mt-5"> */}
          <Row className="paymentinputrow">
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="overview-sub-text">Amount</Form.Label>
                <Form.Control
                  className="overview-sub-text"
                  required
                  type="number"
                  value={values?.amount}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      amount: isValidPositiveNumber(e?.target?.value),
                    })
                  }
                  placeholder="$   Enter Amount"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="overview-sub-text">
                  Payment Method
                </Form.Label>
                <Form.Select
                  className="overview-sub-text"
                  aria-label="Default select Payee Account"
                  required
                  value={values?.fromPaymentMethodId}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      fromPaymentMethodId: Number(e.target.value),
                    })
                  }
                >
                  <option value="">Select Payment Method</option>
                  {paymentMethodsArr?.map((item) => (
                    <option
                      className="FW400_LH20_FS14_label"
                      key={item.id}
                      value={item.id}
                    >
                      {item.accountName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {/* </div> */}
          <div className="editFormFooter">
            <div className="footerRightButtons">
              <AddTraspButton
                bname="Cancel"
                iconPosition={undefined}
                imageValue={undefined}
                onClick={onClose}
                border={false}
              />
              <AddButtonWithArrow
                buttonname="Add Payment"
                disabled={undefined}
                onClick={undefined}
                iconDirection="left"
                Icon={GetImages.WhiteForwardArrow}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PayForm;

