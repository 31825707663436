import React from "react";
import Empty from "../../assets/Empty.png";
import AddButton from "../firstTimeUserExperience/commonCom/AddButton";
const EmptyData = ({ mainText, subText, button }) => {
  return (
    <>
      <div className="EmptyScreen ">
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
          <img src={Empty} />
          <h6>{mainText}</h6>
          <p className="text-center">{subText}</p>
          {!button ? "" : button}
        </div>
      </div>
    </>
  );
};

export default EmptyData;
