import React, {
    createContext,
    useContext,
    useMemo,
    useState,
    ReactNode,
    useEffect,
  } from "react";
  import { useParams } from "react-router";
  import { AnalyticsService, PaymentService } from "@propertelligent/client-api";
  import { useAnalyticsHook } from "../../hooks/useAnalyticsHook";
  import { usePaginationHook } from "../../hooks/usePaginationHook";
  
  interface CashFlowItem {
    expense?: number;
    income?: number;
  }
  
  interface OverviewContextProps {
    rev_monthValue: number;
    setRev_MonthValue: React.Dispatch<React.SetStateAction<number>>;
    fin_monthValue: number;
    setFin_MonthValue: React.Dispatch<React.SetStateAction<number>>;
    cashFlow: CashFlowItem[];
    expensebycategoryArr: any[]; // Replace `any` with the appropriate type
    fetchAndFormatExpensebycategory: () => void;
    rows: any[]; // Replace `any` with the appropriate type
    fetchData: () => void;
    totalRevenue: number;
    totalExpense: number;
    expensedata: { id: number; name: string; value: number; boxclass: string }[];
    showSuccessAlert: boolean;
    setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  const OverviewContext = createContext<OverviewContextProps | undefined>(
    undefined
  );
  
  export const useOverviewContext = () => {
    const context = useContext(OverviewContext);
    if (context === undefined) {
      throw new Error(
        "useOverviewContext must be used within an OverviewProvider"
      );
    }
    return context;
  };
  
  interface OverviewProviderProps {
    children: ReactNode;
  }
  
  export const OverviewProvider: React.FC<OverviewProviderProps> = ({
    children,
  }) => {
    const { id } = useParams<{ id: string }>();
    const [rev_monthValue, setRev_MonthValue] = useState<number>(-12);
    const [fin_monthValue, setFin_MonthValue] = useState<number>(-12);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  
    const customMonthField = "monthYear";
  
    const {
      cashFlow,
      expensebycategoryArr,
      fetchAndFormatExpensebycategory,
    } = useAnalyticsHook({
      httpExpensebycategoryFn:
        AnalyticsService.getApiAnalyticsPropertyExpensebycategory,
      httpCashflowFn: AnalyticsService.getApiAnalyticsPropertyCashflow,
      customMonthField,
      parentEntityId: Number(id),
      fullyQualifiedFilters: {
        rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
        fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
      },
    });
  
    const { rows, fetchData } = usePaginationHook({
      httpFetchDataFn: PaymentService.getApiPaymentDueAccountPropertylist,
      paginationDataMapper: null,
      parentEntityId: Number(id),
      isDependentEntity: true,
      FullyQualifiedFilters: null,
    });
  
    useEffect(() => {
      const fetchDataAndUpdateState = async () => {
        try {
          await fetchData();
          await fetchAndFormatExpensebycategory();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchDataAndUpdateState();
    }, [fetchData, fetchAndFormatExpensebycategory, id, rev_monthValue, fin_monthValue]);
  
    const { totalExpense, totalRevenue } = useMemo(() => {
      return cashFlow.reduce(
        (accumulator, current) => {
          accumulator.totalExpense += current.expense || 0;
          accumulator.totalRevenue += current.income || 0;
          return accumulator;
        },
        { totalExpense: 0, totalRevenue: 0 }
      );
    }, [cashFlow]);
  
    const expensedata = useMemo(
      () => [
        {
          id: 1,
          name: "Net Profit",
          value: totalRevenue - totalExpense,
          boxclass: "blueBox",
        },
        {
          id: 2,
          name: "Expense",
          value: totalExpense,
          boxclass: "orangeBox",
        },
      ],
      [totalExpense, totalRevenue]
    );
  
    const contextValue = {
      rev_monthValue,
      setRev_MonthValue,
      fin_monthValue,
      setFin_MonthValue,
      cashFlow,
      expensebycategoryArr,
      fetchAndFormatExpensebycategory,
      rows,
      fetchData,
      totalRevenue,
      totalExpense,
      expensedata,
      showSuccessAlert,
      setShowSuccessAlert,
    };
  
    return (
      <OverviewContext.Provider value={contextValue}>
        {children}
      </OverviewContext.Provider>
    );
  };
  