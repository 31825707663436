import getUserLocale from 'get-user-locale';

class DateHelper {
    userLocale = getUserLocale();

    defaultFormat: string;
    constructor() {
      this.defaultFormat = 'yyyy/MM/dd HH:mm a';
    }
    
    /**
     * Input  : dd-mm-yyyy  
     * Output : yyyy/mm/dd
    */
    formatDOBString = (dateTime: string) => {
      const dateValue = dateTime.split('-');

      return `${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`;
    };//End of method
    
    /**
     * Input  : yyyy-mm-ddThh:mm:ss. SSSZ  
     * Output : en-GB --> dd/mm/yyyy, hh:mm:ss a ||
     * Output : en-US --> mm/dd/yyyy, hh:mm:ss a
    */
    convertUTCtoDateTime = (utcDateTime: string, showTime: boolean = false): string | null => {
      if(utcDateTime){
        const utcDate = new Date(utcDateTime);

        // Format the date to local datetime string
        const localDateTimeString =  showTime ? new Intl.DateTimeFormat(this.userLocale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        // timeZone: 'America/New_York', // Change this to your desired time zone
        // timeZoneName: 'short'
        }).format(utcDate) : new Intl.DateTimeFormat(this.userLocale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: true,
          // timeZone: 'America/New_York', // Change this to your desired time zone
          // timeZoneName: 'short'
        }).format(utcDate);
        return localDateTimeString;
      }
      else return '--';
    }; //End of method
    
    /**
     * Input  : yyyy-mm-ddThh:mm:ss. SSSZ  
     * Output : en-GB --> 2 Apr 2024, 8:17 pm ||
     * Output : en-US --> Apr 2, 2024, 8:17 PM
    */
    convertUTCtoDateTimeSimple = (utcDateTime: string, showTime: boolean = false,): string | null => {
      if(utcDateTime){
        const dateObject = new Date(utcDateTime);

        const formattedDate = showTime ? dateObject.toLocaleString(this.userLocale, {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }) : dateObject.toLocaleString(this.userLocale, {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        return formattedDate;
      }
      else return '--';
    }; //End of method


}

export default new DateHelper();
