import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  PayDueAccountStatusEnum,
  PayPaymentDueAccountVM,
  PaymentDueAccountVM,
  PaymentTypesEnum,
} from "@propertelligent/client-api";
import {
  convertENUMtoJSONArray,
  isValidPositiveNumber,
} from "../../../utils/helper";
import RadioButton from "../RadioButton";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import {
  CurrencyInput,
  CustomDatePicker,
  CustomInput,
  CustomInputTextArea,
} from "../formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import "./payment.css";
import SuccessAlert from "../../tenants/SuccessAlert";

const PaymentForm = ({
  showModel,
  entityId,
  payeeAccountsArr,
  onClose,
  onConfirm,
  title,
  paymentMethodsArr,

  selectedPaymentType = null,
  amt,
}) => {
  const paymentDueAccountStatusArr = convertENUMtoJSONArray(PaymentTypesEnum);

  const selectedPaymentTypeObj = selectedPaymentType
    ? { paymentTypeId: selectedPaymentType }
    : {};
  const [values, setValues] = useState<PaymentDueAccountVM>({
    ...selectedPaymentTypeObj,
    entityId: entityId,
    paymentDueAccountStatusId: PayDueAccountStatusEnum.Paid,
    isRecordPayment: true,
  });

  const [payvalues, setPayValues] = useState<PayPaymentDueAccountVM>();
  const [validated, setValidated] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const data = {
      values: {
        ...values,
        // Include isRecord property based on the paymentDueAccountStatusId
        isRecordPayment:
          values.paymentDueAccountStatusId === PayDueAccountStatusEnum.Paid,
      },
      payvalues: payvalues,
    };

    onConfirm(data);
  };

  return (
    <>
      <div className="paymentContainer">
        <Form
          className="paymentForm"
          noValidate
          validated={validated}
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <div className="paymentMode">
            <p className="FW400_LH20_FS14_label">Payment Status</p>
            <div className="paymentRadioButton">
              <RadioButton
                label="Record Payment"
                value={PayDueAccountStatusEnum.Paid}
                name={undefined}
                checked={values?.paymentDueAccountStatusId === 2}
                onChange={(e) =>
                  setValues({
                    ...values,
                    paymentDueAccountStatusId: Number(e.target.value),
                    isRecordPayment: true,
                  })
                }
              />
              <RadioButton
                label="Schedule Payment"
                value={PayDueAccountStatusEnum.Due}
                name={undefined}
                checked={values?.paymentDueAccountStatusId === 1}
                onChange={(e) =>
                  setValues({
                    ...values,
                    paymentDueAccountStatusId: Number(e.target.value),
                    isRecordPayment: false,
                  })
                }
              />
            </div>
          </div>

          <Row className="paymentinputrow">
            {values?.paymentDueAccountStatusId === 2 ? (
              <Col>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="FW400_LH20_FS14_label">
                    Payment Method
                  </Form.Label>
                  <Form.Select
                    className="FW400_LH20_FS14_placeholder"
                    aria-label="Default select Payee Account"
                    required
                    value={payvalues?.fromPaymentMethodId || ""}
                    onChange={(e) =>
                      setPayValues({
                        ...payvalues,
                        fromPaymentMethodId: Number(e.target.value),
                        amount: values.amount,
                      })
                    }
                  >
                    <option className="FW400_LH20_FS14_label" value="">
                      Select Payment Method
                    </option>
                    {paymentMethodsArr?.map((item) => (
                      <option
                        className="FW400_LH20_FS14_label"
                        key={item.id}
                        value={item.id}
                      >
                        {item.accountName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a Payment Method.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}

            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="FW400_LH20_FS14_label">
                  Payee Account
                </Form.Label>
                <Form.Select
                  className="FW400_LH20_FS14_placeholder"
                  required
                  aria-label="Default select Payee Account"
                  name="payeeAccountId"
                  value={values?.payeeAccountId || ""}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      payeeAccountId: Number(e.target.value),
                    })
                  }
                >
                  <option className="FW400_LH20_FS14_label" value="">
                    Select Payee Account
                  </option>
                  {payeeAccountsArr.map((item) => (
                    <option
                      className="FW400_LH20_FS14_label"
                      key={item.id}
                      value={item.id}
                    >
                      {item.accountName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a Payee Account.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* )} */}

          <Row className="paymentinputrow">
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="FW400_LH20_FS14_label">
                  Due Date
                </Form.Label>
                <CustomDatePicker
                  name={"dueDate"}
                  required
                  type={FORM_CONTROLFIELD_TYPES.DATE}
                  isFormControl
                  value={values?.dueDate || ""}
                  onChange={(e) =>
                    setValues({ ...values, dueDate: e.target.value })
                  }
                  placeholder="Select date"
                  myClassName="FW400_LH20_FS14_placeholder"
                  min={
                    values?.paymentDueAccountStatusId === 1
                      ? new Date().toISOString().split("T")[0]
                      : undefined
                  } // Disable past dates
                  max={
                    values?.paymentDueAccountStatusId === 2
                      ? new Date().toISOString().split("T")[0] // Disable future dates for "Record Payment"
                      : undefined // Allow future dates for "Schedule Payment"
                  }
                />

                <Form.Control.Feedback type="invalid">
                  Please select a valid Date.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="FW400_LH20_FS14_label">
                  Payment Type
                </Form.Label>
                <Form.Select
                  className="FW400_LH20_FS14_placeholder"
                  aria-label="Default select Payee Account"
                  required
                  value={values.paymentTypeId}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      paymentTypeId: Number(e.target.value),
                    });
                  }}
                  disabled={!!selectedPaymentType}
                >
                  <option className="FW400_LH20_FS14_label" value="">
                    Select Payment Type
                  </option>
                  {paymentDueAccountStatusArr?.map((item) => (
                    <option
                      className="FW400_LH20_FS14_label"
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a Payment Type.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="paymentinputrow">
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="FW400_LH20_FS14_label">
                  Amount
                </Form.Label>
                <CurrencyInput
                  name={"amount"}
                  required
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  value={
                    values?.amount !== undefined
                      ? values.amount
                      : amt !== undefined
                      ? amt
                      : ""
                  }
                  onChange={(e) => {
                    const enteredAmount = isValidPositiveNumber(e.target.value);
                    setValues({ ...values, amount: enteredAmount });
                    setPayValues({ ...payvalues, amount: enteredAmount });
                  }}
                  placeholder="Enter amount"
                  myClassName="FW400_LH20_FS14_placeholder"
                />

                <Form.Control.Feedback type="invalid">
                  Enter Amount.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              {/* {values.paymentDueAccountStatusId == 1 ? ( */}
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="FW400_LH20_FS14_label">
                  Grace Period
                </Form.Label>
                <CustomInput
                  name="gracePeriodInDays"
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  value={values?.gracePeriodInDays}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      gracePeriodInDays: isValidPositiveNumber(e?.target?.value)
                        ? isValidPositiveNumber(e?.target?.value)
                        : 0,
                    })
                  }
                  placeholder="Grace Period (InDays)"
                  myClassName="FW400_LH20_FS14_placeholder"
                />
              </Form.Group>
              {/* ) : (
                ""
              )} */}
            </Col>
          </Row>
          <Row className="paymentinputrow">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="FW400_LH20_FS14_label">
                Description
              </Form.Label>
              <CustomInputTextArea
                name="description"
                placeholder="Enter Description"
                required
                isFormControl
                value={values?.description}
                onChange={(e) =>
                  setValues({ ...values, description: e?.target?.value })
                }
                myClassName="FW400_LH20_FS14_placeholder"
              />
            </Form.Group>
          </Row>

          <div className="editFormFooter ">
            <AddTraspButton
              bname="Cancel"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={onClose}
              border={false}
            />

            <AddButtonWithArrow
              buttonname="Add Payment"
              disabled={undefined}
              onClick={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default PaymentForm;
