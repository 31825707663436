import NameAvatarComponent from "./NameAvatarComponent";

const SingleNoteComponent = ({ noteData, date, name }) => {

  return (
    <div className="singleMessage d-flex align-items-start gap-2 mb-2">
      <NameAvatarComponent
        heightWidth={32}
        firstName={name?.split(" ")[0] || ""}
        lastName={name?.split(" ")[1] || ""}
      />
      <div className="w-100">
        <p className="messageTextStyle w-100">{noteData && noteData}</p>
        <p className="messageDateAndTime">{date}</p>
      </div>
    </div>
  );
};

export default SingleNoteComponent;
