import React, { ChangeEvent, useEffect, useState } from "react";
import {
  emailRegex,
  mobileNumberRegex,
  nameRegex,
  websiteRegex,
} from "../common/Regex";
import EditCustomComponent from "../tenants/EditCustomComponent";
import { OrganizationService } from "@propertelligent/client-api";
import { Form } from "react-bootstrap";
import SuccessAlert from "../tenants/SuccessAlert";
import { UserLoginData } from "../common/charts/Enums";
import { error } from "console";
import { GetImages } from "../../utils/GetImages";
import apiHelper from "../../utils/apiHelper";

const BasicDetailsComponent = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [websiteError, setWebsiteError] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [emergencyPhoneNumberError, setEmergencyPhoneNumberError] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState({
    name: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
    website: "This field is required",
    address: "This field is required",
    emergencyPhoneNumber: "This field is required",
  });
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    website: "",
    address: "",
    emergencyPhoneNumber: "",
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    const organizationId = Number(
      localStorage.getItem(UserLoginData.organization_id)
    );

    OrganizationService.getApiOrganization1(organizationId)
      .then((res) => {
        setValues({
          ...values,
          name: res?.success?.name,
          email: res?.success?.email,
          phoneNumber: res?.success?.phoneNumber,
          website: res?.success?.website,
          address: res?.success?.address?.streetAddress,
          emergencyPhoneNumber: res?.success?.emergencyPhoneNumber,
        });
      })
      .catch((error) => {});
  };

  const validateName = (): boolean => {
    if (values.name.length === 0) {
      setNameError(false);
      return false;
    }

    if (values.name.trim().length > 0) {
      if (nameRegex.test(values.name)) {
        setNameError(false);
      } else if (
        values.name.trim().length < 2 ||
        values.name.trim().length > 40
      ) {
        setNameError(true);
        setErrorMessages({
          ...errorMessages,
          name: "First Name should have 2-20 characters",
        });
      } else {
        setNameError(true);
        setErrorMessages({
          ...errorMessages,
          name: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };
  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase();
      setValues({ ...values, email: lowercaseEmail });
      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };
  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     if (emailRegex.test(values.email)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const validateWebsite = (): boolean => {
    if (values.website.trim().length > 0) {
      if (websiteRegex.test(values.website)) {
        setWebsiteError(false);
      } else {
        setWebsiteError(true);
        setErrorMessages({
          ...errorMessages,
          website: "Please enter correct website",
        });
        return true;
      }
    }

    return false;
  };

  const validateNumber = (type): boolean => {
    if (type === 1) {
      if (values.phoneNumber.trim().length > 0) {
        if (mobileNumberRegex.test(values.phoneNumber)) {
          setPhoneNumberError(false);
        } else {
          setPhoneNumberError(true);
          setErrorMessages({
            ...errorMessages,
            phoneNumber: "Please enter correct phone number",
          });
          return true;
        }
      }

      return false;
    } else {
      if (values.emergencyPhoneNumber.trim().length > 0) {
        if (mobileNumberRegex.test(values.emergencyPhoneNumber)) {
          setEmergencyPhoneNumberError(false);
        } else {
          setEmergencyPhoneNumberError(true);
          setErrorMessages({
            ...errorMessages,
            emergencyPhoneNumber: "Please enter correct phone number",
          });
          return true;
        }
      }

      return false;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "name":
        setNameError(false);
        setErrorMessages({
          ...errorMessages,
          name: "This field is required",
        });
        break;
      case "website":
        setWebsiteError(false);
        setErrorMessages({
          ...errorMessages,
          website: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
      case "address":
        setAddressError(false);
        setErrorMessages({
          ...errorMessages,
          address: "This field is required",
        });
        break;
      case "emergencyPhoneNumber":
        setEmergencyPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          emergencyPhoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const formDetails = [
    {
      label: "Name",
      placeholder: "Enter name",
      value: values.name,
      name: "name",
      blurFunction: validateName,
      errorMessages: errorMessages.name,
      isError: nameError,
      linkWebsite: false,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: () => validateNumber(1),
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
      linkWebsite: false,
    },

    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
      linkWebsite: false,
    },

    {
      label: "Website link",
      placeholder: "Enter website",
      value: values.website,
      name: "website",
      blurFunction: validateWebsite,
      errorMessages: errorMessages.website,
      isError: websiteError,
      linkWebsite: true,
    },
  ];

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.name.trim() === "") {
      setNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }

    if (values.address?.trim() === "") {
      setAddressError(true);
    }
  };

  const submitTimeError = () => {
    return (
      nameError ||
      emailError ||
      phoneNumberError ||
      emergencyPhoneNumberError ||
      addressError ||
      websiteError ||
      values.name.trim() === "" ||
      values.address?.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === ""
    );
  };

  const handleAddDetails = (e) => {
    e.preventDefault();
    checkEmptyFields();
    if (submitTimeError()) {
    } else {
      const newAddress = { streetAddress: values.address };
      const newValues = {
        ...values,
        address: newAddress,
        id: Number(localStorage.getItem(UserLoginData.organization_id)),
      };
      apiHelper(OrganizationService.putApiOrganization, { successMessage : "Basic Details Updated Successfully", failureMessage : "Unable to Update Basic Details", showNotification : true }, newValues)
      .then(({data}) => {
        setValues({
          ...values,
          name: "",
          email: "",
          phoneNumber: "",
          address: "",
          emergencyPhoneNumber: "",
          website: "",
        });
        getUserData();
      })
     }
  };

  return (
    <Form onSubmit={handleAddDetails}>
      <div className="basicDetailsContainer">
        <div className="newTenantFormHeading">Basic Details</div>
        <div className="d-flex addContractorInputContainer1">
          {formDetails.map((item) => {
            return (
              <EditCustomComponent
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                value={item.value}
                name={item.name}
                onChangeFunction={handleChange}
                onBlurFunction={item.blurFunction}
                errorMessages={item.errorMessages}
                isError={item.isError}
                linkWebsite={item.linkWebsite}
                width="332px"
                borderRadius="4px"
              />
            );
          })}
        </div>
        {/* <div>
          <p className="fixedLabels">Address</p>
          <div className="d-flex align-items-center">
            <textarea
              className="addressTextarea"
              placeholder="Enter full address"
              name="address"
              value={values.address}
              onChange={handleChange}
            />
          </div>
          <div>
            {addressError && (
              <p className="errorMessage"> {errorMessages.address} </p>
            )}
          </div>
        </div> */}
        <div className="helpAndSupportContainer">
          <div className="newTenantFormHeading">Help & Support</div>
          <div className="helpAndSupportDescription pt-1">
            Emergency Contact Info - For Tenant
          </div>
          <div className="d-flex gap-3 mt-3 flex-wrap">
            <div>
              <div
                className="d-flex align-items-center "
                style={{ position: "relative" }}
              >
                <input
                  className="emailNumberInput"
                  placeholder="Enter contact email"
                />
                <img
                  style={{ position: "absolute", right: "12px" }}
                  width={20}
                  height={20}
                  src={GetImages.mailGrey}
                />
              </div>
            </div>
            <div>
              <div
                className="d-flex align-items-center"
                style={{ position: "relative" }}
              >
                <input
                  className="emailNumberInput"
                  placeholder="Enter contact number"
                  onChange={handleChange}
                  value={values.emergencyPhoneNumber}
                  onBlur={() => validateNumber(2)}
                  name="emergencyPhoneNumber"
                />
                <img
                  style={{ position: "absolute", right: "12px" }}
                  width={20}
                  height={20}
                  src={GetImages.phoneGrey}
                />
              </div>
              <div>
                {emergencyPhoneNumberError && (
                  <p className="errorMessage">
                    {" "}
                    {errorMessages.emergencyPhoneNumber}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <button type="submit" className="saveChangesButton">
            Save Changes <img src={GetImages.WhiteForwardArrow} />
          </button>
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          message={"Basic Details Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </Form>
  );
};

export default BasicDetailsComponent;
