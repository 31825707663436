import { Route, Routes } from "react-router";
import { UnitCreate } from "./create";
import EditUnit from "./editunit";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { useEffect, useState } from "react";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { UnitService } from "@propertelligent/client-api";
import "./unit.css";
import "./style.css";
import "./ViewItem/styles.css";

export const Units = () => {
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const {
    rows,
    loading,
    hasMore,
    rowCount,
    fetchData,
    currentSortDirection,
    handleSort,
    currentPage,
  } = infiniteScrollHook({
    httpFetchDataFn: UnitService.getApiUnitOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts: fulllyQualifiedSort,
  });

  useEffect(() => {
    if (searchValue !== "") {
      SetFullyQualifiedFilter(
        `UnitNumber.ToLower().Contains("${searchValue.toLowerCase()}")`
      );
    } else {
      SetFullyQualifiedFilter(null);
    }
  }, [searchValue]);

  useEffect(() => {
    SetFulllyQualifiedSort("UnitNumber " + currentSortDirection);
  }, [currentSortDirection]);

  return (
    <Routes>
      <Route
        index
        element={
          <ContentTopBottom
            FixedTopContent={() => (
              <TopFixedView
                rowCount={rowCount}
                currentSortDirection={currentSortDirection}
                handleSort={handleSort}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            )}
            FixedBottomContent={() => (
              <MainScrollView
                rows={rows}
                loading={loading}
                hasMore={hasMore}
                fetchData={fetchData}
                currentPage={currentPage}
              />
            )}
          />
        }
      />
      <Route path="/create" element={<UnitCreate />} />
      <Route path="/edit/:id" element={<EditUnit />} />
    </Routes>
  );
};
