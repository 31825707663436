import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { LoginService } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import { MyContext } from "../utils/context/LoginContext";
import NameAvatarComponent from "../components/tenants/NameAvatarComponent";
import Localizer from "../utils/i18nConfig";
import profilePic from "../assets/profilePic.png";
import downIcon from "../assets/downArrow.png";
import logoutButton from "../assets/logoutButton.png";
import organizationSetting from "../assets/organizationSetting.png";
import { URLS } from "../constants/constants";
import "./UserName.css";
import { NotificationType, PubSub, TopicsEnum } from "../utils/pubsub";

const UserName = ({ size }) => {
  const navigate = useNavigate();

  const firstName = localStorage.getItem(UserLoginData.user_name).split(" ")[0];
  const lastName = localStorage.getItem(UserLoginData.user_name).split(" ")[1];

  const navigateToOrganization = () => {
    navigate(URLS.ORGANIZATION);
  };

  const logoutFunction = () => {
    PubSub.publish(TopicsEnum.Logout, {
      message: "Successfully logged out",
      type: NotificationType.Success
    });    
  };

  return (
    <div className="appBarRightSide">
      <div className="profilePicStyles">
        <NameAvatarComponent
          firstName={firstName}
          lastName={lastName}
          heightWidth={28}
          fromTopBar={true}
          fontSize="12px"
          color="#1A56DB"
          backgroundColor="#E1EFFE"
        />
      </div>

      <Dropdown className="nameBox">
        <Dropdown.Toggle className="nameStyles" id="dropdown-basic">
          {size > 400 ? localStorage.getItem(UserLoginData.user_name) : ""}
          <div className="imgBox">
            <img width={16} height={16} src={downIcon} />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdownBoxUsername">
          <Dropdown.Item
            className="d-flex gap-2 itemFromUsername justify-content-start align-items-center "
            onClick={navigateToOrganization}
          >
            <img width={20} height={20} src={organizationSetting} />{" "}
            <p className="dropdownTextStyles">
              {" "}
              {`${Localizer.getLocalizedString("my_org")}`}{" "}
            </p>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex gap-2 itemFromUsername justify-content-start align-items-center"
            onClick={logoutFunction}
          >
            <img width={20} height={20} src={logoutButton} />{" "}
            <p className="dropdownTextStyles">{`${Localizer.getLocalizedString(
              "logout"
            )}`}</p>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserName;
