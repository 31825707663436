import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CommanStyles/style.css";

const CurrencyInput = ({
  isFormControl = true, value, required = false,
  disabled = false, label= '', placeholder= '',
  type= "text", name, currencySymbol= '$',
  myClassName = "", errorMessage = '',  isError,
  focus = false,  onChange, onBlur = () => {},
}: any) => {
  

  const handleChange = (event: any) => {
    onChange(event);
  };

  let injectErrorClasses = ''; 

  if(typeof isError == "boolean"){
    injectErrorClasses = isError ? "errorBorder errorOutline" : "normalBorder normalOutline";
  }

  return (
    <>
      {isFormControl ? (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text removeRightBorder h-100">{currencySymbol}</span>
          </div>
          <Form.Control
            className={`${injectErrorClasses} ${myClassName}`}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onBlur={onBlur}
            required={required}
            onChange={handleChange}
            disabled={disabled}
            autoFocus={focus}
          />
        </div>
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          className={`${injectErrorClasses} ${myClassName}`}
          disabled={disabled}
          autoFocus={focus}

        />
      )}
      {isError && (
        <div className="mt-2">
          <p className="errorMessageStyles">{errorMessage}</p>
        </div>
      )}
    </>
  )
};

export default CurrencyInput;
