import React, { useEffect, useMemo, useState } from "react";
import Progressbar from "./commonCom/Progressbar";
import FormFooter from "./commonCom/FormFooter";
import {
  PropertyService,
  UnitService,
  UnitTypesEnum,
} from "@propertelligent/client-api";
import { AvatarWithLoading } from "../common/avator";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUnit, getTenantList } from "../../redux/actions/unitAction";
import UnitTanentForm from "./UnitTanentForm";
import { GetImages } from "../../utils/GetImages";
import { Button, Offcanvas } from "react-bootstrap";
import AddButtonWithArrow from "./commonCom/AddButtonWithArrow";
import { ViewItem } from "../units/ViewItem/ViewItem";
import { URLS } from "../../constants/constants";
import SuccessAlert from "../tenants/SuccessAlert";

const AddUnitTanent = ({ navigateto }) => {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setISEdit] = useState<boolean>(false);
  const [unitId, setunitId] = useState<Number>();
  const [Open, setOpen] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const { isLoading } = useSelector((state: any) => state?.Comman);
  const { unitList } = useSelector((state: any) => state.unitList);
  const [alertmessage, setAlertMessage] = useState("");

  useEffect(() => {
    dispatch(getTenantList(location?.state?.id));
  }, [dispatch, isEdit, location?.state?.id, unitId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PropertyService.getApiProperty1(
          location?.state?.id
        );
        setPropertyData(response.success);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location?.state?.id]);

  useMemo(() => {}, [unitList?.length, isEdit, unitId]);

  function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(UnitTypesEnum).filter(
      (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
    );
    return keys.length > 0 ? keys[0] : undefined;
  }

  const handleCancel = async () => {
    try {
      await PropertyService.deleteApiProperty(location?.state?.id);
      unitList.forEach(async (unit) => {
        try {
          await UnitService.deleteApiUnit(unit.id);
        } catch (error) {
          console.error("Error deleting unit:", error);
        }
      });
      navigate("/properties");
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  const handleFinish = async () => {
    navigate("/properties");
  };

  const handleItemClick = (id: string | undefined) => {
    navigate(`${URLS.EDIT_UNIT}/${id}`);
  };

  const handleInviteTenantClick = (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    navigate(URLS.CREATE_TENANT);
  };

  return (
    <>
      <div className="AddProperty">
        <div className="firstAddmain ">
          <div className="progressSection">
            <Progressbar stepname="Add Unit" stepnum="3/3" />
          </div>

          {propertyData && (
            <div className="unitlistContainer">
              <div
                className="unitListItem m-3"
                style={{ border: "1px solid rgb(229, 231, 235)" }}
              >
                <div className="d-flex align-items-center gap-3">
                  <AvatarWithLoading
                    docNumber={propertyData?.mainImageId}
                    avatar={true}
                    Size="Icon40"
                  />
                  <div className="propertyDetailsCard">
                    <p className="propertyAddress">
                      {propertyData.address.streetAddress}
                    </p>
                    <div className="d-flex gap-2">
                      <p className="propertyAddressDetails showaddress">
                        City : <span>{propertyData.address.city}</span>
                      </p>
                      <p className="propertyAddressDetails showaddress">
                        State : <span>{propertyData.address.state}</span>
                      </p>
                      <p className="propertyAddressDetails">
                        Zip : <span>{propertyData.address.zip}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="mainUnitTanent h-100"
            style={{ overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="ListContainer">
              {unitList?.length === 0 ? (
                <div
                  className="unitformWrapper mt-4"
                  style={{ overflow: "hidden" }}
                >
                  <img src={GetImages.unitIcon} />
                  <p className="FW600_LH28_main_text">
                    Add units to your property
                  </p>
                  <AddButtonWithArrow
                    buttonname={"Add unit"}
                    disabled={undefined}
                    onClick={() => setOpen(true)}
                    iconDirection="left"
                    Icon={GetImages.WhiteForwardArrow}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-between mb-3 w-100">
                  <div className="unitCount">{unitList?.length} Units</div>
                  <div>
                    <AddButtonWithArrow
                      buttonname={"Add unit"}
                      disabled={undefined}
                      onClick={() => setOpen(true)}
                      iconDirection="left"
                      Icon={GetImages.WhiteForwardArrow}
                    />
                  </div>
                </div>
              )}

              <div className="tenantListContainer w-100 p-0">
                <div style={{ marginBottom: "8px" }} className="unit-item-list">
                  <ViewItem
                    rows={unitList}
                    loading={isLoading}
                    handleItemClick={(id) => {
                      setISEdit(true);
                      setunitId(id);
                    }}
                    handleInviteTenantClick={handleInviteTenantClick}
                    showTarget={true}
                    notFoundPage={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <Offcanvas
            className="offcanvas"
            show={Open}
            onHide={() => setOpen(false)}
            placement={"end"}
            scroll={true}
          >
            <Offcanvas.Header className="canvasHeader" closeButton>
              <Offcanvas.Title>Add Unit</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="unitformSection">
                <UnitTanentForm
                  setAlertMessage={setAlertMessage}
                  showSuccessAlert={showSuccessAlert}
                  setShowSuccessAlert={setShowSuccessAlert}
                  showDeleteAlert={showDeleteAlert}
                  setShowDeleteAlert={setShowDeleteAlert}
                  showUpdateAlert={showUpdateAlert}
                  setShowUpdateAlert={setShowUpdateAlert}
                  id={location?.state?.id}
                  setShowModel={setOpen}
                  unitId={unitId}
                  isEdit={isEdit}
                  setISEdit={setISEdit}
                  canvasbody={undefined}
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            className="offcanvas"
            show={isEdit}
            onHide={() => setISEdit(false)}
            placement={"end"}
            scroll={true}
          >
            <Offcanvas.Header className="canvasHeader" closeButton>
              <Offcanvas.Title>Edit Unit</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="unitformSection">
                <UnitTanentForm
                  setAlertMessage={setAlertMessage}
                  showSuccessAlert={showSuccessAlert}
                  setShowSuccessAlert={setShowSuccessAlert}
                  id={location?.state?.id}
                  showDeleteAlert={showDeleteAlert}
                  setShowDeleteAlert={setShowDeleteAlert}
                  showUpdateAlert={showUpdateAlert}
                  setShowUpdateAlert={setShowUpdateAlert}
                  setShowModel={setOpen}
                  unitId={unitId}
                  isEdit={isEdit}
                  setISEdit={setISEdit}
                  canvasbody={undefined}
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          {unitList?.length === 0 && (
            <div className="footer d-flex justify-content-center mb-4">
              <p>
                Want to add units later?{" "}
                <span
                  className="skipForNow"
                  onClick={() => navigate("/properties")}
                >
                  Skip for now
                </span>
              </p>
            </div>
          )}
          {unitList?.length !== 0 && (
            <FormFooter
              handleSubmit={undefined}
              buttonname={"Finish"}
              setNextvalue={undefined}
              nextvalue={undefined}
              propertyValue={undefined}
              navigateto={undefined}
              showCancelButton={true}
              handleCancel={handleCancel}
              handleFinish={handleFinish}
            />
          )}
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          message={"Unit Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
      {showUpdateAlert && (
        <SuccessAlert
          message={"Unit Updated Successfully"}
          setShowSuccessAlert={setShowDeleteAlert}
        />
      )}
      {showDeleteAlert && (
        <SuccessAlert
          message={"Unit Deleted Successfully"}
          setShowSuccessAlert={setShowDeleteAlert}
        />
      )}
    </>
  );
};

export default AddUnitTanent;
