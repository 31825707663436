import { Language_Type } from "../../constants/constants";
import i18nConfig from "./i18nConfig";

class I18nConfigHelper {
  
  getLocalizedString(key: string): string {
    return i18nConfig.t(key);
  }

  changeLanguage(language: Language_Type): void {
    i18nConfig.changeLanguage(language);
  }

}

export default new I18nConfigHelper();