import React, { ChangeEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import EditCustomComponent from "../tenants/EditCustomComponent";
import NewSelectAuto from "../tenants/NewSelectAuto";

import {
  ContractorService,
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  LookupService,
  LookupTypesEnum,
} from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { CustomDatePicker } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";

const EditContractorForm = ({
  id,
  setShowModal,
  setShowSuccessAlert,
  setAlertMessage,
}) => {
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [isServiceTypeDisabled, setIsServiceTypeDisabled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [isInHouseValue, setIsInHouseValue] = useState(true);
  const [subServiceTypeData, setSubServiceTypeData] = useState([]);
  const [serviceTypeSelectError, setServiceTypeSelectError] = useState(false);
  const [subServiceTypeError, setSubServiceTypeError] = useState(false);
  const [isSubServiceTypeDisabled, setIsSubServiceTypeDisabled] =
    useState(false);
  const [selectedSubServiceType, setSelectedSubServiceType] = useState(null);
  const [licenseExpiryDate, setLicenceExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [uploadLicenseFileName, setUploadLicenseFileName] =
    useState<any>("License Document");
  const [uploadLicenseDocument, setUploadLicenseDocument] = useState<any>(null);
  const [uploadInsuranceFileName, setUploadInsuranceFileName] =
    useState("Insurance Document");
  const [uploadInsuranceDocument, setUploadInsuranceDocument] = useState(null);
  const [insuranceDocumentId, setInsuranceDocumentId] = useState(null);
  const [licenseDocumentId, setLicenseDocumentId] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
    serviceType: "Please select service type",
    subServiceType: "Please select sub service type",
  });

  const [serviceTypeFields, setServiceTypeFields] = useState([]);
  const [subServiceTypeFields, setSubServiceTypeFields] = useState([]);

  useEffect(() => {
    getContractorData();
  }, []);

  useEffect(() => {
    if (selectedServiceType) {
      getSubServiceType(selectedServiceType);
    }
  }, [selectedServiceType]);

  const getSubServiceType = (x) => {
    LookupService.getApiLookup1(LookupTypesEnum.ServiceSubTypes, x)
      .then((res) => setSubServiceTypeFields(res.success))
      .catch((error) => {});
  };

  const getServiceTypeFields = async () => {
    LookupService.getApiLookup(LookupTypesEnum.ServiceTypes)
      .then((res) => {
        setServiceTypeFields(res.success);
      })
      .catch((error) => {});
  };

  const getContractorData = async () => {
    const contractorResponse = await ContractorService.getApiContractor1(
      Number(id)
    );
    if (contractorResponse.success) {
      setValues({
        ...values,
        firstName: contractorResponse.success.firstName,
        lastName: contractorResponse.success.lastName,
        email: contractorResponse.success.email,
        phoneNumber: contractorResponse.success.phoneNumber,
      });

      setIsInHouseValue(contractorResponse.success.isInHouse);

      getServiceTypeFields();

      if (contractorResponse.success.serviceTypesIds.length > 0) {
        setSelectedServiceType(contractorResponse.success.serviceTypesIds[0]);
        getSubServiceType(contractorResponse.success.serviceTypesIds[0]);
      }

      if (contractorResponse.success.serviceSubTypesIds.length > 0) {
        setSelectedSubServiceType(
          contractorResponse.success.serviceSubTypesIds[0]
        );
      }

      if (contractorResponse.success.currentInsuranceDocumentId) {
        setInsuranceDocumentId(
          contractorResponse.success.currentInsuranceDocumentId
        );
        const insuranceDocumentResponse =
          await DocumentService.getApiDocumentDetails(
            contractorResponse.success.currentInsuranceDocumentId
          );

        setInsuranceExpiryDate(
          insuranceDocumentResponse.success.validUntil.substring(0, 10)
        );

        setUploadInsuranceDocument(
          contractorResponse.success.currentInsuranceDocumentId
        );
      }

      if (contractorResponse.success.currentLicenseDocumentId) {
        setLicenseDocumentId(
          contractorResponse.success.currentLicenseDocumentId
        );
        const licenseDocumentResponse =
          await DocumentService.getApiDocumentDetails(
            contractorResponse.success.currentLicenseDocumentId
          );

        setLicenceExpiryDate(
          licenseDocumentResponse.success.validUntil.substring(0, 10)
        );

        setUploadLicenseDocument(
          contractorResponse.success.currentLicenseDocumentId
        );
      }
    }
  };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 20
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name should have 2-20 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 20
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name should have 2-20 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase();
      setValues({ ...values, email: lowercaseEmail });

      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };
  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     if (emailRegex.test(values.email)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };

  const formDetails = [
    {
      label: "First name",
      placeholder: "Enter first name",
      value: values.firstName,
      name: "firstName",
      blurFunction: validateFirstName,
      errorMessages: errorMessages.firstName,
      isError: firstNameError,
    },
    {
      label: "Last name",
      placeholder: "Enter last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: validateLastName,
      errorMessages: errorMessages.lastName,
      isError: lastNameError,
    },
    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: validateNumber,
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }

    if (selectedServiceType === null) {
      setServiceTypeSelectError(true);
    }

    if (subServiceTypeFields.length > 0 && selectedSubServiceType === null) {
      setSubServiceTypeError(true);
    }
  };

  const handleInsuranceExpiryDateChange = (e) => {
    setInsuranceExpiryDate(e.target.value);
  };

  const handleLicenceExpiryDateChange = (e) => {
    setLicenceExpiryDate(e.target.value);
  };

  const handleUploadInsuranceDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    } else {
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    }
  };

  const handleUploadLicenseDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    } else {
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    }
  };

  const submitTimeError = () => {
    return (
      firstNameError ||
      lastNameError ||
      emailError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      selectedServiceType === null ||
      (subServiceTypeFields.length > 0 && selectedSubServiceType === null)
    );
  };

  const editSubmitHandler = (e) => {
    e.preventDefault();
    checkEmptyFields();

    if (submitTimeError()) {
    } else {

      console.log(values, "<=====valuessss");
      
      const newValues = {
        ...values,
        email:values.email.toLowerCase(),
        ServiceTypesIds: [selectedServiceType],
        serviceSubTypesIds: [selectedSubServiceType],
        isInHouse: isInHouseValue,
        id: id,
      };

      ContractorService.putApiContractor(newValues)
        .then(async (res) => {
          const contractorId = res.success.id;

          if (
            uploadLicenseDocument &&
            typeof uploadLicenseDocument != "number"
          ) {
            if (licenseExpiryDate.trim() != "") {
              var formattedlicenseExpiryDate = new Date(
                licenseExpiryDate
              ).toISOString();
            } else {
              var formattedlicenseExpiryDate = "";
            }

            const uploadLicenseDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.License,
              IsMain: true,
              FileName: uploadLicenseFileName,
              Document: uploadLicenseDocument,
              ValidUntil: formattedlicenseExpiryDate,
            };

            DocumentService.postApiDocument(uploadLicenseDocData)
              .then((res) => {
                setUploadLicenseFileName(null);
                setUploadLicenseDocument(null);
                setLicenceExpiryDate("");
              })
              .catch((error) => {});
          } else if (licenseDocumentId && uploadLicenseDocument === null) {
            await DocumentService.deleteApiDocument(licenseDocumentId);
          }

          if (
            uploadLicenseDocument &&
            typeof uploadLicenseDocument != "number"
          ) {
            if (insuranceExpiryDate.trim() != "") {
              var formattedinsuranceExpiryDate = new Date(
                insuranceExpiryDate
              ).toISOString();
            } else {
              var formattedinsuranceExpiryDate = "";
            }

            const uploadInsuranceDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.Insurance,
              IsMain: true,
              FileName: uploadInsuranceFileName,
              Document: uploadInsuranceDocument,
              ValidUntil: formattedinsuranceExpiryDate,
            };

            DocumentService.postApiDocument(uploadInsuranceDocData)
              .then((res) => {
                setUploadInsuranceDocument(null);
                setUploadInsuranceFileName(null);
                setInsuranceExpiryDate("");
              })
              .catch((error) => {
                {
                }
              });
          } else if (insuranceDocumentId && uploadInsuranceDocument === null) {
            await DocumentService.deleteApiDocument(insuranceDocumentId);
          }

          setValues({
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          });
          setSelectedServiceType(null);
          setSelectedSubServiceType(null);
          setAlertMessage("Contractor Edited Successfully");
          setShowModal(false);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        })
        .catch((error) => {});
    }
  };

  return (
    <Form onSubmit={editSubmitHandler}>
      <div className="editContractorFormContainer">
        <div className="newTenantDesciptionContainer1">
          <p className="newTenantDescriptionText">Select contractor type </p>
        </div>
        <div className="d-flex radioButtonContainer ">
          <Form.Check
            className="clickable"
            name="group1"
            type="radio"
            checked={isInHouseValue}
            onChange={() => setIsInHouseValue(true)}
            id="inHouseRadio"
          />
          <label className="clickable" htmlFor="inHouseRadio">
            In House
          </label>

          <Form.Check
            className="clickable"
            name="group1"
            type="radio"
            checked={!isInHouseValue}
            onChange={() => setIsInHouseValue(false)}
            id="Third-Party"
          />
          <label className="clickable" htmlFor="Third-Party">
            Third-Party
          </label>
        </div>
        <div className="d-flex addContractorInputContainer">
          {formDetails.map((item) => {
            return (
              <EditCustomComponent
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                value={item.value}
                name={item.name}
                onChangeFunction={handleChange}
                onBlurFunction={item.blurFunction}
                errorMessages={item.errorMessages}
                isError={item.isError}
                width="203px"
              />
            );
          })}

          <div className="typeContainer">
            <NewSelectAuto
              disabled={isServiceTypeDisabled}
              setDisabled={setIsServiceTypeDisabled}
              selected={selectedServiceType}
              setSelected={setSelectedServiceType}
              data={serviceTypeFields && serviceTypeFields}
              title={"Wait till the data fetches"}
              type="Service Type"
              setResetDependencies={setSubServiceTypeData}
              error={serviceTypeSelectError}
              setError={setServiceTypeSelectError}
              fromContractor={true}
              label={"Service type"}
              width={"203px"}
            />
            {serviceTypeSelectError && (
              <p className="errorMessage"> {errorMessages.serviceType} </p>
            )}
          </div>

          <div className="typeContainer">
            <NewSelectAuto
              disabled={isSubServiceTypeDisabled}
              setDisabled={setIsSubServiceTypeDisabled}
              selected={selectedSubServiceType}
              setSelected={setSelectedSubServiceType}
              data={subServiceTypeFields && subServiceTypeFields}
              title={"Please select property first"}
              type="Service Sub Type"
              error={subServiceTypeError}
              setError={setSubServiceTypeError}
              fromContractor={true}
              label={"Service sub type"}
              width={"203px"}
            />
            {subServiceTypeError && (
              <p className="errorMessage"> {errorMessages.subServiceType} </p>
            )}
          </div>
        </div>
        <div className="newTenantDesciptionContainer mb-3">
          <p className="newTenantDescriptionText">License Detail (Optional)</p>
        </div>
        <div className="editContractorMonthComponentItem">
          <p className="monthLabels">Select expiry date</p>
          <div>
            <Form.Group controlId="formDate">
              <CustomDatePicker
                name={"licenseExpiryDate"}
                type={FORM_CONTROLFIELD_TYPES.DATE}
                value={licenseExpiryDate}
                onChange={(e) => handleLicenceExpiryDateChange(e)}
                placeholder="Select a date"
              />
            </Form.Group>
          </div>
          <div></div>
        </div>
        <div className="labelContainer">
          <p className="formLabel">Upload license document</p>
        </div>
        {uploadLicenseDocument ? (
          <div
            style={{ width: "100%" }}
            className="d-flex align-items-center justify-content-between documentContainerEditForm"
          >
            <div className="d-flex gap-2 align-items-center">
              <img width={20} height={24} src={GetImages.pdfImage} />
              <p className="licenseDocumentText">{uploadLicenseFileName}</p>
            </div>
            <img
              onClick={() => {
                setUploadLicenseDocument(null);
                setUploadLicenseFileName(null);
              }}
              className="clickable"
              width={20}
              height={20}
              src={GetImages.deleteIconRed}
            />
          </div>
        ) : (
          <FileUploader
            classes="documentUploaderContractor w-100"
            handleChange={handleUploadLicenseDocumentChange}
            children={
              <>
                <div className="fileUploaderTenantDocument w-100">
                  <img width={24} height={24} src={GetImages.upload} />
                  <p>
                    Drag & Drop or{" "}
                    <span className="highlightedText">Browse</span>
                  </p>
                </div>
              </>
            }
          />
        )}
      </div>
      <div className="editFormFooterContractor">
        <p onClick={() => setShowModal(false)} className="cancelEdit clickable">
          Cancel
        </p>
        <button type="submit" className="saveEditTenantButton">
          Save Changes <img src={GetImages.WhiteForwardArrow} />
        </button>
      </div>
    </Form>
  );
};

export default EditContractorForm;
