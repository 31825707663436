import "./../tenantStyles.css";
import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";

const TopFixedView = ({rowCount, searchValue, setSearchValue, handleSort, currentSortDirection}) => {
  return (
    <>
      <TopFixedBar 
        rowCount={rowCount}
        rowCountLabel="Tenants"
        searchPlaceholder="Search by Tenant Name"
        addButtonLabel="Add Tenant"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        navigateTo="create" />
      <TopFixedSubBar 
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
      />
    </>
  );
};

export default TopFixedView;
