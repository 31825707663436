import { Route, Routes } from "react-router";
import SingleContractorComponent from "./SingleContractorComponent";
import AddContractor from "./AddContractor";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { useEffect, useState } from "react";
import { ContractorService } from "@propertelligent/client-api";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";

export const Contractors = () => {

  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { rows, loading, hasMore, rowCount, fetchData, currentSortDirection, handleSort, currentPage} = infiniteScrollHook({
    httpFetchDataFn: ContractorService.getApiContractorOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts : fulllyQualifiedSort
  });

  useEffect(() => {
    if(searchValue !== ""){
      SetFullyQualifiedFilter(`User.FirstName.ToLower().Contains("${searchValue.toLowerCase()}") or User.LastName.ToLower().Contains("${searchValue.toLowerCase()}")`);
    } else {
      SetFullyQualifiedFilter(null);
    }
  }, [searchValue]);

  useEffect(() => {
    SetFulllyQualifiedSort('User.LastName ' + currentSortDirection);
  }, [currentSortDirection]);
  
  return (
    <Routes>
      <Route index element={        
        <ContentTopBottom
            FixedTopContent={() => <TopFixedView rowCount={rowCount} currentSortDirection={currentSortDirection} handleSort={handleSort} setSearchValue={setSearchValue} searchValue={searchValue} />}
            FixedBottomContent={() => <MainScrollView rows={rows} loading={loading} hasMore={hasMore} fetchData={fetchData} currentPage={currentPage}/>} />
      } />
      <Route path="/contractor/:id" element={<SingleContractorComponent />} />
      <Route path="/create" element={<AddContractor />} />
    </Routes>
  );
};
