import './topFixedSubBar.css';
import { GetImages } from '../../../utils/GetImages';

const TopFixedSubBar = ({ currentSortDirection,
  handleSort }) => {

  return (
    <>
      <div className="subTopBarContainer">
          <div className="subTopBarSortButton" onClick={() => handleSort()}>
            <img src={GetImages.sort} />
            <p className="addFiltersButton">
              {currentSortDirection === "asc" ? "Sort by: A-Z": "Sort by: Z-A"}
            </p>
          </div>
        </div>
      </>
  );
};

export default TopFixedSubBar;
