import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomDropDownMenu = ({ Element, data }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{ outline: "none" }}
        variant="none"
        id="filterDropdown"
        className="m-0 p-0"
      >
        {Element}
      </Dropdown.Toggle>
      <Dropdown.Menu className="m-2">
        {data?.map((item, index) => (
          <React.Fragment key={item.id}>
            <Dropdown.Item onClick={item.onClick ? item.onClick : undefined}>
              {item.linkpath == undefined ? (
                <div className="text-decoration-none d-flex flex-row align-items-center text-dark gap-2">
                  {item.img && (
                    <img
                      src={item.img}
                      width={20}
                      height={20}
                      alt="Black Email Icon"
                    />
                  )}
                  <p className="sub-text">{item.name}</p>
                </div>
              ) : (
                <Link
                  to={item.linkpath}
                  className="text-decoration-none d-flex flex-row align-items-center text-dark gap-2"
                >
                  {item.img && (
                    <img
                      src={item.img}
                      width={20}
                      height={20}
                      alt="Black Email Icon"
                    />
                  )}
                  <p className="sub-text">{item.name}</p>
                </Link>
              )}
            </Dropdown.Item>
            {index !== data.length - 1 && <Dropdown.Divider />}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropDownMenu;
