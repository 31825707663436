import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { debouncedFunction } from "../utils/helper";
import { UserLoginData } from "../components/common/charts/Enums";
import { setIsLoading } from "../redux/actions/commanAction";
import { defaultPageNo, defaultPageSize, URLS } from "../constants/constants";
import { Navigate, Route, useNavigate } from "react-router";
import { MyContext } from "../utils/context/LoginContext";
import { LoginService } from "@propertelligent/client-api";
import apiHelper from "../utils/apiHelper";

export const infiniteScrollHook = ({
  httpFetchDataFn,
  parentEntityId = null,
  isDependentEntity = false,
  FullyQualifiedFilters = null,
  FullyQualifiedSorts = null,
}) => {
  const dispatch: any = useDispatch();
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const reqID = isDependentEntity ? parentEntityId : orgId;
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSortDirection, setCurrentSortDirection] = useState("asc");

  const [distinctFilterValues, setDistinctFilterValues] = useState<any>([]);
  const [aggregateValues, setAggregateValues] = useState<any>();
  const [calculateValue, setcalculateValue] = useState<any>();

  const handleSort = () => {
    const newSortDirection = currentSortDirection === "asc" ? "desc" : "asc";
    setCurrentSortDirection(newSortDirection);
  };

  const concateOldRecords = (oldRecords = []) => {
    return [...oldRecords];
  };

  const fetchData = (page) => {
    var currPage = isNaN(page) ? currentPage : page;
    if (reqID) {
      setLoading(true);
      dispatch(setIsLoading(true));
      apiHelper(httpFetchDataFn, { showNotification : false }, reqID,
      defaultPageSize,
      currPage,
      undefined,
      FullyQualifiedFilters,
      FullyQualifiedSorts)
        .then(({data}) => {
          setLoading(false);
          if (distinctFilterValues.length == 0) {
            setDistinctFilterValues(data?.distinctFilterValues);
          }
          setAggregateValues(data?.formattedAggregateValues);
          setcalculateValue(data?.formattedCalculatedValues);
          setCurrentPage(currPage);
          dispatch(setIsLoading(false));
          setRowCount(data?.recordCount);
          setRows((prevRows) => [
            ...concateOldRecords(prevRows),
            ...data?.success,
          ]);

          setHasMore(data?.recordCount > currPage * defaultPageSize);
        })
        .catch((e) => {
          setLoading(false);
          dispatch(setIsLoading(false));
          setRowCount(0);
          setRows([]);
          setHasMore(false);
        });
    } else
      console.log("No dependent information provided to fetch the records!");
  };

  useEffect(() => {
    setRows([]);
    debouncedFunction(() => fetchData(1), 500)();
  }, [FullyQualifiedFilters, currentSortDirection]);

  return {
    loading,
    rows,
    rowCount,
    hasMore,
    distinctFilterValues,
    aggregateValues,
    calculateValue,
    fetchData,
    handleSort,
    currentSortDirection,
    currentPage,
  };
};
