import Forward from "../../../assets/arrow_forward.png";
const AddButtonWithArrow = ({
  buttonname,
  onClick,
  disabled,
  iconDirection,
  Icon,
}) => {
  return (
    <>
      <button
        type="submit"
        className="getStartedButtonadd"
        onClick={onClick}
        disabled={disabled}
      >
        {iconDirection == "right" ? (
          <>
            <img className="iconstyle11" src={Icon} alt={buttonname} />
            <p className="FW600_LH20_button_white_text">{buttonname} </p>
          </>
        ) : iconDirection == "left" ? (
          <>
            <p className="FW600_LH20_button_white_text">{buttonname} </p>
            <img className="iconstyle11" src={Icon} alt={buttonname} />
          </>
        ) : (
          <p className="FW600_LH20_button_white_text">{buttonname} </p>
        )}
      </button>
    </>
  );
};

export default AddButtonWithArrow;
