import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

interface props {
  httpAssociateEntityInfoFn: any;
  formatNameByInfo: (values: any) => any;
  setInfoByParent: (values: any) => any;
  setOpenModal: (open: boolean) => any;
  entityId: number | null | undefined;
  initialValues: any;
  label?: string;
  title?: string | null | undefined;
  buttonText: string;
  isAssociated: boolean;
}

export const useAssociateEntityHook = ({
  httpAssociateEntityInfoFn,
  formatNameByInfo,
  setInfoByParent,
  setOpenModal,
  entityId,
  initialValues,
  label,
  title = null,
  buttonText,
  isAssociated,
}: props) => {
  const [info, setInfo] = useState(initialValues);

  const fetchAssociateEntityInfo = () => {
    if (entityId) {
      httpAssociateEntityInfoFn(entityId)
        .then((response: any) => {
          setInfo(response?.success);
          setInfoByParent(response?.success);
        })
        .catch((error: any) => {});
    }
  };

  useEffect(() => {
    fetchAssociateEntityInfo();
  }, [entityId]);

  return {
    AssociateComponent: isAssociated ? (
      <>
        {title && <p style={{ marginTop: "20px" }}>{title}</p>}
        <p
          style={{
            border: "1px solid gray",
            borderRadius: "8px",
            marginTop: title ? "8px" : "20px",
            padding: "8px",
          }}
        >
          {label && (
            <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>{label}</span>
          )}
          {formatNameByInfo(info)}
        </p>
      </>
    ) : (
      <div style={{ marginTop: "20px" }}>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          {buttonText}
        </Button>
      </div>
    ),
  };
};
