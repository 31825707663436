import React, { createContext, useState, useEffect, useContext } from "react";

type MenuContextType = {
  isOpen: boolean;
  isHidden: boolean;
  setIsOpen: (value: boolean) => void;
  setIsHidden: (value: boolean) => void;
  toggleMenu: (value?: boolean) => void; // Add toggleMenu to the context type
};

export const MenuContext = createContext<MenuContextType | undefined>(
  undefined
);

export const MenuProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 600);
  const [isHidden, setIsHidden] = useState(window.innerWidth < 400);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 600);
      setIsHidden(window.innerWidth < 400);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle menu function
  const toggleMenu = (value?: boolean) => {
    if (typeof value === "boolean") {
      setIsOpen(value);
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <MenuContext.Provider
      value={{ isOpen, isHidden, setIsOpen, setIsHidden, toggleMenu }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
};
