import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { GetImages } from "../../utils/GetImages"

const ScheduleConfirmationModal = ({ onSchedule, onClose, label, heading, subHeading }) => {
	return (
		<div>
			<Modal className="scheduleConfirmationModal d-flex align-items-center justify-content-center" show={true} onHide={onClose}>
				<div className="d-flex flex-column align-items-between justify-content-between h-100">
					<div>
						<div className="d-flex align-items-center justify-content-between">
							<p className="openActionContractorHeading">{heading}</p>
							<img className="clickable" onClick={onClose} src={GetImages.closeIconBlack} />
						</div>
						<p style={{ whiteSpace: "wrap" }} className="typeMailPhoneText mt-2">
							{subHeading}
						</p>
					</div>
					<div className="d-flex justify-content-end align-items-center gap-3">
						<p onClick={onClose} className="cancelEdit clickable">
							Cancel
						</p>
						<button onClick={onSchedule} className="saveChangesButton1">
							{label}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default ScheduleConfirmationModal
