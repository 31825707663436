
import { routeList } from "../../routes/routesList";
import { USER_ROLES } from "../../constants/constants";
import { FirstTimeDashboard } from "../firstTimeUserExperience";
import { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";

const UserDashboard = ({ userRoleId = 1 }) => {
  const [count, setCount] = useState(1);
  const [onboadringStatus, setOnBoardingStatus] = useState(
    localStorage.getItem("userOnboarded")
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (onboadringStatus === "false") {
      navigate("/questionnaire");
    }
  }, [onboadringStatus]); 

  return ( 
    <Routes>
      {" "}
      {count === 0 ? (
        <>
          <Route path="/*" element={<FirstTimeDashboard />} />
          <Route path="/" element={<FirstTimeDashboard />} />
        </>
      ) : (
        routeList[USER_ROLES[userRoleId]]?.map((item) => (
          <Route
            key={item.id} // Assign unique key here
            path={item.path}
            element={item.element}
          />
        ))
      )}
    </Routes>
  );
};

export default UserDashboard;
