import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ContractorOverview from "./ContractorOverview";
import TransactionComponent from "./TransactionComponent";
import ServiceRequestComponent from "./ServiceRequestComponent";
import { Offcanvas } from "react-bootstrap";
import EditContractorForm from "./EditContractorForm";
import {
  ContractorService,
  DocumentService,
  PaymentService,
  AnalyticsService,
  EntityType,
} from "@propertelligent/client-api";
import NameAvatarComponent from "../tenants/NameAvatarComponent";
import { convertStreamAndDownload } from "../../utils/helper";
import SuccessAlert from "../tenants/SuccessAlert";
import { GetImages } from "../../utils/GetImages";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { UserLoginData } from "../../components/common/charts/Enums";
import { usePaginationHook } from "../../../src/hooks/usePaginationHook";
import { useAnalyticsHook } from "../../../src/hooks/useAnalyticsHook";
import PaymentForm from "../common/Payment/PaymentForm";
import ScrollingContainer from "../common/scrollingContainer/ScrollingContainer";
import "./contractorStyles.css";
import { MenuContext } from "../../utils/context/MenuContext";

const SingleContractorComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [contractorData, setContractorData] = useState<any>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading1, setIsUploading1] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showPaymentSuccessAlert, setShowPaymentSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  const [fin_monthValue, setFin_MonthValue] = useState(-12);

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    PaymentService.getApiPaymentMethodOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPaymentMethodsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});

    PaymentService.getApiPayeeAccountOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});
  }, [id]);

  useEffect(() => {
    getContractorData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getContractorData();
    }, 1000);
  }, [showModal]);

  const getContractorData = async () => {
    const contractorResponse = await ContractorService.getApiContractor1(
      Number(id)
    );
    if (contractorResponse.success) {
      setContractorData(contractorResponse.success);
    }
  };

  console.log("Contractor data ==>", contractorData);

  const downloadDocument = (id, fileName, loadingState) => {
    loadingState(true);

    DocumentService.getApiDocumentBase641(id)
      .then((docRes) => {
        //Conversion alogorithm
        convertStreamAndDownload(docRes?.success, fileName);
        loadingState(false);
      })
      .catch((docErr) => {
        loadingState(false);
      });
  };

  const { fetchData } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountPropertylist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  const customMonthField = "monthYear";
  const { fetchAndFormatExpensebycategory } = useAnalyticsHook({
    httpExpensebycategoryFn:
      AnalyticsService.getApiAnalyticsPropertyExpensebycategory,
    httpCashflowFn: AnalyticsService.getApiAnalyticsPropertyCashflow,
    customMonthField,
    parentEntityId: Number(id),

    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });

  const handleSubmitPaymentAccountRecord = (data) => {
    const organizationId = parseInt(
      localStorage.getItem(UserLoginData.organization_id)
    );
    Object.assign(data.values, {
      organizationId,
      entityTypeId: EntityType.Contractor,
      dueDate: new Date(data.values?.dueDate).toISOString(),
    });

    if (data.values.paymentDueAccountStatusId === 2) {
      PaymentService.postApiPaymentDueAccount(data.values)
        .then((res: any) => {
          if (res?.success) {
            Object.assign(data.payvalues, {
              payDueAccountId: res?.success.id,
            });

            PaymentService.putApiPaymentDueAccountPay(data.payvalues)

              .then((res: any) => {
                if (res?.success) {
                  setShowPaymentModal(false);
                  setShowSuccessAlert(true);
                  setTimeout(() => {
                    setShowSuccessAlert(false);
                  }, 3000);
                  fetchData();
                  fetchAndFormatExpensebycategory();
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    } else if (data.values.paymentDueAccountStatusId === 1) {
      PaymentService.postApiPaymentDueAccount(data.values)
        .then((res: any) => {
          if (res?.success) {
            fetchData();
            fetchAndFormatExpensebycategory();
            setShowPaymentModal(false);

            setShowPaymentSuccessAlert(true);
            setTimeout(() => {
              setShowPaymentSuccessAlert(false);
            }, 3000);
          }
        })
        .catch((error) => {});
    }
  };

  const { isOpen } = useContext(MenuContext);

  return (
    // <ScrollingContainer Content={() => {return (
    <>
      <div
        id="contractorEditForm"
        className="singleContractorView position-relative"
      >
        <div
          className={`sticky-top-property ${
            isOpen ? "sidebar-open" : "sidebar-closed"
          }`}
        >
          <div className="singleTenantTopbar">
            <div
              onClick={() => {
                navigate("/contractors");
              }}
              className="clickable"
            >
              <p className="singleTenantTopbarHeading">Contractor</p>
            </div>
            <img src={GetImages.BlackChevronRight} />
            <p className="singleTenantTopbarSubHeading">Contractor Detail</p>
          </div>
          <div className="mainContractoComponent">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex gap-2">
                <NameAvatarComponent
                  firstName={contractorData.firstName}
                  lastName={contractorData.lastName}
                  heightWidth={48}
                />

                <div>
                  <p className="newSingleTenantName ">
                    {`${contractorData.firstName} ${contractorData.lastName}`}
                  </p>
                  <div className="sinceTimePeriod ms-0 d-flex gap-1">
                    <p>
                      {contractorData?.formatted?.ServiceTypesIds?.length > 0
                        ? contractorData.formatted.ServiceTypesIds[0]
                        : "Interior"}
                    </p>
                    <p>•</p>
                    <p>
                      {contractorData?.formatted?.ServiceSubTypesIds?.length > 0
                        ? contractorData.formatted.ServiceSubTypesIds[0]
                        : "Furnishing"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3">
                <p
                  className="newEditTanentButton clickable"
                  onClick={() => setShowModal(true)}
                >
                  Edit Contractor
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-start w-100 gap-3 flex-wrap">
              <div className="d-flex align-items-end typeMailPhoneText gap-1">
                <img width={18} height={18} src={GetImages.orangeContractor} />
                <p>{contractorData.isInHouse ? "In House" : "Third Party"}</p>
              </div>
              <div className="seperatorLine1"></div>
              <div className="d-flex align-items-end typeMailPhoneText gap-1 ">
                <img src={GetImages.mailIconOrange} />
                <p>{contractorData.email}</p>
              </div>
              <div className="seperatorLine1"></div>
              <div className="d-flex align-items-end typeMailPhoneText gap-1">
                <img src={GetImages.phoneIconOrange} />
                <p>{contractorData.phoneNumber}</p>
              </div>
            </div>

            {(contractorData.currentLicenseDocumentId ||
              contractorData.currentInsuranceDocumentId) && (
              <div className="d-flex gap-2 newDocumentSection2 flex-wrap">
                {contractorData.currentLicenseDocumentId && (
                  <div
                    onClick={() => {
                      downloadDocument(
                        contractorData.currentLicenseDocumentId,
                        "License Document",
                        setIsUploading
                      );
                    }}
                    className="newDocumentItem clickable"
                  >
                    {isUploading ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <img height={40} src={GetImages.loadingGif} />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-3 p-2">
                        <img src={GetImages.pdfImage} />
                        <p className="newDocumentItemText">License Document</p>
                      </div>
                    )}
                  </div>
                )}

                {contractorData.currentInsuranceDocumentId && (
                  <div
                    onClick={() => {
                      downloadDocument(
                        contractorData.currentInsuranceDocumentId,
                        "Insurance Document",
                        setIsUploading1
                      );
                    }}
                    className="newDocumentItem clickable"
                  >
                    {isUploading1 ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <img height={40} src={GetImages.loadingGif} />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-3 p-2">
                        <img src={GetImages.pdfImage} />
                        <p className="newDocumentItemText">
                          Insurance Document
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="singleTenantMultiTabSelect">
            <div
              onClick={() => {
                setSelectedTab(1);
              }}
              className="clickable"
            >
              <p
                className={
                  selectedTab === 1
                    ? "singleTenantSelectedTab"
                    : "singleTenantUnselectedTab"
                }
              >
                Overview
              </p>
            </div>
            <div
              onClick={() => {
                setSelectedTab(2);
              }}
              className="clickable"
            >
              <p
                className={
                  selectedTab === 2
                    ? "singleTenantSelectedTab"
                    : "singleTenantUnselectedTab"
                }
              >
                Service requests
              </p>
            </div>
            <div
              onClick={() => {
                setSelectedTab(3);
              }}
              className="clickable"
            >
              <p
                className={
                  selectedTab === 3
                    ? "singleTenantSelectedTab"
                    : "singleTenantUnselectedTab"
                }
              >
                Transactions
              </p>
            </div>
          </div>
        </div>

        <div
          // style={{ marginTop: "220px" }}
          // style={{marginTop:"282px"  ,
          style={{
            marginTop:`${contractorData.currentLicenseDocumentId ||
              contractorData.currentInsuranceDocumentId ? "300px" : "250px"}`,
            // marginTop: "   300px",
            height: "   calc(100vh - 280px)",
            overflow: "auto",
          }}
          className="bottom-contractor-scrolling"
        >
          {selectedTab === 1 && <ContractorOverview id={Number(id)} />}
          {selectedTab === 2 && <ServiceRequestComponent id={Number(id)} />}
          {selectedTab === 3 && <TransactionComponent id={Number(id)} />}
          <Offcanvas
            className="editTanentOffcanvas"
            show={showModal}
            onHide={() => setShowModal(false)}
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Contractor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {contractorData && (
                <EditContractorForm
                  setShowModal={setShowModal}
                  id={contractorData?.id}
                  setShowSuccessAlert={setShowSuccessAlert}
                  setAlertMessage={setAlertMessage}
                />
              )}
            </Offcanvas.Body>
          </Offcanvas>

          {showSuccessAlert && (
            <SuccessAlert
              message={alertMessage}
              setShowSuccessAlert={setShowSuccessAlert}
            />
          )}
          {showPaymentSuccessAlert && (
            <SuccessAlert
              message={"Payment Added Successfully"}
              setShowSuccessAlert={setShowPaymentSuccessAlert}
            />
          )}
        </div>
      </div>
    </>
    // )}} />
  );
};

export default SingleContractorComponent;
