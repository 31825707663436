import React from 'react';

const LeaseAgreement = () => {
  console.log("Hello world");
  
  return (
    <div>
      <h1>Lease Agreement</h1>
      <p>Lease Agreement Goes Here!</p>
    </div>
  );
};

export default LeaseAgreement;