import { ApiError } from "@propertelligent/client-api";
import { NotificationType, PubSub, TopicsEnum } from "./pubsub";


const apiHelper = async (apiCall, apiNotificationSettings : ApiNotificationSettings, ...params) => {
    let responseData = null;
    let errors = null;
    let statusCode = null;
  
    try {
      responseData = await apiCall(...params); 
      if(apiNotificationSettings.showNotification){
        PubSub.publish(TopicsEnum.Notification, {
            message : apiNotificationSettings.successMessage || "Api completed successfully",
            type: NotificationType.Success
        });
     }
    } catch (err) {
        var apiError = err as ApiError;

        // this.name = 'ApiError';
        // this.url = response.url;
        // this.status = response.status;
        // this.statusText = response.statusText;
        // this.body = response.body;
        // this.request = request;

        if(apiError?.body?.errors){
            console.log(apiError.body);
            errors = apiError.body.errors;
        }
        
        let errorMessage = "";
        switch (apiError.status) {
        case 400:
            errorMessage = 'Bad Request (400)';
            break;
        case 401:
            errorMessage = 'Unauthorized (401)';
            PubSub.publish(TopicsEnum.Logout, {
                type: NotificationType.Failure,
                message : "Session expired, login again."
            });
            return;
        case 403:
            errorMessage = "User not authorized to perform this action.";
            break;
        case 415:
            errorMessage = 'Media Type not Allowed (415)';
            break;
        case 500:
            errorMessage = 'Internal Server Error (500)';        
            break;
        default:
            errorMessage = `Unexpected Error (${statusCode})`;
            break;
        }
        if(apiNotificationSettings.showNotification){
            PubSub.publish(TopicsEnum.Notification, {
                type: NotificationType.Failure,
                message : apiNotificationSettings.failureMessage || errorMessage
            });
        }
    }
  
    return { data: responseData, errors, statusCode };
  };
  

export default apiHelper;

export type ApiNotificationSettings = {
    successMessage?: string;
    failureMessage?: string;
    showNotification: boolean;
};  