import { CustomInput } from "../common/formControls";
import "./tenantStyles.css";

const CustomTenantInput = ({
  label,
  placeholder,
  value,
  name,
  onChangeFunction,
  onBlurFunction,
  errorMessages,
  isError,
}) => {
  return (
    <div className="customTenantInputContainerStyles">
      <div className="inputContainer">
        <CustomInput 
          name={name}
          value={value} isFormControl
          onChange={(e)=>onChangeFunction(e)}
          placeholder={placeholder}
          myClassName = "containerTextField"
          onBlur={onBlurFunction}
          isError = {isError}
          errorMessage = {errorMessages}
        />
      </div>
    </div>
  );
};

export default CustomTenantInput;
