import React, { useEffect, useState } from "react";
import { CommunicationService } from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import { usePaginationHook } from "../../../hooks/usePaginationHook";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import ChatBox from "./ChatBox";
import EmptyData from "../../common/EmptyData";
import AddButton from "../../firstTimeUserExperience/commonCom/AddButton";
import SelectChat from "./SelectChat";
import { GetImages } from "../../../utils/GetImages";
import { UserLoginData } from "../../common/charts/Enums";
import DateHelper from "../../../utils/DateHelper";
import Search from "../../common/search";
import SimpleSpinner from "../../loader";

const ChatView = () => {
  const [searchValues, setSearchValues] = useState("");
  const [entityId, setEntityId] = useState<Number>();
  const [entityTypeId, setEntityTypeId] = useState<Number>();
  const [chatname, setChatName] = useState("");
  const [showSection, setShowSection] = useState("");
  const [messageChannel, setMessageChannel] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMessageChannel = () => {
    setLoading(true);
    const searchQuery =
      typeof searchValues === "string" ? searchValues.toLowerCase() : "";

    CommunicationService.getApiMessageOrganizationlistMessagechannels(
      parseInt(localStorage.getItem(UserLoginData.organization_id)),
      undefined,
      undefined,
      undefined,
      `usr.FirstName.ToLower().Contains("${searchQuery}") or usr.LastName.ToLower().Contains("${searchQuery}")`
    )
      .then((res) => {
        setMessageChannel(res.success);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessageChannel();
  }, [searchValues, messageChannel?.length, entityId]);

  const handleSearchChange = (value) => {
    if (typeof value === "string") {
      setSearchValues(value);
    }
  };

  return (
    <>
      <div className="chatMainContainer">
        <div className="chatSubContainer ">
          <div className="chatLeftSection ">
            <div className="chatHeader d-flex flex-row justify-content-between">
              <p className="FW700_LH24_main_text">Messages</p>
              <AddTraspButton
                onClick={() => {
                  setEntityId(null);
                  setShowSection("selectChat");
                }}
                bname="New Message"
                iconPosition="right"
                imageValue={GetImages.BlueAdd}
                border={false}
              />
            </div>
            <div className="searchSection ">
              <Search
                placeholderName="Search chat"
                searchFilterChange={handleSearchChange}
                searchValue={searchValues || ""}
              />

              <div className="chatFilter">
                <img src={GetImages.Filter} className="iconstyle" />
              </div>
            </div>

            {loading ? (
              <SimpleSpinner />
            ) : (
              <div
                className="chatItemContaner "
                style={{ overflowX: "hidden" }}
              >
                {messageChannel?.length == 0 ? (
                  <EmptyData
                    mainText="No Message Here"
                    subText="Please click the button below to start the conversation"
                    button={
                      <AddButton
                        onClick={undefined}
                        bname="Start Conversation"
                      />
                    }
                  />
                ) : (
                  messageChannel?.map((item, i) => {
                    const name = item?.name?.split(" ");
                    return (
                      <div
                        className="chatItem "
                        style={{
                          background:
                            item.entityId === entityId
                              ? "var(--Primary-Next-level-Lighter, #EBF5FF)"
                              : "",
                        }}
                        key={i}
                        onClick={() => {
                          setEntityId(item.entityId);
                          setEntityTypeId(item?.entityTypeId);
                          setChatName(item?.name);
                          setShowSection("chat");
                          fetchMessageChannel();
                        }}
                      >
                        <div className="userIcon">
                          <NameAvatarComponent
                            firstName={name[0]}
                            lastName={name[1]}
                            heightWidth={40}
                          />
                        </div>

                        <div className="chatDetailsView">
                          <div className=" d-flex flex-row justify-content-between align-items-center w-100">
                            <p className="FW500_LH20_sub_text ">{item?.name}</p>
                            <p className="FW400_LH16_small_text">
                              {DateHelper.convertUTCtoDateTime(
                                item?.lastMessageDateTime,
                                true
                              )}
                            </p>
                          </div>
                          <div className="message_count d-flex justify-content-between align-items-center w-100 ">
                            <p
                              className="FW400_LH16_small_text"
                              id="message-data"
                            >
                              {item?.lastMessageData}
                            </p>
                            {item.unreadMessagesCount > 0 ? (
                              <div className="meesageCount">
                                {" "}
                                <p className="FW400_LH12_FS8_white">
                                  {item.unreadMessagesCount}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
          <div className="chatRightSection ">
            <ChatBox
              entityId={entityId}
              setEntityId={setEntityId}
              entityTypeId={entityTypeId}
              chatname={chatname}
              setChatName={setChatName}
              setEntityTypeId={setEntityTypeId}
              fetchData={fetchMessageChannel}
            />
            {/* {showSection == "chat" ? (
              <ChatBox
                entityId={entityId}
                entityTypeId={entityTypeId}
                chatname={chatname}
                fetchData={fetchMessageChannel}
              />
            ) : showSection == "selectChat" ? (
              <SelectChat callnewchat={fetchMessageChannel} />
            ) : (
              <div className="chatboxContainer ">
                <EmptyData
                  mainText={undefined}
                  subText="Click on the chat from left to start the conversation"
                  button={undefined}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatView;
