import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CommanStyles/style.css";

const CustomDatePicker = ({
  isFormControl = true,  required = false, 
  label= '', type= "text", placeholder= '',
  value, name, focus = false, onChange,
  onBlur = () => {}, myClassName = "",
  disabled = false, errorMessage = '', isError,
  min = undefined, max = undefined,
}: any) => {


  const handleChange = (event: any) => {
      onChange(event);
  };

  let injectErrorClasses = ''; 

  if(typeof isError == "boolean"){
    injectErrorClasses = isError ? "errorBorder errorOutline" : "normalBorder normalOutline";
  }

  return (
    <>
        {isFormControl ? (
          <Form.Control
            className={`${injectErrorClasses} ${myClassName}`}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onBlur={onBlur}
            required={required}
            onChange={handleChange}
            disabled={disabled}
            min={min}
            max={max}
          />) 
          : 
          (<input
            className={`${injectErrorClasses} ${myClassName}`}
            type={type}
            name={name}
            value={value}
            required={required}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            autoFocus={focus}
          />
        )}
        {isError && 
          <div className="mt-2">
            <p className="errorMessageStyles">{errorMessage}</p>
          </div>
        }
    </>
  );
};

export default CustomDatePicker;
