import {
  electrical,
  plumbing,
  appliance,
  exterior,
  interior,
  other1,
} from "../../assets";

const ServiceIcon = ({ serviceType }) => {

  const serviceTypeToImagePath = {
    Plumbing: plumbing,
    Appliance: appliance,
    Other: other1,
    Exterior: exterior,
    Interior: interior,
    Electrical: electrical,
  };
  const imagePath = serviceTypeToImagePath[serviceType];
  const path = imagePath ? imagePath : other1;
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        width: 40,
        height: 40,
        background: "#FEECDC",
        borderRadius: "50%",
      }}
    >
      <img
        src={path}
        alt={`${serviceType} icon`}
        style={{ width: "20px", height: "20px", flexShrink: 0 }}
      />
    </div>
  );
};

export default ServiceIcon;
