import React, { useContext, useCallback, useMemo} from "react";
import { useNavigate } from "react-router";
import AddButtonWithArrow from "./commonCom/AddButtonWithArrow";
import { useProperty } from "../../utils/context/PropertyProvider";
import { UserLoginData } from "../common/charts/Enums";
import Link from "./Link/index";
import { OpenAPI } from "@propertelligent/client-api";
import Progressbar from "./commonCom/Progressbar";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import Context from "./Context";
import { GetImages } from "../../utils/GetImages";
import { URLS } from "../../constants/constants";
import plaidimg from "../../assets/plaid.png";
import Secure from "../../assets/Secure.png";
import Success from "../../assets/success.png";

const AddPlaidAccount = () => {
  const {
    itemId,
    accessToken,
    linkToken, 
    linkSuccess,
    isItemAccess,
    backend,
    linkTokenError,
    dispatch
  } = useContext(Context);
 
  const [propertycount, setPropertyCount] = useProperty();
  const navigate = useNavigate();

  const getInfo = useCallback(async () => {
    const response = await fetch(`${OpenAPI.BASE}/api/PlaidAccount/info`, { method: "POST" });
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { backend: false } });
      return { paymentInitiation: false };
    }
    const data = await response.json();
    const paymentInitiation: boolean = data.products.includes(
      "payment_initiation"
    );
    dispatch({
      type: "SET_STATE",
      state: {
        products: data.products,
        isPaymentInitiation: paymentInitiation,
      },
    });
    return { paymentInitiation };
  }, [dispatch]);

  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      const loginToken = localStorage.getItem(UserLoginData.token);
      const path = isPaymentInitiation
        ? `${OpenAPI.BASE}/api/create_link_token_for_payment`
        : `${OpenAPI.BASE}/api/PlaidAccount/create-link-token`;
      const response = await fetch(path, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${loginToken}`,
        },
      });
      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      const data = await response.json();
      if (data) {
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      // Save the link_token to be used later in the Oauth flow.
      localStorage.setItem("link_token", data.link_token);
    },
    [dispatch]
  );

  useMemo(() => {
    const init = async () => {
      const { paymentInitiation } = await getInfo(); 
      
      if (window.location.href.includes("?oauth_state_id=")) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, getInfo]);

  return (
    <>
      <div className="firstAddmain align-items-center">
        <div className="progressSection">
          <Progressbar stepname="Add unit and tenant" stepnum="3/3" />
        </div>
        <div className="h-100 d-flex flex-column">
          {!linkSuccess ? (
            <div className="plaidMain">
              <p className="plaidText">
                Awesome! Connect your Plaid account with Propertelligent
              </p>
              <img src={plaidimg} className="plaidimg" />
              {!backend ? (
                <Callout warning>
                  Unable to fetch link_token: please make sure your backend
                  server is running and that your .env file has been configured
                  with your
                  <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
                </Callout>
              ) : /* message if backend is running and there is no link token */
              linkToken == null && backend ? (
                <Callout warning>
                  <div>
                    Unable to fetch link_token: please make sure your backend
                    server is running and that your .env file has been
                    configured correctly.
                  </div>
                  <div>
                    Error Code: <code>{linkTokenError.error_code}</code>
                  </div>
                  <div>
                    Error Type: <code>{linkTokenError.error_type}</code>{" "}
                  </div>
                  <div>Error Message: {linkTokenError.error_message}</div>
                </Callout>
              ) : linkToken === "" ? (
                <div>
                  <Button large disabled>
                    Loading...
                  </Button>
                </div>
              ) : (
                <div>
                  <Link />
                </div>
              )}
            </div>
          ) : (
            <>
              {
                <>
                  {isItemAccess ? (
                    <div className="linkedmain">
                      <h4>
                        Congrats! By linking an account, you have created a
                        secure connection between us and your bank.
                      </h4>
                      <img src={Success} className="linkimg" />
                      <div className="linktext">
                        <p className="plaidText">Linked Successfully!</p>
                        <p className="linksubtext">
                          Hence, your account have been created.
                        </p>
                      </div>
                      <AddButtonWithArrow
                        buttonname="Finish"
                        disabled={undefined}
                        onClick={() => {
                          setPropertyCount([...propertycount]);
                          navigate(URLS.HOME);
                        }}
                        iconDirection="left"
                        Icon={GetImages.WhiteForwardArrow}
                      />
                    </div>
                  ) : (
                    <h4>
                      <Callout warning>
                        Unable to create an item. Please check your backend
                        server
                      </Callout>
                    </h4>
                  )}
                </>
              }
            </>
          )}
        </div>
        {isItemAccess ? (
          <div className="plaidbottom">
            <img src={Secure} className="icon_style" />
            <p className="plaidfooter">
              We just link your account, your data is completely secured
            </p>
          </div>
        ) : (
          <Callout warning>
            Unable to create an item. Please check your backend server
          </Callout>
        )}
      </div>
    </>
  );
};

export default AddPlaidAccount;
