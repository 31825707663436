import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
const DeleteConfirmationModal = ({ onDelete, onClose, paymentMethod }) => {
  return (
    <div>
      <Modal
        className="deleteConfirmationModal d-flex align-items-center justify-content-center"
        show={true}
        onHide={onClose}
      >
        <div className="d-flex flex-column align-items-between justify-content-between h-100">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="openActionContractorHeading">Delete account</p>
              <img
                className="clickable"
                onClick={onClose}
                src={GetImages.closeIconBlack}
              />
            </div>
            <p
              style={{ whiteSpace: "wrap" }}
              className="typeMailPhoneText mt-2"
            >
              Do you want to delete this {paymentMethod} account.
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <p onClick={onClose} className="cancelEdit clickable">
              Cancel
            </p>
            <button onClick={onDelete} className="saveChangesButton1">
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
