import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from "./locales/en/translation.json";
import enGB from "./locales/en-GB/translation.json";
import enUS from "./locales/en-US/translation.json";
import fr from "./locales/fr/translation.json";

/**
 * fallbackLng : Language to use if translations in user language are not available.
 */
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    // fallbackLng: 'en-US',
    // fallbackLng: 'en-GB',
    // fallbackLng: 'fr',
    debug: false,

    interpolation: {//For avoiding cross site scripting issues, React takes care of it
      escapeValue: false, // not needed for react!!
    },
    resources: {
      en: {
        translation: en
      },
      "en-GB":{
        translation: enGB
      },
      "en-US": {
        translation: enUS
      },
      fr: {
        translation: fr
      }
    }
  });


export default i18n;