import Forward from "../../../assets/blueforwardarrow.png";
import { GetImages } from "../../../utils/GetImages";

const AddTraspButton = ({
  bname,
  iconPosition,
  imageValue = null,
  onClick,
  border,
}) => {
  return (
    <>
      <button
        type="submit"
        onClick={onClick}
        className="addtrasparent"
        style={{
          border: !border ? "none" : "",
          boxShadow: !border ? "none" : "",
          background: !border ? "none" : "",
        }}
      >
        {iconPosition === "right" && imageValue !== null && (
          <img src={imageValue} className="iconstyle " />
        )}
        <p className="FW600_LH20_FW14_blue_text"> {bname} </p>
        {iconPosition === "left" && imageValue !== null && (
          <img src={GetImages.blueForwardArrow} className="iconstyle" />
        )}
      </button>
    </>
  );
};

export default AddTraspButton;
