import React from "react";
import paper from "../../assets/paper.png";
import { useLocation, useNavigate } from "react-router";
import { TopBar } from "../common/TopBar";
import "./CheckEmailStyles.css";
import { Button } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";

const CheckEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state.key;
  const backToLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
        }}
        className="checkEmailContainer"
      >
        <TopBar haveAccount="Don’t have an account?" signInUp="Sign up" />
        <div className="container1">
          {" "}
          <div className="middleBox">
            <div className="correctImageContainer">
              <img src={paper} />
            </div>
            <p className="resetLinkSentText">Reset password link sent</p>

            <p className="resetLinkSubText">
              We have sent a reset password link on
            </p>

            <p className="emailtextStyles">{email && email}</p>

            <Button
              type="submit"
              onClick={backToLogin}
              className="backtoLoginButton"
            >
              Back to login
            </Button>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default CheckEmail;
