import { TopBar } from "../common/TopBar";
import { useState } from "react";
import { GetImages } from "../../utils/GetImages";
import { LoginService } from "@propertelligent/client-api";
import { useParams } from "react-router-dom";
import SuccessAlert from "../tenants/SuccessAlert";
import { useNavigate } from "react-router";
import "./ResetPassword.css";

const ResetPassword = () => {
  const { userKey } = useParams();
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [arePasswordEqual, setArePasswordEqual] = useState(true);

  const [comingFromResetPass, setComingFromResetPass] = useState(false);

  const navigate = useNavigate();

  const validatePassword = () => {
    if (newPassword.trim().length == 0) {
      setPasswordError(true);
      return true;
    } else {
      setPasswordError(false);
      return false;
    }
  };
  const validateConfirmPassword = () => {
    if (confirmPassword.trim().length == 0) {
      setConfirmPasswordError(true);
      return true;
    } else {
      setConfirmPasswordError(false);
      return false;
    }
  };

  const passwordEqualCheck = () => {
    if (
      newPassword.length > 0 &&
      confirmPassword.length > 0 &&
      newPassword === confirmPassword
    ) {
      setArePasswordEqual(true);
      return true;
    } else {
      setArePasswordEqual(false);
      return false;
    }
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const setPassword = () => {
    const isNewPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();
    const passwordEqual = passwordEqualCheck();

    if (passwordEqual) {
      if (!(isNewPasswordValid || isConfirmPasswordValid)) {
        const payload = {
          guid: userKey,
          password: newPassword,
        };

        LoginService.postApiLoginSetPassword(payload)
          .then((res) => {
            setComingFromResetPass(true);
            setTimeout(() => {
              setComingFromResetPass(false);
              navigate("/login");
            }, 3000);
          })
          .catch((error) => {
            console.error("Error resetting password:", error);
          });
      }
    }
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="reset-pass-container">
          <TopBar haveAccount="Don’t have an account?" signInUp="Sign up" />
          {/* <Container> */}
          <div className="form-container">
            <div className="form-box">
              <form>
                <p className="form-heading">Reset password</p>

                <p className="form-label">New password</p>
                <div className="input-container">
                  <input
                    className="input-field"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setArePasswordEqual(true);
                      setPasswordError(false);
                    }}
                    onBlur={validatePassword}
                  />
                  {newPassword.length > 0 && (
                    <span
                      className="password-toggle"
                      onClick={toggleShowNewPassword}
                      style={{
                        backgroundImage: `url(${GetImages.hidePasswordIcon})`,
                      }}
                    ></span>
                  )}
                </div>
                {passwordError && (
                  <p className="error-message">Password can't be empty</p>
                )}

                <div className="confirm-password-container">
                  <p className="form-label">Re-enter new password</p>
                  <div className="input-container">
                    <input
                      className="input-field"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Re-enter new password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setArePasswordEqual(true);
                        setConfirmPasswordError(false);
                      }}
                      onBlur={validateConfirmPassword}
                    />
                    {confirmPassword.length > 0 && (
                      <span
                        className="password-toggle"
                        onClick={toggleShowConfirmPassword}
                        style={{
                          backgroundImage: `url(${GetImages.hidePasswordIcon})`,
                        }}
                      ></span>
                    )}
                  </div>
                  {confirmPasswordError && (
                    <p className="error-message">Password can't be empty</p>
                  )}
                </div>

                <button
                  type="button"
                  className="reset-btn"
                  onClick={setPassword}
                >
                  Reset password
                </button>
              </form>
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>

      {comingFromResetPass && (
        <SuccessAlert
          setShowSuccessAlert={comingFromResetPass}
          message={"Successfully Reset Password"}
        />
      )}
    </>
  );
};

export default ResetPassword;
