import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { OrganizationService } from "@propertelligent/client-api";
import AccountingDropDown from "../../components/common/AccountingDropDown";
import EmptyData from "../../components/common/EmptyData";
import SimpleSpinner from "../loader";
import DateHelper from "../../utils/DateHelper";
import { GetImages } from "../../utils/GetImages";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import useWindowDimensions from "../../hooks/windowDimensionsHook";

const OrganizationAccounts = () => {
  const [selectedType, setSelectedType] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  let isLoadingElement = null;

  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);

  const { rows, loading, hasMore, rowCount, fetchData, handleSort, currentPage, distinctFilterValues, aggregateValues, calculateValue} = infiniteScrollHook({
    httpFetchDataFn: OrganizationService.getApiOrganizationTransactionOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter ||
      `Created > DateTime.UtcNow.AddMonths(${rev_monthValue})`,
    FullyQualifiedSorts : fulllyQualifiedSort
  });

   if (loading) {
    isLoadingElement = (<tr><td colSpan={7}><SimpleSpinner myClassName={rows?.length == 0 ? 'h-50':'h_70'} /></td></tr>);
  }
  else {
    isLoadingElement = null;
  }

  const loadMoreRecords = () => {
    if (!loading) {
      fetchData(currentPage + 1);
    }
  }
  
  const [sentryRef] = useInfiniteScroll({
      loading: loading,
      hasNextPage: hasMore, // This can be dynamic based on your API response
      onLoadMore: loadMoreRecords,
      rootMargin: '0px 0px 100px 0px', // Adjust root margin as per your requirement
  });

  const handleTypeChange = (event, column) => {
    const selectedValue = event.target.value;
    setSelectedType((prevSelected) => {
      const updatedState = {
        ...prevSelected,
        [column]: selectedValue,
      };
      const result = Object.entries(updatedState)
        .map(([key, value]) => `${key} ${value}`)
        .join(",");
        SetFulllyQualifiedSort(result);
        handleSort();
      return updatedState;
    });
  };

  const handleCategoryChange = (event, column) => {
    const category = event.target.value;
    setSelectedCategories((prevSelected) => {
      const updatedColumnCategories = prevSelected[column] || [];
      if (updatedColumnCategories.includes(category)) {
        const updatedCategories = updatedColumnCategories.filter(
          (c) => c !== category
        );
        const newSelectedCategories = {
          ...prevSelected,
          [column]:
            updatedCategories.length > 0 ? updatedCategories : undefined,
        };

        Object.keys(newSelectedCategories).forEach((key) => {
          if (
            newSelectedCategories[key] === undefined ||
            newSelectedCategories[key].length === 0
          ) {
            delete newSelectedCategories[key];
          }
        });

        return newSelectedCategories;
      } else {
        return {
          ...prevSelected,
          [column]: [...updatedColumnCategories, category],
        };
      }
    });
  };

  useEffect(() => {
    const filterClauses = Object.entries(selectedCategories)
      .map(([column, values]) => {
        if (values && (values as string[]).length > 0) {
          const valueClauses = (values as string[])
            .map((value) => `${column}=="${value}"`)
            .join(" or ");
          return valueClauses ? `(${valueClauses})` : "";
        } else {
          return null;
        }
      })
      .filter(Boolean);

    const newFullyQualifiedFilters =
      filterClauses.length > 0 ? filterClauses.join(" and ") : null;

      SetFullyQualifiedFilter(newFullyQualifiedFilters);
  }, [selectedCategories, fulllyQualifiedFilter, fulllyQualifiedSort, rev_monthValue]);

  const { Category, From, To, PaymentType, PaymentMethod } = distinctFilterValues;

  const data = [
    {
      id: 1,
      img: GetImages.RedArrowInward,
      value: aggregateValues?.TotalRevenueFormatted,
      name: "Total Income",
    },
    {
      id: 2,
      img: GetImages.RedArrowOutward,
      value: aggregateValues?.TotalExpenseFormatted,
      name: "Total Expense",
    },
    {
      id: 3,
      img: GetImages.OrangeDoller,
      value: calculateValue?.NetProfitFormatted || 0,
      name: "Net Income",
    },
  ];

  const { height, width } = useWindowDimensions();  
  const fixedAreaHeight = 100;
  const [scrollContainerHeight, setScrollContainerHeight] = useState(height - fixedAreaHeight);

  useEffect(() => {
    setScrollContainerHeight(height - fixedAreaHeight);
  });

  return (
      <div className="AccountingContainer relativeContainer">
        <div className="w-100">
          <div className="accountingHeader border-bottom-0 ">
            <p className="FW700_LH32_main_text">{rowCount} Transactions</p>
            <div className="monthhead ">
              <div className="monthhead ">
                <button
                  onClick={() => setRev_MonthValue(-1)}
                  className={ rev_monthValue === -1 ? "overviewSelectedButton" : "overviewUnselectedButton"}
                >
                  Last month
                </button>
                <button
                  onClick={() => setRev_MonthValue(-6)}
                  className={
                    rev_monthValue === -6
                      ? "overviewSelectedButton"
                      : "overviewUnselectedButton"
                  }
                >
                  Last 6 month
                </button>
                <button
                  onClick={() => setRev_MonthValue(-12)}
                  className={
                    rev_monthValue === -12
                      ? "overviewSelectedButton"
                      : "overviewUnselectedButton"
                  }
                >
                  Last 12 Months
                </button>
              </div>
            </div>
          </div>
          <div className="accountingRow ">
            {data.map((item) => (
              <div className="accountingCard" key={item.id}>
                <div className="firstbox">
                  <div className="CommonRoundBackground BackgroundlightOrange Icon40">
                    <img src={item.img} />
                  </div>

                  <div className="commonbox">
                    <p className="FW700_LH24_main_text">$ {item.value}</p>
                    <p className="FW400_LH16_small_text">{item.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>               
        <div className="accountinf_table scrollingContainer" style={{ height : scrollContainerHeight }}>
          <Table bordered hover className="accountinf_table mb-0">
            <thead className="topFixedContainer">
              <tr>
                <th>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="FW700_LH24_main_text">Date</p>
                    <AccountingDropDown
                      sortName="created"
                      selectedType={selectedType}
                      handleTypeChange={(e) => handleTypeChange(e, "created")}
                      data={undefined}
                      name="created"
                      handleCategoryChange={(e) =>
                        handleCategoryChange(e, "created")
                      }
                      selectedCategories={selectedCategories["created"] || []}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="FW700_LH24_main_text">From</p>
                    <AccountingDropDown
                      sortName="paymentMethod.accountName"
                      selectedType={selectedType}
                      handleTypeChange={(e) =>
                        handleTypeChange(e, "paymentMethod.accountName")
                      }
                      data={From}
                      name="paymentMethod.accountName"
                      handleCategoryChange={(e) =>
                        handleCategoryChange(e, "paymentMethod.accountName")
                      }
                      selectedCategories={
                        selectedCategories["paymentMethod.accountName"] || []
                      }
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {" "}
                    <p className="FW700_LH24_main_text">To</p>
                    <AccountingDropDown
                      sortName="payeeAccount.accountName"
                      selectedType={selectedType}
                      handleTypeChange={(e) =>
                        handleTypeChange(e, "payeeAccount.accountName")
                      }
                      data={To}
                      name="payeeAccount.accountName"
                      handleCategoryChange={(e) =>
                        handleCategoryChange(e, "payeeAccount.accountName")
                      }
                      selectedCategories={
                        selectedCategories["payeeAccount.accountName"] || []
                      }
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {" "}
                    <p className="FW700_LH24_main_text">Payment Methods</p>{" "}
                    <div></div>
                    <AccountingDropDown
                      sortName="paymentMethod.PaymentMethodTypeId"
                      selectedType={selectedType}
                      handleTypeChange={(e) =>
                        handleTypeChange(
                          e,
                          "paymentMethod.PaymentMethodTypeId"
                        )
                      }
                      data={PaymentMethod}
                      name="paymentMethod.PaymentMethodTypeId
                    "
                      handleCategoryChange={(e) =>
                        handleCategoryChange(
                          e,
                          "paymentMethod.PaymentMethodTypeId"
                        )
                      }
                      selectedCategories={
                        selectedCategories[
                        "paymentMethod.PaymentMethodTypeId"
                        ] || []
                      }
                    />
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {" "}
                    <p className="FW700_LH24_main_text">Payment Type</p>{" "}
                    <AccountingDropDown
                      sortName="paymentDueAccount.PaymentTypeId"
                      selectedType={selectedType}
                      handleTypeChange={(e) =>
                        handleTypeChange(e, "paymentDueAccount.PaymentTypeId")
                      }
                      data={PaymentType}
                      name="paymentDueAccount.PaymentTypeId"
                      handleCategoryChange={(e) =>
                        handleCategoryChange(
                          e,
                          "paymentDueAccount.PaymentTypeId"
                        )
                      }
                      selectedCategories={
                        selectedCategories[
                        "paymentDueAccount.PaymentTypeId"
                        ] || []
                      }
                    />
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {" "}
                    <p className="FW700_LH24_main_text">Category</p>
                    <AccountingDropDown
                      sortName="TranactionCreditDebitTypeId"
                      selectedType={selectedType}
                      handleTypeChange={(e) =>
                        handleTypeChange(e, "TranactionCreditDebitTypeId")
                      }
                      data={Category}
                      name="TranactionCreditDebitTypeId"
                      handleCategoryChange={(e) =>
                        handleCategoryChange(e, "TranactionCreditDebitTypeId")
                      }
                      selectedCategories={
                        selectedCategories["TranactionCreditDebitTypeId"] ||
                        []
                      }
                    />
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {" "}
                    <p className="FW700_LH24_main_text">Amount</p>
                    <AccountingDropDown
                      sortName="amount"
                      selectedType={selectedType}
                      handleTypeChange={(e) => handleTypeChange(e, "amount")}
                      data={undefined}
                      name="amount"
                      handleCategoryChange={undefined}
                      selectedCategories={undefined}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            { 
              (rows?.length == 0 && !loading) ?
                (<tbody>
                  <tr>
                    <td colSpan={1000}> 
                      <EmptyData
                        mainText={undefined}
                        subText="No Transactions to display."
                        button={undefined}
                      />
                    </td>
                  </tr>
                </tbody>
                
              ) : (   
                <>
                  <tbody className="tableBody scrollingContent">
                      {rows?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {DateHelper.convertUTCtoDateTime(
                              item?.formatted?.CreatedDateWithTime,
                              true
                            )}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {item?.paymentMethod?.accountName}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {item?.payeeAccount?.accountName}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {item?.paymentMethod?.formatted?.PaymentMethodTypeId}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {item?.paymentDueAccount?.formatted?.PaymentTypeId}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">
                            {" "}
                            {item.formatted.TranactionCreditDebitTypeId}
                          </p>{" "}
                        </td>
                        <td>
                          <p className="FW400_LH20_FS14_label">$ {item?.formatted?.AmountRaw}</p>{" "}
                        </td>
                      </tr>
                    ))}
                    {isLoadingElement}                  
                  </tbody>
                  <tbody ref={sentryRef} />
                </>
            )}
          </Table>
        </div>
      </div>
  );
};

export default OrganizationAccounts;
