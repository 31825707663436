import React from "react";
import { useNavigate } from "react-router";
import ProgressBar from "../../common/ProgressBar";
import NameAvatarComponent from "../NameAvatarComponent";
import StatusTag from "../../common/StatusTag";
import { GetImages } from "../../../utils/GetImages";
import "./../tenantStyles.css";
import { TenantLeaseTypesEnum } from "@propertelligent/client-api";

const CardView = (data) => {
  const navigate = useNavigate();
  const disable = false;

  return (
    <>
      <div
        key={data.item.id}
        onClick={() => {
          navigate(`tenant/${data.item.id}`);
        }}
        className={`singleRowContainer ${disable ? "disable" : "clickable"}`}
      >
        <div className="nameDetails d-flex flex-row gap-2 align-data.items-center ">
          <NameAvatarComponent
            firstName={data.item.firstName}
            lastName={data.item.lastName}
            heightWidth={40}
          />
          <div>
            <div className="nameDueContainer">
              <p className="nameHeading">{`${data.item.firstName} ${data.item.lastName}`}</p>
              {data.item?.expensesDue !== 0 && (
                <StatusTag
                  color={"red"}
                  icon={<img src={GetImages.redArrow} />}
                  isLarge={false}
                  statusText={`$${data.item.expensesDue} due`}
                  disabled={disable}
                />
              )}
            </div>
            <div className="leftSideBox">
              <p className="leftSideBoxText">
                {data.item.email && data.item.email}{" "}
              </p>
              <p className="leftSideBoxText">•</p>
              <p className="leftSideBoxText">
                {data.item.phoneNumber && data.item.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <div className="middleBoxDetails">
          <p className="addressDetails">
            {data.item.unitNumber && data.item.unitNumber}
          </p>
          <div className="dashLine"></div>
          <p className="addressDetails1">
            {data.item.property?.address?.streetAddress?.trim() == ""
              ? "N/A"
              : `${data.item.property?.address?.streetAddress}`}
          </p>
        </div>

        {!disable &&
          (data.item.leaseRemainingMonths !== null ||
          data?.item?.currentLeaseInfo?.tenantLeaseTypeId ===
            TenantLeaseTypesEnum.MonthToMonth ? (
            <ProgressBar
              monthsRemaining={data.item.leaseRemainingMonths}
              leaseType={
                data?.item?.currentLeaseInfo?.formatted?.TenantLeaseTypeId
              }
            />
          ) : (
            <StatusTag
              color={"yellow"}
              statusText={data.item?.formatted?.UserStatus}
            />
          ))}
      </div>
    </>
  );
};

export default CardView;
