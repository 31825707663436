// import React from "react";
// import homeIcon from "../../assets/homeIcon.png";
// import { convertDate } from "../../constants/constants";

// interface ServiceRequest {
//   formatted?: {
//     ServiceType?: string;
//     RequestDateDateOnly: Date;
//     address: string;
//   };
// }

// interface RecentServiceRequestComponentProps {
//   data: ServiceRequest[];
// }

// const RecentServiceRequestComponent = ({
//   data,
// }: RecentServiceRequestComponentProps) => {
//   return (
//     <div className="d-flex flex-column gap-2">
//       {data.slice(0, 5).map((serviceRequest, index) => (
//         <div
//           key={index}
//           className="recentServiceRequestComponentStyles d-flex align-items-center justify-content-between flex-wrap"
//         >
//           <div className="d-flex flex-column gap-2">
//             <div className="outletRepairComponent ms-0 d-flex gap-1  ">
//               <p>{serviceRequest?.formatted?.ServiceType}</p>
//             </div>
//             <div className="d-flex align-items-center imgAddressContainer">
//               <img src={homeIcon} alt="Home Icon" />
//               <p className="typeMailPhoneText">
//                 {serviceRequest?.formatted?.address}
//               </p>
//             </div>
//           </div>
//           <p className="sinceTimePeriod">
//             {convertDate(serviceRequest?.formatted?.RequestDateDateOnly)}
//           </p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RecentServiceRequestComponent;

import React from "react";
import homeIcon from "../../assets/homeIcon.png";
import { convertDate } from "../../constants/constants";
import { Button } from "react-bootstrap";
import StatusTag from "../common/StatusTag";
import calendar from "../../assets/calendar.png";
import { useNavigate } from "react-router";
import EmptyData from "../common/EmptyData";

interface ServiceRequest {
  id: number;
  streetAddress: null;
  address: null;
  formatted?: {
    ServiceRequestStatus: string;
    ServiceType?: string;
    ServiceSubType?: string;
    RequestDateDateOnly: Date;
    address: string;
  };
}

interface RecentServiceRequestComponentProps {
  data: ServiceRequest[];
}

const RecentServiceRequestComponent = ({
  data,
}: RecentServiceRequestComponentProps) => {
  const navigate = useNavigate();

  const handleRequestClick = (id: number) => {
    navigate(`/servicerequest/edit/${id}`);
  };
  return (
    <div className="d-flex flex-column gap-2 w-100 h-100">
      {data.length > 0 ? (
        <>
          {data.slice(0, 5).map((serviceRequest, index) => (
            <div
              key={index}
              onClick={() => handleRequestClick(serviceRequest.id)}
              className="recentServiceRequestComponentStyles d-flex align-items-center justify-content-between flex-wrap"
            >
              <div className="d-flex flex-column gap-2">
                <div className="outletRepairComponent ms-0 d-flex gap-1  ">
                  <p>
                    {serviceRequest?.formatted?.ServiceType} :{" "}
                    {serviceRequest?.formatted?.ServiceSubType}
                  </p>
                </div>
                <div className="d-flex align-items-center imgAddressContainer">
                  <img src={homeIcon} alt="Home Icon" />
                  <p className="typeMailPhoneText">
                    {serviceRequest?.streetAddress == null
                      ? "No Address Available"
                      : serviceRequest?.streetAddress}
                  </p>
                </div>
              </div>

              <div className="d-flex flex-column gap-2 align-items-end">
                <div className="propertCardRight">
                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "In Progress" && (
                    <StatusTag
                      color={"yellow"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "Payment Pending" && (
                    <StatusTag
                      color={"red"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "Completed" && (
                    <StatusTag
                      color={"green"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "Open" && (
                    <StatusTag
                      color={"yellow"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "New Request" && (
                    <StatusTag
                      color={"lightBlue"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "Estimating" && (
                    <StatusTag
                      color={"lightBlue"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}
                  {serviceRequest?.formatted?.ServiceRequestStatus ===
                    "Verification Pending" && (
                    <StatusTag
                      color={"blue"}
                      statusText={
                        serviceRequest?.formatted?.ServiceRequestStatus
                      }
                    />
                  )}

                
                </div>
                <div className="d-flex align-items-center gap-1">
                  <img src={calendar} className="contractor-iconstyle" />
                  <span className="overview-small-text">
                    Requested Date :{" "}
                    {convertDate(
                      serviceRequest?.formatted?.RequestDateDateOnly
                    )}
                  </span>
                </div>
           
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <EmptyData
            mainText={undefined}
            subText="Data Not Available"
            button={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default RecentServiceRequestComponent;
