import Adduser from "./Adduser";
import EditUser from "./EditUser";
import MyOrganizationView from "./view";
import { Route, Routes } from "react-router";

export const MyOrganization = () => {
  return (
    <Routes>
      <Route index element={<MyOrganizationView />} />
      <Route path="/create" element={<Adduser />} />
      <Route path="/edit/:id" element={<EditUser />} />
    </Routes>
  );
};
