import { Alert } from "react-bootstrap"
import { GetImages } from "../../utils/GetImages"

const SuccessAlert = ({ setShowSuccessAlert, message }) => {
	return (
		<div className="addTenantAlert">
			<Alert variant="success">
				<img width="24px" height="24px" src={GetImages.success} />
				<div>{message}</div>
				<img onClick={() => setShowSuccessAlert(false)} src={GetImages.closeIconBlack} />
			</Alert>
		</div>
	)
}

export default SuccessAlert
