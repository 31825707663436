import React, { useEffect, useMemo } from "react";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import EmptyData from "./EmptyData";
import CardView from "../servicerequest/listView/cardView";
type Props = {
  id?: string;
  httpFetchDataFn: any;
  setTotalServiceRequest: any;
  loadingStates: any;
  setLoadingStates: any;
};

const ServiceRequestList = React.memo(
  ({
    id,
    httpFetchDataFn,
    setTotalServiceRequest = null,
    loadingStates,
    setLoadingStates,
  }: Props) => {
    const { rows, rowCount, loading } = usePaginationHook(
      useMemo(
        () => ({
          httpFetchDataFn: httpFetchDataFn,
          paginationDataMapper: null,
          parentEntityId: Number(id),
          isDependentEntity: true,
        }),
        [id]
      )
    );

    useEffect(() => {
      if (!loading) {
        setLoadingStates({
          ...loadingStates,
          serviceRequest: false,
        });
      }
    }, [loading]);

    setTotalServiceRequest && setTotalServiceRequest(rowCount);
    return (
      <>
        <div className="single_pro_UnitList">
          {rows?.length === 0 ? (
            <EmptyData
              mainText={undefined}
              subText="Data Not Available"
              button={undefined}
            />
          ) : (
            rows?.map((item, i) => {
              return (
                <div className="d-flex w-100" key={i}>
                  <CardView item={item} />
                </div>
              );
            })
          )}
        </div>
      </>
    );
  }
);

export default ServiceRequestList;
