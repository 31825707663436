import { CommunicationService, IntegrationsService } from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import AddGmailAccount from "../Messaging/Email/components/AddGmailAccount";
import Compose from "../Messaging/Email/components/Compose";

const SendReminder = () => {
    const [refreshtoken, setRefreshToken] = useState<Boolean>()
    useEffect(() => {
        CommunicationService.getApiGmailHasRefreshToken().then((res) => {
            if (res.success == true) {
                setRefreshToken(res?.success)
            }
        }
        ).catch((error) => {})

    }, [refreshtoken])

    return (<>
        <div className="p-3 h-100">
            {!refreshtoken ?
                <AddGmailAccount
                border={false}
                /> : (<>
                    <Compose getEmailMessages={undefined} replyId={undefined} emailId={undefined} setReplyId={undefined} replytype={undefined} />

                </>)}
        </div>
    </>)

}

export default SendReminder