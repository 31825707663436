const AddButton = ({ onClick, bname }) => {
  return (
    <>
      <button onClick={onClick} className="getStartedButton FW600_LH20_button_white_text">
        {bname}
      </button>
    </>
  );
};

export default AddButton;
