import { ServiceRequestService } from "@propertelligent/client-api";
import {
  REQUEST_GET_SERVICE_REQUEST,
  SUCCESS_GET_SERVICE_REQUEST,
  FAIL_GET_SERVICE_REQUEST,
  REQUEST_PUT_SERVICE_REQUEST,
  SUCCESS_PUT_SERVICE_REQUEST,
  FAIL_PUT_SERVICE_REQUEST,
} from "./types";

export const getServiceRequest = (serviceReqId) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_GET_SERVICE_REQUEST });

    const res = await ServiceRequestService.getApiServiceRequest1(serviceReqId);

    if (res?.success) {
      dispatch({ type: SUCCESS_GET_SERVICE_REQUEST, payload: res?.success });
    } else {
      throw new Error("Fetching Service request was not successful.");
    }
  } catch (error) {
    dispatch({
      type: FAIL_GET_SERVICE_REQUEST,
      payload: error?.response?.data?.message || error.message,
    });
  }
};

export const updateServiceRequest =
  (serviceRequestData) => async (dispatch) => {
    try {
      dispatch({ type: REQUEST_PUT_SERVICE_REQUEST });

      const res = await ServiceRequestService.putApiServiceRequest(
        serviceRequestData
      );
      console.log("Veerification")
      if (res?.success) {
        dispatch({ type: SUCCESS_PUT_SERVICE_REQUEST, payload: res?.success });
      } else {
        throw new Error("Updating Service request was not successful.");
      }
    } catch (error) {
      dispatch({
        type: FAIL_PUT_SERVICE_REQUEST,
        payload: error?.response?.data?.message || error.message,
      });
    }
  };

