import { Route, Routes, useNavigate, useParams } from "react-router";
import LeaseApplication from "./leaseApplication";
import LeaseAgreement from "./leaseAgreement";
import ProfitAndLossStatement from "./profitAndLossStatement";
import RentRoll from "./rentRoll";
import { useEffect } from "react";

export const GeneratePdfDocument = () => {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  
  useEffect(() => {
    let documentType = query.get('documentType');
    if(documentType){
      navigate("/generatePdfDocument/" + documentType);
    }
  }, []);
  
  return (<> 
  <Routes>
    <Route path="/lease-application" element={<LeaseApplication />} />
    <Route path="/lease-agreement" element={<LeaseAgreement />} />
    <Route path="/pnl" element={<ProfitAndLossStatement />} />        
    <Route path="/rent-roll" element={<RentRoll />} />
   </Routes>
  </>)
};
