import * as React from "react";
import { useState, useEffect, ChangeEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  emailRegex,
  nameRegex,
  passwordRegex,
  mobileNumberRegex,
} from "../common/Regex";
import { LoginService, OpenAPI } from "@propertelligent/client-api";
import PI, { PhoneInputProps } from "react-phone-input-2";

const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI;
import "react-phone-input-2/lib/style.css";

import { TopBar } from "../common/TopBar";
import "./SignupStyles.css";
import Inputs from "../common/Inputs";
import OtpInput from "./OtpInput";
import { Button, Form } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import { UserLoginData } from "../common/charts/Enums";
import { URLS, USER_ROLES, USERS } from "../../constants/constants";
import { MyContext } from "../../utils/context/LoginContext";
type Values = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  organizationName: string;
};

type ErrorMessages = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  number: string;
  organization: string;
  signUpError: String;
};

const Signup = () => {
  document.title = "Signup | Propertelligent";
  const { isLogin, setIsLogin } = useContext(MyContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [signedIn, SetSignedIn] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [organizationError, setOrganizationError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    password: "This field is required",
    number: "This field is required",
    organization: "This field is required",
    signUpError:
      "Some error occured, please make sure that email and phone number has not already been registered",
  });
  const [codeError, setCodeError] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [resendPassword, setResendPassword] = useState<boolean>(false);
  const initialSeconds: number = 59;
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [historyPageURL, setHistoryPageURL] = useState(
    window?.location?.pathname
  );
  const navigate = useNavigate();
  const [values, setValues] = useState<Values>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    organizationName: "",
  });
  const [intervalId, setIntervalId] = useState(null);
  const [signUpError, setSignUpError] = useState(false);
  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSignUpError(false);
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setNumberError(false);
        setErrorMessages({
          ...errorMessages,
          number: "This field is required",
        });
        break;
      case "password":
        setPasswordError(false);
        setErrorMessages({
          ...errorMessages,
          password: "This field is required",
        });
        break;
      case "organizationName":
        setOrganizationError(false);
        setErrorMessages({
          ...errorMessages,
          organization: "This field is required",
        });
        break;
      default:
        break;
    }

    //   // Update values state
    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.organizationName.trim() === "") {
      setOrganizationError(true);
    }

    if (values.password.trim() === "") {
      setPasswordError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setNumberError(true);
    }
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase(); // Convert email to lowercase
      setValues({ ...values, email: lowercaseEmail }); // Update the email in state with lowercase

      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        // setEmailError({
        //   ...errorMessages,
        //   email: "Please enter a valid email",
        // });
        return true;
      }
    }
    return false;
  };
  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     if (emailRegex.test(values.email)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 40
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Name should have length between 2-40 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 40
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Name should have length between 2-40 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validatePassword = (): boolean => {
    if (values.password.length !== values.password.trim().length) {
      setPasswordError(true);
      setErrorMessages({
        ...errorMessages,
        password: "Spaces are not allowed",
      });
      return true;
    }

    if (values.password !== "" && !passwordRegex.test(values.password)) {
      setPasswordError(true);
      setErrorMessages({
        ...errorMessages,
        password:
          "Password must be at least 8 characters and it must have at least one number, one uppercase letter, and one special character",
      });
      return true;
    }

    return false;
  };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setNumberError(false);
      } else {
        setNumberError(true);
        setErrorMessages({
          ...errorMessages,
          number: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };

  const handleCodeInputChange = (value: string) => {
    setCodeError(false);
    setCode(value);
    const requestObject = { email: values.email, code: Number(value) };
    if (value.length == 6) {
      setLoading(true);
      LoginService.putApiLoginVerifyEmail(requestObject)
        .then((response: any) => {
          if (response.success) {
            setLoading(false);
            setSuccess(true);
            stopCountdown();
            // navigate("/login", {
            //   state: {
            //     isComingFromSignUP: true,
            //   },
            // });
            localStorage.setItem(UserLoginData.token, response.success.token);
            OpenAPI.TOKEN = response.success.token;

            localStorage.setItem(
              UserLoginData.user_name,
              response.success.firstName + " " + response.success.lastName
            );
            localStorage.setItem(
              UserLoginData.user_id.toString(),
              response.success.userId
            );
            localStorage.setItem(
              UserLoginData.organization_id,
              response.success.organizationUserInfo.organizationId
            );
            localStorage.setItem(
              UserLoginData.currency_symbol,
              response.success.currencySymbol
            );

            localStorage.setItem(
              "userOnboarded",
              response.success.userOnboarded
            );
            localStorage.setItem("userRoleId", response?.success?.userRoleId);

            if (USER_ROLES[response?.success?.userRoleId] === USERS.TENANT) {
              localStorage.setItem(
                "tenantInfo",
                JSON.stringify(response?.success?.tenantInfo)
              );
            } else if (
              USER_ROLES[response?.success?.userRoleId] === USERS.CONTRACTOR
            ) {
              localStorage.setItem(
                "contractorInfo",
                JSON.stringify(response?.success?.contractorInfo)
              );
            }

            setIsLogin(true);
            if (response?.success?.userOnboarded == false) {
              navigate(URLS.QUESTIONNAIRE);
            } else if (historyPageURL !== URLS.HOME) {
              navigate(historyPageURL);
            } else {
              navigate(URLS.HOME);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setCodeError(true);
        });
    }
  };

  const startCountdown = () => {
    setSeconds(59);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          setResendPassword(true);
          clearInterval(id); // Clear the interval when countdown reaches 0
          return prevSeconds; // Return the same value to prevent further state updates
        } else {
          return prevSeconds - 1; // Update the countdown value
        }
      });
    }, 1000);

    // Store the interval ID in state
    setIntervalId(id);
  };

  const stopCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval
      setIntervalId(null); // Reset the interval ID in state
    }
  };

  const formatTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  const submitTimeError = () => {
    return (
      firstNameError ||
      lastNameError ||
      passwordError ||
      numberError ||
      emailError ||
      organizationError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.password.trim() === "" ||
      values.organizationName.trim() === "" ||
      values.email.trim() === "" ||
      values.phoneNumber.trim() === ""
    );
  };

  const handleSignUp = (e: Values) => {
    checkEmptyFields();
    if (!submitTimeError()) {
      setLoading(true);
      LoginService.postApiLoginSignup(e)
        .then((response: any) => {
          if (response.success) {
            setLoading(false);
            setSeconds(59);
            SetSignedIn(true);
            startCountdown();
          }
        })
        .catch((error) => {
          setLoading(false);
          setSignUpError(true);
        });
    }
  };
  return (
    <div
      className="signupFullScreen"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
        height: signedIn ? "100vh" : "100%",
        paddingBottom:
          firstNameError ||
          lastNameError ||
          passwordError ||
          numberError ||
          emailError ||
          organizationError
            ? "100px"
            : "0px",
      }}
    >
      {/* TopBar */}
      <TopBar haveAccount="Have an account?" signInUp="Log in" />

      {/* Main Content. Some Information and Sign up form */}
      <div className="mainContainer">
        {/* Left Side Information */}
        <div className="leftSideMainContainer">
          <div className="leftSideContainerContent">
            <p className="leftSideTitleText">What sets Propertelligent apart</p>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.smart} />
              </div>
              <div>
                <p className="boxTitle">Smart Rental Applications</p>
                <p className="boxDescription">
                  Easily screen the right tenant for your properties with the
                  information that is most important to you.
                </p>
              </div>
            </div>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.finance} />
              </div>
              <div>
                <p className="boxTitle">Finance Tools Just for Landlords</p>
                <p className="boxDescription">
                  Optimize your finances with industry-leading accounting and
                  tax prep tools built for landlords.
                </p>
              </div>
            </div>
            <div className="textBox">
              <div className="smallLeftImage">
                <img src={GetImages.simplified} />
              </div>
              <div>
                <p className="boxTitle">Simplified Rent Collection</p>
                <p className="boxDescription">
                  Easily screen the right tenant for your properties with the
                  information that is most important to you.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right side Forms */}
        <div className="rightSideFormContainer">
          {signedIn ? (
            // Email verify code
            <div className="emailVerifyMainBox">
              <div>
                <p className="verifyEmailTitle">
                  Enter email verification code
                </p>
                <p className="verifyEmailText">
                  A 6-digit code was sent to{" "}
                  <span className="emailSpan">{values.email},</span>
                </p>
                <div style={{ marginBottom: codeError ? "8px" : "32px" }}>
                  <OtpInput
                    handleCodeInputChange={handleCodeInputChange}
                    code={code}
                    setCode={setCode}
                    status={signedIn}
                    comingFrom="signup"
                  />
                </div>
                {codeError && (
                  <p className="incorrectCodeError">
                    Incorrect code. Please try again
                  </p>
                )}
                {resendPassword ? (
                  <p className="resendpassword">
                    <span className="resendCodeText">Resend code</span>
                  </p>
                ) : (
                  <p className="resendInText">
                    Resend In
                    <span className="resendCounter">
                      {formatTime(Math.floor(seconds / 60))}:
                      {formatTime(seconds % 60)}
                    </span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            // Sign up form Form
            <div className="formContainer">
              <p className="signInFormTitle">Create an account</p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignUp(values);
                }}
              >
                {/* All Inputs */}
                <div className="allInputsWrapper">
                  {/* FirstName Input */}
                  <Inputs
                    labelText="First Name*"
                    errorName={firstNameError}
                    value={values.firstName}
                    onChangeFunction={handleChange}
                    onBlurFunction={validateFirstName}
                    errorMessages={errorMessages.firstName}
                    id="firstNameInput"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    isPassword={false}
                    focus={true}
                  />

                  {/* LastName input */}
                  <Inputs
                    labelText="Last Name*"
                    errorName={lastNameError}
                    value={values.lastName}
                    onChangeFunction={handleChange}
                    onBlurFunction={validateLastName}
                    errorMessages={errorMessages.lastName}
                    id="lastNameInput"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    isPassword={false}
                    focus={false}
                  />

                  {/* PhoneNumber Input */}
                  <div className="singleInputsWrapper">
                    <p className="labelText">Phone Number*</p>
                    <ReactPhoneInput
                      country="us"
                      value={values.phoneNumber}
                      onChange={(phone) => {
                        setValues({ ...values, phoneNumber: phone });
                        setNumberError(false);
                      }}
                      copyNumbersOnly={false}
                      onBlur={validateNumber}
                      containerClass="phoneInputContainer"
                      containerStyle={{
                        border: numberError
                          ? "2px solid #F05252"
                          : "1px solid #E5E7EB",
                        marginBottom: numberError ? "8px" : "16px",
                      }}
                      buttonClass="phoneInputButton"
                      inputClass="phoneInputClass"
                    />
                    {numberError && (
                      <div style={{ marginBottom: "16px" }}>
                        <p className="errorMessageStyles">
                          {errorMessages.number}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Email Input */}
                  <Inputs
                    labelText="Email*"
                    errorName={emailError}
                    value={values.email}
                    onChangeFunction={handleChange}
                    onBlurFunction={validateEmail}
                    errorMessages={errorMessages.email}
                    id="emailInput"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    isPassword={false}
                    focus={false}
                  />

                  {/* OrganizationName Input */}
                  <Inputs
                    labelText="Organization Name*"
                    errorName={organizationError}
                    value={values.organizationName}
                    onChangeFunction={handleChange}
                    onBlurFunction={validateEmail}
                    errorMessages={errorMessages.organization}
                    id="organizationNameInput"
                    name="organizationName"
                    type="text"
                    placeholder="Organization Name"
                    isPassword={false}
                    focus={false}
                  />

                  {/* Password Input */}
                  <Inputs
                    labelText="Password*"
                    errorName={passwordError}
                    value={values.password}
                    onChangeFunction={handleChange}
                    onBlurFunction={validatePassword}
                    errorMessages={errorMessages.password}
                    id="passwordInput"
                    name="password"
                    type="text"
                    placeholder="Password"
                    isPassword={true}
                    focus={false}
                  />
                </div>

                {/* Signup Button */}
                <Button
                  id="signUpButton"
                  type="submit"
                  variant="contained"
                  className="signUpButton"
                  disabled={
                    firstNameError ||
                    lastNameError ||
                    passwordError ||
                    organizationError ||
                    emailError ||
                    numberError
                  }
                >
                  {loading ? (
                    <img height={50} src={GetImages.loadingGif} />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
                {signUpError && (
                  <div style={{ marginBottom: "16px" }}>
                    <p className="signUpErrorMessageStyles">
                      {errorMessages.signUpError}
                    </p>
                  </div>
                )}
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
