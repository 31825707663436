import React from "react";
import { Dropdown } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";

const FilterComponent = () => {
  return (
    <div>
      {/* <Dropdown>
      <Dropdown.Toggle
        className="subTopBarComponents clickable"
        id="dropdown-basic"
      >
        <img src={GetImages.filter} />
        <p className="addFiltersButton">Add filters</p>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
    </div>
  );
};

export default FilterComponent;
