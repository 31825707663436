import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserLoginData } from "../common/charts/Enums";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  EntityType,
  PaymentService,
  TenantService,
  UnitService,
} from "@propertelligent/client-api";
import { useAssociateEntityHook } from "../../hooks/useAssociateEntityHook";
import { useLeaseDocumentHook } from "../../hooks/useLeaseDocumentHook";
import EntityPaymentAccountList from "../common/EntityPaymentAccountList";
import { getSizeString } from "../../utils/helper";
import { URLS } from "../../constants/constants";

export const TenantEdit = () => {
  const initialUnitValues = {
    id: 0,
  };
  const [tenantData, setTenantData] = useState({ unitId: null, userId: null });
  const [unitData, setUnitData] = useState(initialUnitValues);
  const [openUnitModal, setOpenUnitModal] = useState(false);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [associatedUnitId, setAssociatedUnitId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [value, setValue] = useState(0);

  const { id } = useParams();
  const tenantId = parseInt(id);
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const navigate = useNavigate();

  useEffect(() => {
    // Fetching tenant data
    TenantService.getApiTenant1(tenantId)
      .then((response: any) => {
        setTenantData(response?.success);
        setUserId(response?.success?.userId);
      })
      .catch((tenantErr: any) => { });

    // Fetching available units
    UnitService.getApiUnitOrganizationlist(orgId)
      .then((tenantsRes: any) => {
        setAvailableUnits(tenantsRes?.success);
      })
      .catch((tenantErr: any) => { });

    // Fetching payment methods
    PaymentService.getApiPaymentMethodTenantlist(tenantId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPaymentMethodsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});

    // Fetching payee accounts
    PaymentService.getApiPayeeAccountTenantlist(tenantId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => { });
  }, []);

  const handleSubmit = (e: any) => {
    TenantService.putApiTenant(e)
      .then((res: any) => {
        if (res.success) {
          navigate(URLS.TENANTS);
        }
      })
      .catch((putErr) => { });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formatNameByInfo = (info: any) => (
    <>
      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        {"Area: "}
        <span>{getSizeString(info?.areaInSquareFeet)}</span>
      </span>
      <br />
      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        {"Rent: "}
        <span>{info?.rent}</span>
      </span>
    </>
  );

  const { AllLeaseDocuments, UploadLeaseDocument } = useLeaseDocumentHook({
    httpEntityDocumentInfoFn: DocumentService.getApiDocumentList,
    httpEntityDocumentUploadFn: DocumentService.postApiDocument,
    entityId: tenantId,
    entityTypeId: DocumentEntityTypesEnum.Tenant,
    documentTypeId: DocumentTypesEnum.Other,
    title: "Lease documents:",
    canUploadDocs: true,
    canDisplayDocs: true,
  });

  const { AssociateComponent } = useAssociateEntityHook({
    httpAssociateEntityInfoFn: UnitService.getApiUnit1,
    setOpenModal: setOpenUnitModal,
    formatNameByInfo: formatNameByInfo,
    setInfoByParent: setUnitData,
    entityId: associatedUnitId || tenantData?.unitId,
    initialValues: initialUnitValues,
    title: "Unit Details:",
    buttonText: "Associate Unit",
    isAssociated: unitData?.id != 0,
  });

  const handleSubmitAssociateUnit = (unitId: number) => {
    TenantService.putApiTenantAssociate(tenantId, unitId)
      .then((res: any) => {
        if (res?.success) {
          setAssociatedUnitId(unitId);
          setOpenUnitModal(false);
        }
      })
      .catch((astErr: any) => {});
  };

  return (
    <>
      <div>
        <div>
          <button onClick={() => setValue(0)}>Information</button>
          <button onClick={() => setValue(1)}>Payment</button>
        </div>
      </div>
      {value === 0 && (
        <div>
          <div>
            <EntityPaymentAccountList
              id={id}
              payMethodField={"name"}
              entityTypeId={EntityType.Tenant}
              paymentMethodsArr={paymentMethodsArr}
              payeeAccountsArr={payeeAccountsArr}
              httpFetchDataFn={PaymentService.getApiPaymentDueAccountTenantlist}
            />
          </div>
          <div>
            {AssociateComponent}
            {AllLeaseDocuments}
            {UploadLeaseDocument}
          </div>
        </div>
      )}
    </>
  );
};
