import React from 'react';

const RentRoll = () => {
  return (
    <div>
      <h1>Rent Roll</h1>
      <p>Rent Roll Statement Goes Here!</p>
    </div>
  );
};

export default RentRoll;