import { Contractors } from "../components/contractors";
import { MyOrganization } from "../components/my-organization";
import { Properties } from "../components/properties";
import { ServiceRequests } from "../components/servicerequest";
import { Tenants } from "../components/tenants";
import { Units } from "../components/units";
import { USER_ROLES, MAIN_ROUTES_URLS } from "../constants/constants";
import { Dashboard } from "../dashboard";
import { OrganizationAccounts } from "../components/organizationAccounts";
import { Messaging } from "../components/Messaging";
import { GeneratePdfDocument } from "../components/generatePdfDocument";

export const routeList = {
  [USER_ROLES[1]]: [
    { id: "1", path: MAIN_ROUTES_URLS.HOME, element: <Dashboard /> },
    { id: "2", path: MAIN_ROUTES_URLS.UNITS, element: <Units /> },
    { id: "3", path: MAIN_ROUTES_URLS.PROPERTIES, element: <Properties /> },
    { id: "4", path: MAIN_ROUTES_URLS.TENANTS, element: <Tenants /> },
    {
      id: "5",
      path: MAIN_ROUTES_URLS.SERVICE_REQUESTS,
      element: <ServiceRequests />,
    },
    { id: "7", path: MAIN_ROUTES_URLS.CONTRACTORS, element: <Contractors /> },
    {
      id: "8",
      path: MAIN_ROUTES_URLS.ORGANIZATION,
      element: <MyOrganization />,
    },
    { id: "9", path: MAIN_ROUTES_URLS.MESSAGING, element: <Messaging /> },
    {
      id: "11",
      path: MAIN_ROUTES_URLS.ORGANIZATION_ACCOUNTS,
      element: <OrganizationAccounts />,
    },
    {
      id: "12",
      path: MAIN_ROUTES_URLS.GENERATE_PDF_DOCUMENT,
      element: <GeneratePdfDocument />,
    }
  ],  
};
