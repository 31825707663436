import NameAvatarComponent from "../../../tenants/NameAvatarComponent";
import EmptyData from "../../../common/EmptyData";
import AddButton from "../../../firstTimeUserExperience/commonCom/AddButton";
const Inbox = ({ setEmailId,emailId,emaillist,threadlist }) => {

 
  const categorizeEmails = (emailDate) => {
    const today = new Date().toDateString();
    const yesterday = new Date(Date.now() - 86400000).toDateString();
    if (emailDate === today) {
      return "Today";
    } else if (emailDate === yesterday) {
      return "Yesterday";
    } else {
      return "Older";
    }
  };

  
  const hasTodayEmails = threadlist.some(
    (email) => categorizeEmails(new Date(email?.date).toDateString()) === "Today"
  );
  const hasYesterdayEmails = threadlist.some(
    (email) => categorizeEmails(new Date(email?.date).toDateString()) === "Yesterday"
  );

  const data = [];

  if (hasTodayEmails) {
    data.push({
      id: "0",
      name: "Today",
     
    });
  }
  if (hasYesterdayEmails) {
    data.push({
      id: "1",
      name: "Yesterday",
     
    });
  }
  data.push({
    id: "2",
    name: "Older",
  });

   


  return (
    <>
      {/* <div className="InboxContainer">
        {threadlist?.length==0 ? <EmptyData mainText="No Email Yet"subText="Please click the button below to compose an email." button={ <AddButton onClick={undefined} bname="Compose Email" />}/> : */}
       
       <>
        {/* <div className="AccordianSection"> */}
      {threadlist?.length==0 ? <EmptyData mainText="No Email Yet"subText="Please click the button below to compose an email." button={ <AddButton onClick={undefined} bname="Compose Email" />}/> :
       <>
       {data?.map((item)=>(
        <div className="AccordianItem" key={item.id}>
          <div className="Accordianheader">
        <p className="FW400_LH16_small_text ">{item.name}</p>
          </div>
          <div className="emailItemContaner">
          {
                    threadlist?.filter((email) => categorizeEmails(new Date(email?.date).toDateString()) === item.name)?.map((email, i, emailsArray) => {
                      
                      const name = email?.fromName?.split(" ");
                      const isLastItem = i === emailsArray.length - 1;
                      const isEven = i % 2 === 0;
                      
                    return   ( <div
                      key={i}
                      style={{
                        background:
                        email?.id === emailId
                            ? "var(--Primary-Next-level-Lighter, #EBF5FF)"
                            : "",
                      }}
                      className={`emailItem ${isEven ? "" : "border-start-0"} ${isLastItem ? "border-bottom-0" : ""}`}
                      onClick={() => setEmailId(email?.id)}
                    > 
                     {/* <div className="emailsubitem d-flex w-100 "> */}
                         <div className="userIcon">
                            <NameAvatarComponent  firstName={name?.length>0 &&name[0]}
                          lastName={name?.length>1 ? name[1]:""} heightWidth={40} />
                          </div>
                          <div className="emailDetails">
                            <div className="email_details_header d-flex flex-row justify-content-between align-items-center w-100">
                              <p className="FW600_LH20_main_text ">{email?.fromName}</p>
                              <p className="FW400_LH16_small_text">{email?.displayDate}</p>
                            </div>
                            <p className="wrapemailSubject FW400_LH16_small_text">{email?.subject}</p>
                          </div>
                          {/* </div> */}
                        </div>
                        )
                      
                   })}
          </div>
        </div>
       ))}
          
          </> }         
        
        {/* </div> */}
       
       </>
         {/* }     
       </div> */}
      
    </>
  );
};


export default Inbox;