import React from 'react';

const LeaseApplication = () => {
  return (
    <div>
      <h1>Lease Application</h1>
      <p>Lease Application Goes Here!</p>
    </div>
  );
};

export default LeaseApplication;