import React from 'react';

const ProfitAndLossStatement = () => {
  return (
    <div>
      <h1>Profit And Loss Statement</h1>
      <p>Profit And Loss Statement Goes Here!</p>
    </div>
  );
};

export default ProfitAndLossStatement;