import { TenantService } from "@propertelligent/client-api";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import mail from "../../assets/mail.png";
import call from "../../assets/call.png";
import user_red from "../../assets/user_red.png";
import Time_Red from "../../assets/Time_Red.png";
import SimpleSpinner from "../loader";

type Props = {
  tenantId: any;
  loading: boolean;
  tenantData: any;
};

const flexContainerStyle = {
  display: "flex",
  padding: "var(--3, 12px)",
  alignItems: "center",
  gap: "var(--2, 8px)",
  alignSelf: "stretch",
  borderRadius: "var(--rounded-lg, 8px)",
  background: "var(--Primary-Next-level-Lighter, #EBF5FF)",
};

const textStyle = {
  color: "var(--bs-gray-900)",
  fontFamily: "Instrument Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  paddingLeft: "10px",
};

const TenantDetails = React.memo(({ tenantId, loading, tenantData }: Props) => {
  // const [tenantData, setTenantData] = useState<any>({});
  // const [loading, setLoading] = useState(true); // Add loading state

  // useEffect(() => {
  //   if (tenantId) {
  //     setLoading(true);
  //     TenantService.getApiTenant1(tenantId)
  //       .then((ptRes) => {
  //         if (ptRes?.success) {
  //           setTenantData(ptRes.success);
  //         }
  //       })
  //       .catch((ptErr) => { })
  //       .finally(() => {
  //         setLoading(false); // Set loading to false regardless of success or error
  //       });
  //   }
  // }, [tenantId]);

  return (
    <>
      {tenantData?.id !== undefined ? (
        <div style={flexContainerStyle}>
          <div className="singleTenantNameDetailsLeftSide">
            <div>
              <div style={textStyle}>Tenant details </div>

              <div>
                <div className="singleTenantUnitOr d-flex flex-wrap">
                  <span className="unitNameText">
                    <img height={16} width={16} src={user_red} />

                    <span style={textStyle}>
                      {" "}
                      {tenantData?.firstName} {tenantData?.lastName}
                    </span>
                  </span>

                  <span className="unitNameText">
                    <span style={{ marginRight: 5 }}>
                      <img height={16} width={16} src={Time_Red} />
                    </span>
                    <span style={textStyle}>
                      {tenantData?.property?.address?.streetAddress ||
                        tenantData?.property?.name ||
                        tenantData?.tenancySince}

                      {/* Since 3 months{" "} */}
                    </span>
                  </span>

                  <span className="unitNameText">
                    <img
                      height={16}
                      width={16}
                      src={mail}
                      style={{ marginRight: 5 }}
                    />
                    <span style={textStyle}>{tenantData?.email}</span>
                  </span>

                  <span className="unitNameText">
                    <img
                      height={16}
                      width={16}
                      src={call}
                      style={{ marginRight: 5 }}
                    />
                    <span style={textStyle}>{tenantData?.phoneNumber}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* {loading ? (
        <SimpleSpinner />
      ) : (
        <Row className="singleTenantNameDetailsLeftSide">
          <div>
            <div style={textStyle}>Tenant details </div>
            {tenantData?.id !== undefined ? "tenant found " :" no tenant found"}
            <Col>
              {
              tenantData && tenantData?.currentLeaseInfo?.tenantId !==null ? (
                <div className="singleTenantUnitOr">
                  <span className="unitNameText">
                    <img height={16} width={16} src={user_red} />

                    <span style={textStyle}>
                      {" "}
                      {tenantData?.firstName} {tenantData?.lastName}
                    </span>
                  </span>

                  <span className="unitNameText">
                    <span style={{ marginRight: 5 }}>
                      <img height={16} width={16} src={Time_Red} />
                    </span>
                    <span style={textStyle}>
                      {tenantData?.property?.address?.streetAddress ||
                        tenantData?.property?.name}
                      Since 3 months{" "}
                    </span>
                  </span>

                  <span className="unitNameText">
                    <img
                      height={16}
                      width={16}
                      src={mail}
                      style={{ marginRight: 5 }}
                    />
                    <span style={textStyle}>{tenantData?.email}</span>
                  </span>

                  <span className="unitNameText">
                    <img
                      height={16}
                      width={16}
                      src={call}
                      style={{ marginRight: 5 }}
                    />
                    <span style={textStyle}>{tenantData?.phoneNumber}</span>
                  </span>
                </div>
              ) : ( 
                <div>
                  <p>No tenant detail found</p>
                </div>
              )}
            </Col>
          </div>
        </Row>
      )} */}
        </div>
      ) : (
        " "
      )}
    </>
  );
});

export default TenantDetails;
