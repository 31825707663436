
import { useNavigate } from 'react-router-dom';
import rightArrow from "../../assets/chevron_right.png";

const RequestBreadcrumb = ({ firstName, link }) => {
  const navigate = useNavigate();
  return (
    <div className="singleTenantTopbar">
      <div
        onClick={() => {
          navigate(link);
        }}
        className="clickable"
      >
        <p className="singleTenantTopbarHeading">{firstName}</p>
      </div>
      <img src={rightArrow} />
      <p className="singleTenantTopbarSubHeading"> Detail</p>
    </div>
  );
};

export default RequestBreadcrumb;
