import { DocumentEntityTypesEnum, DocumentService, 
         DocumentTypesEnum, PropertyService, } from "@propertelligent/client-api";
import {
  FAIL_ADD_PROPERTY, FAIL_GET_ALL_PROPERTY,  FAIL_GET_PROPERTY,
  REQUEST_ADD_PROPERTY, REQUEST_GET_ALL_PROPERTY, REQUEST_GET_PROPERTY,
  SUCCESS_ADD_PROPERTY, SUCCESS_GET_ALL_PROPERTY, SUCCESS_GET_PROPERTY,
} from "./types";

export const addProperty =
  (propertyValue, mainImage, fileName, navigate, navigateto) =>
  async (dispatch) => {
    try {
      dispatch({ type: REQUEST_ADD_PROPERTY });
      const response = await PropertyService.postApiProperty(propertyValue);
      if (response?.success) {
        if (mainImage) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Property,
            EntityId: Number(response.success.id),
            DocumentTypeId: DocumentTypesEnum.BeforeImage,
            IsMain: true,
            Document: mainImage,
            FileName: fileName,
          };

          try {
            await DocumentService.postApiDocument(imgData);

            dispatch({ type: SUCCESS_ADD_PROPERTY, payload: response.success });
            navigate(navigateto, { state: { id: response.success.id } });
          } catch (imageUploadError) {
            // Handle image upload error
            console.error("Image upload error:", imageUploadError);
            notify("Image upload error.", {
              type: "error",
            });
          }
        } else {
          notify("Record has been added successfully!", {
            type: "success",
          });
        }
      }
    } catch (mainUnitPostError) {
      dispatch({ type: FAIL_ADD_PROPERTY });
    }
  };

  export const getProperty =  (propertyId) =>async(dispatch)=> {
    try {
      dispatch({ type:  REQUEST_GET_PROPERTY });
     
      const res = await PropertyService.getApiProperty1(propertyId);
  
      if (res.success) {
        dispatch({ type: SUCCESS_GET_PROPERTY, payload:res.success });
      } else {
        throw new Error('Property request was not successful.');
      }
    } catch (error) {
      dispatch({ type:  FAIL_GET_PROPERTY ,payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message });
    }
  };

// with organization id
export const getPropertyList = (orgdID) => async (dispatch) => {
  try {
    dispatch({ type:  REQUEST_GET_ALL_PROPERTY });
    PropertyService.getApiPropertyOrganizationlist(orgdID)
      .then((res) => dispatch({ type: SUCCESS_GET_ALL_PROPERTY, payload: res.success }))
      .catch((error) => {});
  } catch (error) {
    dispatch({ type: FAIL_GET_ALL_PROPERTY, payload: error.response && error.response.data.message
      ? error.response.data.message
      : error.message });
  }
};
