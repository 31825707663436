import React, { useState } from "react";
import RecentServiceRequestComponent from "./RecentServiceRequestComponent";
import RecentServiceHistoryComponent from "./RecentServiceHistoryComponent";
import ServiceRequestList from "../common/ServiceRequestList";
import { ServiceRequestService } from "@propertelligent/client-api";

const ServiceRequestComponent = ({ id }) => {
  const [totalServiceRequest, setTotalServiceRequest] = useState(10);
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    unit: true,
    serviceRequest: true,
  }); // Loading state
  return (
    <div className=" pt-0 transactionComponentContainer">
      <div className="transactionTopBar d-flex align-items-center justify-content-between">
        <p className="transactionTopBarHeading">{`Service request (${totalServiceRequest})`}</p>
      </div>

      <div className="serviceRequestComponentContainer d-flex flex-column gap-2">
        <ServiceRequestList
          loadingStates={loadingStates}
          setLoadingStates={setLoadingStates}
          id={id}
          httpFetchDataFn={
            ServiceRequestService.getApiServiceRequestContractorlist
          }
          setTotalServiceRequest={setTotalServiceRequest}
        />
        {/* <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent />
        <RecentServiceHistoryComponent /> */}
      </div>
    </div>
  );
};

export default ServiceRequestComponent;
