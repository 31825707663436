import { useNavigate } from "react-router";
import StatusTag from "../../common/StatusTag";
import ServiceIcon from "../../common/serviceTypeImage";
import { Button } from "react-bootstrap";
import DateHelper from "../../../utils/DateHelper";
import calendar from "../../../assets/calendar.png";
import Worker from "../../../assets/Worker.png";

const CardView = (data) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        key={`${data.item.id}`}
        className="propertyCardConatiner"
        onClick={() => navigate(`/servicerequest/edit/${data.item?.id}`)}
      >
        <div className="propertCardLeft" style={{ maxWidth: "43%" }}>
          <div className="imgcommonstyle">
            <ServiceIcon serviceType={data.item?.formatted?.ServiceType} />
          </div>

          <div className="propertyCardDisc">
            <div className="propertyCardDetails ">
              <div className="d-flex align-items-center ">
                <span className="p-main-text">
                  {data.item?.formatted.ServiceSubType}
                </span>
              </div>
              <div>
                <span className="p-sub-text">
                  {data.item?.formatted?.ServiceType}
                </span>
              </div>
            </div>
            <div className="propertyCardQuantity">
              <div className="d-flex align-items-center ">
                <img src={calendar} className="iconstyle" />
                <span className="overview-small-text">
                  Created on:{" "}
                  {DateHelper.convertUTCtoDateTimeSimple(
                    data.item?.formatted?.RequestDateDateOnly
                  )}
                </span>
              </div>
              {data.item?.assignedTo ? (
                <div className="d-flex align-items-center ">
                  <img src={Worker} className="iconstyle" />
                  <span className="overview-small-text">
                    Contractor: {data.item?.assignedTo}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="middleBoxDetails">
          <p className="addressDetails d-flex align-items-center gap-2">
            {data?.item?.unitNumber ? (
              <>
                <p className="addressDetails">{data.item.unitNumber}</p>
                <div className="dashLine"></div>
              </>
            ) : null}
          </p>

          <p className="addressDetails1">
            {data?.item?.streetAddress?.trim() == ""
              ? "N/A"
              : data?.item?.streetAddress}
          </p>
        </div>

        <div style={{ width: "30%" }} className="propertCardRight">
          {data.item?.formatted?.ServiceRequestStatus === "In Progress" && (
            <StatusTag
              color={"yellow"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Payment Pending" && (
            <StatusTag
              color={"red"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Completed" && (
            <StatusTag
              color={"green"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Open" && (
            <StatusTag
              color={"yellow"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "New Request" && (
            <StatusTag
              color={"lightBlue"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Estimating" && (
            <StatusTag
              color={"lightBlue"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}
          {data.item?.formatted?.ServiceRequestStatus ===
            "Verification Pending" && (
            <StatusTag
              color={"blue"}
              statusText={data.item?.formatted?.ServiceRequestStatus}
            />
          )}

          {data.item?.formatted?.ServiceRequestStatus === "In Progress" && (
            <Button className="assignContractorButton">View Details</Button>
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Payment Pending" && (
            <Button className="assignContractorButton">
              Pay $ {data.item.formatted.ActualCostRaw}
            </Button>
          )}

          {data.item?.formatted?.ServiceRequestStatus === "New Request" && (
            <Button className="assignContractorButton">
              Assign Contractor
            </Button>
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Completed" && (
            <Button className="assignContractorButton">View Details</Button>
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Open" && (
            <Button className="assignContractorButton">View Details</Button>
          )}

          {data.item?.formatted?.ServiceRequestStatus === "Estimating" && (
            <Button className="assignContractorButton">View Details</Button>
          )}
          {data.item?.formatted?.ServiceRequestStatus ===
            "Verification Pending" && (
            <Button className="assignContractorButton">View Details</Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CardView;
