import React from 'react';
import { Form } from 'react-bootstrap';

const RadioButton = ({ label, value, checked, onChange,name }) => {
  
    const handleClick = () => {
      onChange({ target: { value } }); // Simulate onChange event with the clicked value
    };
  return (
    <div onClick={handleClick}>
    <Form.Check
    required
      type="radio"
      label={label}
      value={value}
      name={name}
      checked={checked}
      onChange={onChange}
      className="FW400_LH20_small_text "
    />
    </div>
  );
};

export default RadioButton;
