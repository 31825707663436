import {
  PaymentMethodEntityTypesEnum,
  PaymentMethodTypesEnum,
  PaymentService,
} from "@propertelligent/client-api";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { UserLoginData } from "../common/charts/Enums";
import { GetImages } from "../../utils/GetImages";

const AddEditPaymentModal = ({
  setShowSuccessAlert,
  setShowModal,
  setAlertMessage,
  mode,
  id,
}) => {
  const [accountingNumber, setAccountingNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountingName, setAccountingName] = useState("");
  const [selectedManualMethod, setSelectedManualMethod] = useState("ACH");
  const [values, setValues] = useState({
    cardHolderName: "",
    cardNumber: "",
    cvvNumber: "",
    billingZipCode: "",
    expMonth: "",
  });
  const [errors, setErrors] = useState({
    cardHolderName: false,
    cardNumber: false,
    cvvNumber: false,
    billingZipCode: false,
    expMonth: false,
    routingNumber: false,
    accountingNumber: false,
    accountingName: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    cardHolderName: "This field is required",
    cardNumber: "This field is required",
    cvvNumber: "This field is required",
    billingZipCode: "This field is required",
    expMonth: "This field is required",
    routingNumber: "This field is required",
    accountingNumber: "This field is required",
    accountingName: "This field is required",
  });

  const handleCreditCardChange = (e) => {
    setErrors({ ...errors, cardNumber: false });
    setErrorMessage({ ...errorMessage, cardNumber: "This field is required" });
    const inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedNumber = inputNumber.slice(0, 16); // Take the first 16 digits

    // Add spaces after every 4 digits
    const formattedWithSpaces = formattedNumber.replace(/(\d{4})/g, "$1 ");

    setValues({
      ...values,
      cardNumber: formattedWithSpaces.trim(), // Trim to remove any trailing space
    });
  };

  const creditCardValidation = () => {
    // Check the length on blur
    if (values.cardNumber.replace(/\D/g, "").length !== 16) {
      setErrorMessage({
        ...errorMessage,
        cardNumber: "Credit card should have 16 digits",
      });
      setErrors({ ...errors, cardNumber: true });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "cardHolderName":
        setErrors({ ...errors, cardHolderName: false });
        break;

      case "cardNumber":
        setErrors({ ...errors, cardNumber: false });
        break;

      case "cvvNumber":
        setErrors({ ...errors, cvvNumber: false });
        break;

      case "billingZipCode":
        setErrors({ ...errors, billingZipCode: false });
        break;
      case "expMonth":
        setErrors({ ...errors, expMonth: false });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const validateAccountingNumber = () => {
    setErrorMessage({
      ...errorMessage,
      accountingNumber: "This field is required",
    });
    setErrors({ ...errors, accountingNumber: false });

    const trimmedAccountingNumber = accountingNumber.trim();
    if (trimmedAccountingNumber === "") {
      return true;
    }
    const isValid = /^[0-9]{7,15}$/.test(trimmedAccountingNumber);
    if (!isValid) {
      setErrorMessage({
        ...errorMessage,
        accountingNumber: "Please enter correct Accounting number",
      });
      setErrors({ ...errors, accountingNumber: true });
    }
  };

  const handleExpiryDateChange = (event) => {
    setErrors({ ...errors, expMonth: false });
    setErrorMessage({ ...errorMessage, expMonth: "This field is required" });

    let inputExpiryDate = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const month = inputExpiryDate.slice(0, 2);
    const year = inputExpiryDate.slice(2, 6);

    // Format the input as MM/YY
    if (inputExpiryDate.length > 2) {
      inputExpiryDate = `${month}/${year}`;
    }

    setValues({ ...values, expMonth: inputExpiryDate });
  };

  const validateRoutingNumber = () => {
    setErrorMessage({
      ...errorMessage,
      routingNumber: "This field is required",
    });
    setErrors({ ...errors, routingNumber: false });

    const trimmedroutingNumber = routingNumber.trim();
    if (trimmedroutingNumber === "") {
      return true;
    }
    const isValid = /^\d{9}$/.test(trimmedroutingNumber);
    if (!isValid) {
      setErrorMessage({
        ...errorMessage,
        routingNumber: "Please enter a 9-digit accounting number",
      });
      setErrors({ ...errors, routingNumber: true });
    }
  };

  const submitTimeError = () => {
    if (selectedManualMethod === "ACH") {
      let updatedError = { ...errors };
      if (accountingNumber.trim() === "") {
        updatedError = { ...updatedError, accountingNumber: true };
      }
      if (routingNumber.trim() === "") {
        updatedError = { ...updatedError, routingNumber: true };
      }

      setErrors(updatedError);
      return accountingNumber.trim() === "" || routingNumber.trim() === "";
    } else if (selectedManualMethod === "creditCard") {
      let updatedError = { ...errors };

      if (values.billingZipCode.trim() === "") {
        updatedError = { ...updatedError, billingZipCode: true };
      }
      if (values.cardHolderName.trim() === "") {
        updatedError = { ...updatedError, cardHolderName: true };
      }
      if (values.cardNumber.trim() === "") {
        updatedError = { ...updatedError, cardNumber: true };
      }
      if (values.cvvNumber.trim() === "") {
        updatedError = { ...updatedError, cvvNumber: true };
      }
      if (values.expMonth.trim() === "") {
        updatedError = { ...updatedError, expMonth: true };
      }

      setErrors(updatedError);
      return (
        values.billingZipCode.trim() === "" ||
        values.cardHolderName.trim() === "" ||
        values.cardNumber.trim() === "" ||
        values.cvvNumber.trim() === "" ||
        values.expMonth.trim() === ""
      );
    } else {
      return false;
    }
  };

  const addPaymentHandler = (e) => {
    e.preventDefault();
    if (submitTimeError()) {
    } else {
      if (mode === "add") {
        if (selectedManualMethod === "ACH") {
          const payload = {
            achPaymentMethod: {
              accountNumber: accountingNumber,
              routingNumber: routingNumber,
            },
            accountName: accountingName,
            paymentMethodTypeId: PaymentMethodTypesEnum.ACH,
            entityTypeId: PaymentMethodEntityTypesEnum.Organization,
            entityId: Number(
              localStorage.getItem(UserLoginData.organization_id)
            ),
          };

          PaymentService.postApiPaymentMethod(payload)
            .then((res) => {
              setAlertMessage("Payment method added successfully");
              setShowModal(false);
              setShowSuccessAlert(true);
              setAccountingNumber("");
              setRoutingNumber("");
              setAccountingName("");
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);
            })
            .catch((error) => {});
        } else if (selectedManualMethod === "creditCard") {
          const creditCardPayload = {
            cardHolderName: values.cardHolderName,
            cardNumber: values.cardNumber.split(" ").join(""),
            cvvNumber: values.cvvNumber,
            billingZipCode: values.billingZipCode,
            expMonth: Number(values.expMonth.split("/")[0]),
            expYear: Number(values.expMonth.split("/")[1]),
          };

          const payload = {
            accountName: values.cardHolderName,
            paymentMethodTypeId: PaymentMethodTypesEnum.CreditCard,
            entityTypeId: PaymentMethodEntityTypesEnum.Organization,
            entityId: Number(
              localStorage.getItem(UserLoginData.organization_id)
            ),
            creditCardPaymentMethod: creditCardPayload,
          };

          PaymentService.postApiPaymentMethod(payload)
            .then((res) => {
              setAlertMessage("Payment method added successfully");
              setShowSuccessAlert(true);
              setValues({
                cardHolderName: "",
                cardNumber: "",
                cvvNumber: "",
                billingZipCode: "",
                expMonth: "",
              });
              setShowSuccessAlert(true);
              setShowModal(false);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);
            })
            .catch((error) => {});
        }
      } else if (mode === "edit") {
        if (selectedManualMethod === "ACH") {
          const payload = {
            id: id,
            achPaymentMethod: {
              accountNumber: accountingNumber,
              routingNumber: routingNumber,
            },
            accountName: accountingName,
            paymentMethodTypeId: PaymentMethodTypesEnum.ACH,
            entityTypeId: PaymentMethodEntityTypesEnum.Organization,
            entityId: Number(
              localStorage.getItem(UserLoginData.organization_id)
            ),
          };

          PaymentService.putApiPaymentMethod(payload)
            .then((res) => {
              setAlertMessage("Payment method edited successfully");
              setShowModal(false);
              setShowSuccessAlert(true);
              setAccountingNumber("");
              setRoutingNumber("");
              setAccountingName("");
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);
            })
            .catch((error) => {});
        } else if (selectedManualMethod === "creditCard") {
          const creditCardPayload = {
            cardHolderName: values.cardHolderName,
            cardNumber: values.cardNumber.split(" ").join(""),
            cvvNumber: values.cvvNumber,
            billingZipCode: values.billingZipCode,
            expMonth: Number(values.expMonth.split("/")[0]),
            expYear: Number(values.expMonth.split("/")[1]),
          };

          const payload = {
            id: id,
            accountName: values.cardHolderName,
            paymentMethodTypeId: PaymentMethodTypesEnum.CreditCard,
            entityTypeId: PaymentMethodEntityTypesEnum.Organization,
            entityId: Number(
              localStorage.getItem(UserLoginData.organization_id)
            ),
            creditCardPaymentMethod: creditCardPayload,
          };

          PaymentService.putApiPaymentMethod(payload)
            .then((res) => {
              setAlertMessage("Payment method edited successfully");
              setShowSuccessAlert(true);
              setValues({
                cardHolderName: "",
                cardNumber: "",
                cvvNumber: "",
                billingZipCode: "",
                expMonth: "",
              });
              setShowSuccessAlert(true);
              setShowModal(false);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);
            })
            .catch((error) => {});
        }
      }
    }
  };

  useEffect(() => {
    if (mode === "edit" && id) {
      getDataForEdit(id);
    }
  }, []);

  const getDataForEdit = async (id) => {
    const data = await PaymentService.getApiPaymentMethod(id);
    if (data?.success?.formatted?.PaymentMethodTypeId === "ACH") {
      setSelectedManualMethod("ACH");
      setAccountingNumber(data?.success?.achPaymentMethod?.accountNumber);
      setRoutingNumber(data?.success?.achPaymentMethod?.routingNumber);
      setAccountingName(data?.success?.accountName);
    } else if (
      data?.success?.formatted?.PaymentMethodTypeId === "Credit Card"
    ) {
      setSelectedManualMethod("creditCard");
      setValues({
        cardHolderName: data?.success?.creditCardPaymentMethod?.cardHolderName,
        cardNumber: data?.success?.creditCardPaymentMethod?.cardNumber,
        cvvNumber: data?.success?.creditCardPaymentMethod?.cvvNumber,
        billingZipCode: data?.success?.creditCardPaymentMethod?.billingZipCode,
        expMonth:
          String(data?.success?.creditCardPaymentMethod?.expMonth) +
          "/" +
          String(data?.success?.creditCardPaymentMethod?.expYear),
      });
    }
  };

  const validateExpiryDate = () => {
    const currentDate = new Date();
    const inputDate = new Date();
    const [month, year] = values.expMonth.split("/");

    if (month && year) {
      inputDate.setFullYear(
        parseInt("20" + year, 10),
        parseInt(month, 10) - 1,
        1
      );

      if (inputDate < currentDate) {
        setErrors({ ...errors, expMonth: true });
        setErrorMessage({
          ...errorMessage,
          expMonth: "Card Expired ",
        });
      } else {
        setErrors({ ...errors, expMonth: false });
        setErrorMessage({ ...errorMessage, expMonth: "Invalid expiry date" });
      }
    } else {
      setErrors({ ...errors, expMonth: true });
      setErrorMessage({ ...errorMessage, expMonth: "Invalid expiry date" });
    }
  };

  const handleCvvChange = (e) => {
    setErrors({ ...errors, cvvNumber: false });
    setErrorMessage({
      ...errorMessage,
      cvvNumber: "This field is required",
    });
    const inputNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = inputNumber.slice(0, 3);

    setValues({
      ...values,
      cvvNumber: formattedNumber.trim(),
    });
  };

  const handleCvv = () => {
    if (values.cvvNumber.length !== 3) {
      setErrors({ ...errors, cvvNumber: true });
      setErrorMessage({ ...errorMessage, cvvNumber: "CVV must be 3 digit" });
    }
  };

  const handleBillingZipCodeChange = (e) => {
    setErrors({ ...errors, billingZipCode: false });
    setErrorMessage({
      ...errorMessage,
      billingZipCode: "This field is required",
    });
    const inputNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = inputNumber.slice(0, 5);

    setValues({
      ...values,
      billingZipCode: formattedNumber.trim(),
    });
  };

  const validateBillingZipCode = () => {
    if (values.billingZipCode.length !== 5) {
      setErrors({ ...errors, billingZipCode: true });
      setErrorMessage({
        ...errorMessage,
        billingZipCode: "Zip code must be 5 digits",
      });
    }
  };

  return (
    <div>
      <div className="addEditPaymentContainer">
        {mode !== "edit" && (
          <div className="radioButtonContainer1 ">
            <p className="helpAndSupportDescription mb-2">Payment method</p>
            <div style={{ gap: 20 }} className="d-flex align-items-center">
              {selectedManualMethod === "ACH" && mode === "edit" && (
                <div
                  className={
                    selectedManualMethod === "ACH"
                      ? "selectedPaymentOption1 clickable"
                      : "nonSelectedPaymentOption1 clickable"
                  }
                  onClick={() => setSelectedManualMethod("ACH")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "ACH"
                        ? "radioButtonSelected"
                        : "radioButtonUnSelected"
                    }
                    name="group2"
                    type="radio"
                    label="ACH"
                    checked={selectedManualMethod === "ACH"}
                    onChange={() => setSelectedManualMethod("ACH")}
                  />
                </div>
              )}
              {mode === "add" && (
                <div
                  className={
                    selectedManualMethod === "ACH"
                      ? "selectedPaymentOption1 clickable"
                      : "nonSelectedPaymentOption1 clickable"
                  }
                  onClick={() => setSelectedManualMethod("ACH")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "ACH"
                        ? "radioButtonSelected"
                        : "radioButtonUnSelected"
                    }
                    name="group2"
                    type="radio"
                    label="ACH"
                    checked={selectedManualMethod === "ACH"}
                    onChange={() => setSelectedManualMethod("ACH")}
                  />
                </div>
              )}

              {selectedManualMethod === "creditCard" && mode === "edit" && (
                <div
                  className={
                    selectedManualMethod === "creditCard"
                      ? "selectedPaymentOption1"
                      : "nonSelectedPaymentOption1"
                  }
                  onClick={() => setSelectedManualMethod("creditCard")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "creditCard"
                        ? "radioButtonSelected clickable"
                        : "radioButtonUnSelected clickable"
                    }
                    name="group2"
                    type="radio"
                    label="Credit Card"
                    checked={selectedManualMethod === "creditCard"}
                    onChange={() => setSelectedManualMethod("creditCard")}
                  />
                </div>
              )}
              {mode === "add" && (
                <div
                  className={
                    selectedManualMethod === "creditCard"
                      ? "selectedPaymentOption1"
                      : "nonSelectedPaymentOption1"
                  }
                  onClick={() => setSelectedManualMethod("creditCard")}
                >
                  <Form.Check
                    className={
                      selectedManualMethod === "creditCard"
                        ? "radioButtonSelected clickable"
                        : "radioButtonUnSelected clickable"
                    }
                    name="group2"
                    type="radio"
                    label="Credit Card"
                    checked={selectedManualMethod === "creditCard"}
                    onChange={() => setSelectedManualMethod("creditCard")}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {selectedManualMethod === "ACH" && (
          <div>
            <div style={{ gap: 23 }} className="d-flex  flex-wrap">
              <div className="labelInputContainer">
                <p className="monthLabels">Name of Account</p>
                <div>
                  <input
                    className="manualAccountInput3"
                    placeholder="Account name"
                    value={accountingName}
                    type="text"
                    onChange={(e) => {
                      setAccountingName(e.target.value);
                      setErrors({ ...errors, accountingName: false });
                    }}
                  />
                  {errors.accountingNumber && (
                    <p className="errorMessage">
                      {errorMessage.accountingName}
                    </p>
                  )}
                </div>
              </div>
              <div className="labelInputContainer">
                <p className="monthLabels">Account number</p>
                <div>
                  <input
                    className="manualAccountInput3"
                    placeholder="Account number"
                    value={accountingNumber}
                    type="number"
                    pattern="[0-9]*"
                    onBlur={validateAccountingNumber}
                    onChange={(e) => {
                      setAccountingNumber(e.target.value);
                      setErrors({ ...errors, accountingNumber: false });
                    }}
                  />
                  {errors.accountingNumber && (
                    <p className="errorMessage">
                      {errorMessage.accountingNumber}
                    </p>
                  )}
                </div>
              </div>
              <div className="labelInputContainer">
                <p className="monthLabels">Routing number</p>
                <div>
                  <input
                    className="manualAccountInput3"
                    placeholder="Routing number"
                    value={routingNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setRoutingNumber(value);
                      setErrors({ ...errors, routingNumber: false });
                    }}
                    onBlur={validateRoutingNumber}
                    maxLength={9}
                  />
                  {errors.routingNumber && (
                    <p className="errorMessage">{errorMessage.routingNumber}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedManualMethod === "creditCard" && (
          <div>
            <div className="d-flex gap-3 flex-wrap">
              <div className="labelInputContainer">
                <p className="monthLabels">Name of account</p>
                <div>
                  <input
                    className="manualAccountInput3"
                    placeholder="Account name"
                    name="cardHolderName"
                    value={values.cardHolderName}
                    onChange={handleChange}
                  />
                  {errors.cardHolderName && (
                    <p className="errorMessage">This field is required</p>
                  )}
                </div>
              </div>
              <div className="labelInputContainer">
                <p className="monthLabels">Credit card number</p>
                <div>
                  <input
                    className="manualAccountInput3"
                    placeholder="Credit card number"
                    name="cardNumber"
                    value={values.cardNumber}
                    onChange={handleCreditCardChange}
                    onBlur={creditCardValidation}
                  />
                  {errors.cardNumber && (
                    <p className="errorMessage">{errorMessage.cardNumber}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3 flex-wrap">
              <div className="labelInputContainer">
                <p className="monthLabels">Billing Zip code</p>
                <div>
                  <input
                    className="manualAccountInput1"
                    placeholder="Zip code"
                    value={values.billingZipCode}
                    name="billingZipCode"
                    onChange={handleBillingZipCodeChange}
                    maxLength={5}
                    onBlur={validateBillingZipCode}
                  />

                  {errors.billingZipCode && (
                    <p className="errorMessage">
                      {errorMessage.billingZipCode}
                    </p>
                  )}
                </div>
              </div>
              <div className="labelInputContainer">
                <p className="monthLabels">Expires</p>
                <div>
                  <input
                    className="manualAccountInput2"
                    placeholder="12/24"
                    value={values.expMonth}
                    name="expMonth"
                    onChange={handleExpiryDateChange}
                    maxLength={5}
                    onBlur={validateExpiryDate}
                  />

                  {errors.expMonth && (
                    <p className="errorMessage">{errorMessage.expMonth}</p>
                  )}
                </div>
              </div>

              <div className="labelInputContainer">
                <p className="monthLabels">CVV</p>
                <div>
                  <input
                    className="manualAccountInput2"
                    placeholder="123"
                    value={values.cvvNumber}
                    name="cvvNumber"
                    onChange={handleCvvChange}
                    maxLength={3}
                    onBlur={handleCvv}
                  />

                  {errors.cvvNumber && (
                    <p className="errorMessage">{errorMessage.cvvNumber}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="editFormFooter">
        <p onClick={() => setShowModal(false)} className="cancelEdit clickable">
          Cancel
        </p>
        <button
          onClick={addPaymentHandler}
          type="submit"
          className="saveChangesButton1"
        >
          {mode === "add" ? "Add Payment Account" : "Edit Payment Account"}
          <img src={GetImages.WhiteForwardArrow} />
        </button>
      </div>
    </div>
  );
};

export default AddEditPaymentModal;
