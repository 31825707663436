import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  TenantService,
  UnitService,
} from "@propertelligent/client-api";
import {
  ADD_UNIT,
  FAIL_GET_UNIT,
  GET_ALL_UNIT,
  GET_ERRORS,
  GET_UNIT,
  REQUEST_GET_UNIT,
  SUCCESS_GET_UNIT,
} from "./types";

export const addUnitTenant = (unittenantdata) => async (dispatch) => {
  try {
    const response = await UnitService.postApiUnit(unittenantdata.unitval);

    if (response?.success) {
      if (unittenantdata.mainImage) {
        const imgData = {
          EntityTypeId: DocumentEntityTypesEnum.Unit,
          EntityId: Number(response?.success?.id),
          DocumentTypeId: DocumentTypesEnum.BeforeImage,
          IsMain: false,
          Document: unittenantdata.mainImage,
          FileName: unittenantdata.fileName,
        };

        try {
          const docresponse = await DocumentService.postApiDocument(imgData);

          if (
            unittenantdata.tanentValue?.firstName === "" &&
            unittenantdata.tanentValue?.lastName === "" &&
            unittenantdata.tanentValue?.email === "" &&
            unittenantdata.tanentValue?.phoneNumber === ""
          )
            await TenantService.postApiTenant(unittenantdata.tanentValue).then(
              (res) => {
                if (res.success) {
                  unittenantdata.navigate("/tenants");
                }
              }
            );
          dispatch({ type: ADD_UNIT, payload: response.success });
        } catch (error) { }
      }
    }
  } catch (error) { }
};

export const getTenantList = (id) => async (dispatch) => {
  try {
    UnitService.getApiUnitPropertylist(parseInt(id)).then((response) => {
      dispatch({ type: GET_ALL_UNIT, payload: response.success });
    });
  } catch (error) {
    dispatch({ type: GET_ERRORS, payload: response.errors });
  }
};

export const getSingleUnit = (id) => async (dispatch) => {
 

  try {
    dispatch({ type: REQUEST_GET_UNIT });

    const res = await UnitService.getApiUnit1(parseInt(id));

    if (res.success) {
      dispatch({ type: SUCCESS_GET_UNIT, payload: res.success });
    } else {
      throw new Error("Property request was not successful.");
    }
  } catch (error) {
    dispatch({
      type: FAIL_GET_UNIT,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
