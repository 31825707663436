
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { CommunicationService } from "@propertelligent/client-api";
import { currentUrl } from "../../../../utils/helper";

const GmailAuthReturn = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) { CommunicationService.postApiGmailSaveRequestToken({
        authorizationToken: code,
        redirectUri: `${currentUrl}/callback/gmail`
      }).then((res) => {
          if (res.success === true) {
            navigate("/messaging/email");
          } else {
            navigate("/messaging/email");
          }
        })
        .catch((error) => {
          console.error("Error making API call:", error);
          navigate("/messaging/email");
        });
    } else {
      console.error("Code parameter not found in URL");
      navigate("/messaging/email");
    }
  }, [location.search, navigate]);

  return <h1>Loading...</h1>;
};

export default GmailAuthReturn;

