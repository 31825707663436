import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import {
  OrganizationService,
  OrganizationUserRolesEnum,
  OrganizationUserService,
} from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";
import { Button, Form } from "react-bootstrap";
import EditCustomComponent from "../tenants/EditCustomComponent";
import SuccessAlert from "../tenants/SuccessAlert";
import { UserLoginData } from "../common/charts/Enums";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { URLS } from "../../constants/constants";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [userTypeError, setUserTypeError] = useState(false);
  const [organizationUserTypeId, setOrganizationUserTypeId] = useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState("User edited successfully");
  const [userId, setUserId] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
  });

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }

    if (!organizationUserTypeId) {
      setUserTypeError(true);
    }
  };

  const submitTimeError = () => {
    return (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneNumberError ||
      userTypeError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      !organizationUserTypeId
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 20
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name should have 2-20 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 20
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name should have 2-20 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase(); // Convert email to lowercase
      setValues({ ...values, email: lowercaseEmail }); // Update the email in state with lowercase

      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };
  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     if (emailRegex.test(values.email)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };
  const formDetails = [
    {
      label: "First name",
      placeholder: "Enter first name",
      value: values.firstName,
      name: "firstName",
      blurFunction: validateFirstName,
      errorMessages: errorMessages.firstName,
      isError: firstNameError,
    },
    {
      label: "Last name",
      placeholder: "Enter last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: validateLastName,
      errorMessages: errorMessages.lastName,
      isError: lastNameError,
    },
    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: validateNumber,
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
    },
  ];

  const editUserHandler = (e) => {
    e.preventDefault();
    const payload = {
      ...values,
      organizationUserTypeId: organizationUserTypeId,
      organizationUserId: Number(id),
    };

    OrganizationUserService.putApiOrganizationUser(id, payload)
      .then((res) => {
        setMessage("User edited successfully");
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
        getUserData();
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    OrganizationUserService.getApiOrganizationUser(Number(id))
      .then((res) => {
        if (res.success) {
          setValues({
            firstName: res?.success?.firstName,
            lastName: res?.success?.lastName,
            email: res?.success?.email,
            phoneNumber: res?.success?.phoneNumber,
          });
          setOrganizationUserTypeId(res?.success?.organizationUserTypeId);
          setUserId(res?.success?.id);
        }
      })
      .catch((err) => {});
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Form onSubmit={editUserHandler}>
        <div className="singleTenantTopbar">
          <div
            onClick={() => { navigate(URLS.HOME) }}
            className="clickable"
          >
            <p className="singleTenantTopbarHeading">Dashboard</p>
          </div>
          <img src={GetImages.BlackChevronRight} />
          <p
            onClick={() => {
              navigate(URLS.ORGANIZATION, { state: true });
            }}
            className="singleTenantTopbarHeading clickable"
          >
            My Organization
          </p>
          <img src={GetImages.BlackChevronRight} />
          <p className="singleTenantTopbarSubHeading">User Details</p>
        </div>
        <div className="addContractorFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              onClick={() => {
                navigate(URLS.ORGANIZATION, { state: true });
              }}
              height={28}
              width={28}
              src={GetImages.leftChevronBlack}
              className="clickable"
            />
            <p className="newTenantFormHeading"> Edit user</p>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText">Select user role </p>
          </div>
          <div style={{ marginBottom: 40 }}>
            <div className="d-flex radioButtonContainer mb-0 ">
              <Form.Check
                className="clickable"
                name="group1"
                type="radio"
                checked={organizationUserTypeId === 1}
                onChange={() => {
                  setOrganizationUserTypeId(1);
                  setUserTypeError(false);
                }}
                id="organizationAdmin"
              />
              <label className="clickable" htmlFor="organizationAdmin">
                Organization admin{" "}
              </label>

              <Form.Check
                className="clickable"
                name="group1"
                type="radio"
                checked={organizationUserTypeId === 2}
                onChange={() => {
                  setOrganizationUserTypeId(2);
                  setUserTypeError(false);
                }}
                id="propertyManager"
              />

              <label className="clickable" htmlFor="propertyManager">
                Property manager
              </label>
            </div>
            <div>
              {userTypeError && (
                <p className="errorMessage"> Please select user role </p>
              )}
            </div>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText">
              Enter basic details of users{" "}
            </p>
          </div>

          <div className="d-flex addContractorInputContainer">
            {formDetails.map((item) => {
              return (
                <EditCustomComponent
                  key={item.label}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  name={item.name}
                  onChangeFunction={handleChange}
                  onBlurFunction={item.blurFunction}
                  errorMessages={item.errorMessages}
                  isError={item.isError}
                  width="290px"
                />
              );
            })}
          </div>
        </div>

        <div className="editUserBottomBar d-flex align-items-center justify-content-start gap-3">
          <Button type="submit" className="saveChangesButtonStyles">
            <p>Save Changes </p> <img src={GetImages.blueForwardArrow} />
          </Button>
        </div>
      </Form>

      {showSuccessAlert && (
        <SuccessAlert
          message={message}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </div>
  );
};

export default EditUser;
