import { Route, Routes } from "react-router";
import { TenantEdit } from "./edit";
import CreateForm from "./CreateForm";
import NewSingleTenantView from "./NewSingleTenantView";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { useEffect, useState } from "react";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { TenantService } from "@propertelligent/client-api";
import { debouncedFunction } from "../../utils/helper";

export const Tenants = () => {

  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { rows, loading, hasMore, rowCount, fetchData, currentSortDirection, handleSort, currentPage} = infiniteScrollHook({
    httpFetchDataFn: TenantService.getApiTenantOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts : fulllyQualifiedSort
  });

  useEffect(() => {
    if(searchValue !== ""){
      SetFullyQualifiedFilter(`User.FirstName.ToLower().Contains("${searchValue.toLowerCase()}")
      or User.LastName.ToLower().Contains("${searchValue.toLowerCase()}")`);
    } else {
      SetFullyQualifiedFilter(null);
    }
  }, [searchValue]);

  useEffect(() => {
    SetFulllyQualifiedSort('User.LastName ' + currentSortDirection);
  }, [currentSortDirection]);

  return (
    <Routes>
      <Route index element={        
        <ContentTopBottom
            FixedTopContent={() => <TopFixedView rowCount={rowCount} currentSortDirection={currentSortDirection} handleSort={handleSort} setSearchValue={setSearchValue} searchValue={searchValue} />}
            FixedBottomContent={() => <MainScrollView rows={rows} loading={loading} hasMore={hasMore} fetchData={fetchData} currentPage={currentPage}/>} />
      } />
      <Route path="/create" element={<CreateForm />} />
      <Route path="/tenant/:id" element={<NewSingleTenantView />} />
      <Route path="/edit/:id" element={<TenantEdit />} />
    </Routes>
  );
};
