import React, { useState } from "react";
import info from "../assets/info.png";
import dummyPic from "../assets/dummyPropertyPic.png";
const CapRateComponent = ({ type, address, percent, img }) => {
  const [showInfoBox, setShowInfoBox] = useState(false);

  const getImageSource = () => {
    return img ? `data:image/jpeg;base64,${img}` : dummyPic;
  };

  return (
    <div className="d-flex align-items-center justify-content-between w-100 capRateComponent pt-3 pb-3">
      <div className="d-flex align-items-center gap-2">
        <img
          className="rounded"
          width={44}
          height={44}
          src={getImageSource()}
          // src={img ? img : dummyPic}
        />
        <div className="typeAddressContainer">
          <p className="openActionDate1">{type}</p>
          <p className="openActionTitle1">{address}</p>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1 rentOverviewHeading position-relative">
        <p>{percent}</p>
        <div
          style={{ width: 25, height: 25 }}
          onMouseOver={() => setShowInfoBox(true)}
          onMouseLeave={() => setShowInfoBox(false)}
        >
          <img className="clickable" width={16} height={16} src={info} />
        </div>
        {showInfoBox && (
          <div className="toolTipContainer1">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="openActionDate1">Property Value</p>
              <p className="openActionTitle1">$20,000</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="openActionDate1">NOI</p>
              <p className="openActionTitle1">$5,000</p>
            </div>
            <div className="d-flex align-items-center justify-content-between p-2 vacancyRateContainer">
              <p className="openActionDate1 mb-0">Cap Rate</p>
              <p className="toolTipHeading mb-0 ">9.8%</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CapRateComponent;
