import React, { useEffect, useState, useCallback } from "react";
import {
  PropertyService,
  UnitService,
  DocumentService,
  DocumentEntityTypesEnum,
  DocumentTypesEnum,
  UnitVM,
} from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import UnitForm from "./UnitForm/index";
import SuccessAlert from "../tenants/SuccessAlert";
import ErrorAlert from "../tenants/ErrorAlert";

interface Props {
  setShow?: any;
  unitData?: any;
  generatedMainImage?: any;
  fetchUnitDetails?: any;
  showSuccessAlert?: any;
  setShowSuccessAlert?: any;
  setMainImageId?: (newNumber: number) => void;
}

export const UnitCreate: React.FC<Props> = ({
  setShow,
  unitData,
  showSuccessAlert,
  setShowSuccessAlert,
  generatedMainImage,
  fetchUnitDetails,
  setMainImageId,
}) => {
  const organization_id = parseInt(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  // const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [mainImage, setMainImage] = useState<any>(generatedMainImage);
  const [uploadImage, setUploadImage] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState("");
  const [questionaireError, setQuestionaireError] = useState(false);
  const [unitVal, setUnitVal] = useState<UnitVM | any>(
    unitData || {
      propertyId: 0,
      mainImageId: 0,
    }
  );
  const [updatedMainImageIdObj, setUpdatedMainImageIdObj] = useState({
    mainImageId: 0,
  });

  useEffect(() => {
    PropertyService.getApiPropertyOrganizationlookup(organization_id)
      .then((response: any) => {
        setProperties(response?.success);
      })
      .catch((ptyListErr: any) => {});
  }, []);

  const handleChangeMainImage = (selectedFile: File | null) => {
    setFileName(selectedFile?.name);
    const blob = new Blob([selectedFile], { type: "image/png|jpg" });
    setMainImage(blob);
    setUploadImage(blob);
    if (unitData?.id && unitData?.id > 0) {
      const imgData = {
        EntityTypeId: DocumentEntityTypesEnum.Unit,
        EntityId: Number(unitData?.id),
        DocumentTypeId: DocumentTypesEnum.BeforeImage,
        IsMain: true,
        Document: blob,
        FileName: selectedFile?.name,
      };

      DocumentService.postApiDocument(imgData)
        .then((imageResponse: any) => {
          const currentMainImageId = imageResponse?.success?.id;

          setUpdatedMainImageIdObj({ mainImageId: currentMainImageId });
          DocumentService.getApiDocumentBase641(currentMainImageId)
            .then((imageResponse: any) => {
              const imageData = "data:image/*;base64," + imageResponse?.success;
              setMainImage(imageData);
              if (setMainImageId) {
                setTimeout(() => {
                  setMainImageId(currentMainImageId);
                }, 1000);
              }

              setShowSuccessAlert(true);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);
            })
            .catch((imageErr: any) => {});
        })
        .catch((imageErr: any) => {});
    }
  };

  const handleDelete = () => {
    setMainImage(null);
    setFileName("");
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedProperty(event.target.value);
      setUnitVal((prevUnitVal) => ({
        ...prevUnitVal,
        propertyId: Number(event.target.value), // Ensure propertyId is a number
      }));
    },
    []
  );

  const generateAndPostImgObj = async (currentUnitId: number) => {
    const imgData = {
      EntityTypeId: DocumentEntityTypesEnum.Unit,
      EntityId: Number(currentUnitId),
      DocumentTypeId: DocumentTypesEnum.BeforeImage,
      IsMain: true,
      Document: uploadImage,
      FileName: fileName,
    };

    DocumentService.postApiDocument(imgData)
      .then((imageResponse: any) => {
        setUpdatedMainImageIdObj({ mainImageId: imageResponse?.success?.id });
        DocumentService.getApiDocumentBase641(imageResponse?.success?.id)
          .then((imageResponse: any) => {
            const imageData = "data:image/*;base64," + imageResponse?.success;
            setMainImage(imageData);
            if (setMainImageId) {
              setMainImageId(imageResponse?.success?.id);
            }
            setShowSuccessAlert(true);
            setTimeout(() => {
              setShowSuccessAlert(false);
            }, 3000);
          })
          .catch((imageErr: any) => {});
      })
      .catch((imageErr: any) => {});
  };

  const handleSubmit = async (e: any) => {
    // we can handle for both Create & Update purpose:
    try {
      e.preventDefault();
      if (unitData) {
        //Means EditUnit called
        if (updatedMainImageIdObj?.mainImageId != 0) {
          Object.assign(unitVal, {
            mainImageId: updatedMainImageIdObj?.mainImageId,
          });
        }
        UnitService.putApiUnit(unitVal)
          .then((res: any) => {
            if (res.success) {
              setShowSuccessAlert(true);
              setShow(false);

              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 3000);

              // if (fetchUnitDetails) {

              fetchUnitDetails();
              // setShow(false);
              // }
            }
          })
          .catch((putErr: any) => {
            setQuestionaireError(true);
          });
      } else {
        //Means NewUnit called
        const data = await UnitService.postApiUnit(unitVal);
        if (data?.success) {
          if (mainImage) {
            await generateAndPostImgObj(data?.success?.id);
            setShowSuccessAlert(true);
            setTimeout(() => {
              setShow(false);
            }, 500);
            setTimeout(() => {
              setShowSuccessAlert(false);
            }, 3000);
          } else {
            setShowSuccessAlert(true);
            setTimeout(() => {
              setShow(false);
            }, 500);
            setTimeout(() => {
              setShowSuccessAlert(false);
            }, 3000);
          }
          setUnitVal({});
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="add-unit-canvas">
        <UnitForm
          title="Add Unit"
          properties={properties}
          handleChange={handleChange}
          mainImage={mainImage}
          setFileName={setFileName}
          unitVal={unitVal}
          setUnitVal={setUnitVal}
          handleDelete={handleDelete}
          selectedProperty={selectedProperty}
          handleChangeImage={handleChangeMainImage}
          setShow={setShow}
          showSuccessAlert={showSuccessAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          isEditForm={!!unitData}
        />
      </form>
      {showSuccessAlert && (
        <SuccessAlert
          message={"Unit added successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
      {questionaireError && (
        <ErrorAlert
          setShowSuccessAlert={questionaireError}
          message={"Some Error Occured"}
        />
      )}
    </>
  );
};
