import { useState } from "react";
import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";
import { Offcanvas } from "react-bootstrap";
import { UnitCreate } from "../create";
import SuccessAlert from "../../tenants/SuccessAlert";

const TopFixedView = ({
  rowCount,
  searchValue,
  setSearchValue,
  handleSort,
  currentSortDirection,
}) => {
  const [show, setShow] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {show && (
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement={"end"}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            height: "100vh",
            background: "white",
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add Unit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <UnitCreate
              setShow={setShow}
              showSuccessAlert={showSuccessAlert}
              setShowSuccessAlert={setShowSuccessAlert}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <TopFixedBar
        rowCount={rowCount}
        rowCountLabel="Units"
        searchPlaceholder="Search by Unit Number"
        addButtonLabel="Add Unit"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        addCallback={() => setShow(true)}
      />
      <TopFixedSubBar
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
      />
      {showSuccessAlert && (
        <SuccessAlert
          message={"Unit added successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </>
  );
};

export default TopFixedView;
