import React from "react";
import { Alert } from "react-bootstrap";
import fail from "../../assets/fail.png";
import { GetImages } from "../../utils/GetImages";
const ErrorAlert = ({ setShowSuccessAlert, message }) => {
  return (
    <div>
      <div className="failureAlert">
        <Alert variant="danger">
          <img width="24px" height="24px" src={fail} />
          <div>{message}</div>
          <img
            onClick={() => setShowSuccessAlert(false)}
            src={GetImages.closeIconBlack}
          />
        </Alert>
      </div>
    </div>
  );
};

export default ErrorAlert;
