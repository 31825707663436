import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import NewSelectAuto from "../tenants/NewSelectAuto";
import EditCustomComponent from "../tenants/EditCustomComponent";
import {
  ContractorService,
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  LookupService,
  LookupTypesEnum,
} from "@propertelligent/client-api";
import SuccessAlert from "../tenants/SuccessAlert";
import { GetImages } from "../../utils/GetImages";
import { FORM_CONTROLFIELD_TYPES, URLS } from "../../constants/constants";
import { CustomDatePicker } from "../common/formControls";

const AddContractor = () => {
  const navigate = useNavigate();
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [isServiceTypeDisabled, setIsServiceTypeDisabled] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [serviceTypeSelectError, setServiceTypeSelectError] = useState(false);
  const [subServiceTypeError, setSubServiceTypeError] = useState(false);
  const [isSubServiceTypeDisabled, setIsSubServiceTypeDisabled] =
    useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isInHouseValue, setIsInHouseValue] = useState(true);
  const [selectedSubServiceType, setSelectedSubServiceType] = useState(null);
  const [licenseExpiryDate, setLicenceExpiryDate] = useState("");
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState("");
  const [uploadLicenseFileName, setUploadLicenseFileName] = useState<any>("");
  const [uploadLicenseDocument, setUploadLicenseDocument] = useState<any>(null);
  const [uploadInsuranceFileName, setUploadInsuranceFileName] = useState("");
  const [uploadInsuranceDocument, setUploadInsuranceDocument] = useState(null);
  const [serviceTypeFields, setServiceTypeFields] = useState([]);
  const [subServiceTypeFields, setSubServiceTypeFields] = useState([]);
  const [licenseDocumentFileType, setLicenceDocumentFileType] = useState("pdf");
  const [insuranceDocumentFileType, setInsuranceDocumentFileType] =
    useState("pdf");
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
    serviceType: "Please select service type",
    subServiceType: "Please select sub service type",
  });

  useEffect(() => {
    if (selectedServiceType && ![1, 2, 3].includes(selectedServiceType)) {
      setSelectedSubServiceType(null);
      getSubServiceType();
    }
  }, [selectedServiceType]);

  const getSubServiceType = () => {
    LookupService.getApiLookup1(
      LookupTypesEnum.ServiceSubTypes,
      selectedServiceType
    )
      .then((res) => {
        setSubServiceTypeFields(res.success);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getServiceTypeFields();
  }, []);

  const getServiceTypeFields = async () => {
    LookupService.getApiLookup(LookupTypesEnum.ServiceTypes)
      .then((res) => {
        setServiceTypeFields(res.success);
      })
      .catch((error) => {});
  };

  const handleInsuranceExpiryDateChange = (e) => {
    setInsuranceExpiryDate(e.target.value);
  };

  const handleLicenceExpiryDateChange = (e) => {
    setLicenceExpiryDate(e.target.value);
  };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 20
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name should have 2-20 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 20
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name should have 2-20 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     const lowercaseEmail = values.email.trim().toLowerCase();
  //     setValues({ ...values, email: lowercaseEmail });

  //     if (emailRegex.test(lowercaseEmail)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      if (emailRegex.test(values.email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };

  const formDetails = [
    {
      label: "First name",
      placeholder: "Enter first name",
      value: values.firstName,
      name: "firstName",
      blurFunction: validateFirstName,
      errorMessages: errorMessages.firstName,
      isError: firstNameError,
    },
    {
      label: "Last name",
      placeholder: "Enter last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: validateLastName,
      errorMessages: errorMessages.lastName,
      isError: lastNameError,
    },
    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: validateNumber,
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }

    if (selectedServiceType === null) {
      setServiceTypeSelectError(true);
    }

    if (subServiceTypeFields.length > 0 && selectedSubServiceType === null) {
      setSubServiceTypeError(true);
    }
  };

  const handleUploadInsuranceDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setInsuranceDocumentFileType("image");
      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    } else {
      setInsuranceDocumentFileType("pdf");

      setUploadInsuranceFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadInsuranceDocument(blob);
    }
  };

  const handleUploadLicenseDocumentChange = (selectedFile: File | null) => {
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setLicenceDocumentFileType("image");
      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    } else {
      setLicenceDocumentFileType("pdf");

      setUploadLicenseFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadLicenseDocument(blob);
    }
  };

  const submitTimeError = () => {
    return (
      firstNameError ||
      lastNameError ||
      emailError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === "" ||
      selectedServiceType === null ||
      (subServiceTypeFields.length > 0 && selectedSubServiceType === null)
    );
  };

  const submitHandler = (e) => {
    checkEmptyFields();
    e.preventDefault();
    if (submitTimeError()) {
    } else {
      let newValues;
      if (selectedSubServiceType) {
        newValues = {
          ...values,
          email: values.email.toLowerCase(),
          ServiceTypesIds: [selectedServiceType],
          serviceSubTypesIds: [selectedSubServiceType],
          isInHouse: isInHouseValue,
        };
      } else {
        newValues = {
          ...values,
          email: values.email.toLowerCase(),
          ServiceTypesIds: [selectedServiceType],
          isInHouse: isInHouseValue,
        };
      }

      ContractorService.postApiContractor(newValues)
        .then((res) => {
          const contractorId = res.success.id;

          if (uploadLicenseDocument) {
            if (licenseExpiryDate.trim() != "") {
              var formattedlicenseExpiryDate = new Date(
                licenseExpiryDate
              ).toISOString();
            } else {
              var formattedlicenseExpiryDate = "";
            }

            const uploadLicenseDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.License,
              IsMain: true,
              FileName: uploadLicenseFileName,
              Document: uploadLicenseDocument,
              ValidUntil: formattedlicenseExpiryDate,
            };

            DocumentService.postApiDocument(uploadLicenseDocData)
              .then((res) => {
                setUploadLicenseFileName(null);
                setUploadLicenseDocument(null);
                setLicenceExpiryDate("");
              })
              .catch((error) => {
                console.log("error", error);
              });
          }

          if (uploadInsuranceDocument) {
            if (insuranceExpiryDate.trim() != "") {
              var formattedinsuranceExpiryDate = new Date(
                insuranceExpiryDate
              ).toISOString();
            } else {
              var formattedinsuranceExpiryDate = "";
            }

            const uploadInsuranceDocData = {
              EntityTypeId: DocumentEntityTypesEnum.Contractor,
              EntityId: contractorId,
              DocumentTypeId: DocumentTypesEnum.Insurance,
              IsMain: true,
              FileName: uploadInsuranceFileName,
              Document: uploadInsuranceDocument,
              ValidUntil: formattedinsuranceExpiryDate,
            };

            DocumentService.postApiDocument(uploadInsuranceDocData)
              .then((res) => {
                setUploadInsuranceDocument(null);
                setUploadInsuranceFileName(null);
                setInsuranceExpiryDate("");
              })
              .catch((error) => {});
          }

          setValues({
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          });
          setSelectedServiceType(null);
          setSelectedSubServiceType(null);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
            navigate("/contractors");
          }, 3000);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <>
      <Form id="contractorForm" onSubmit={submitHandler}>
        <div className="singleTenantTopbar">
          <div
            onClick={() => {
              navigate(URLS.CONTRACTORS);
            }}
            className="clickable"
          >
            <p className="singleTenantTopbarHeading">Contractor</p>
          </div>
          <img src={GetImages.BlackChevronRight} />
          <p className="singleTenantTopbarSubHeading">Add contractor</p>
        </div>
        <div className="addContractorFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              onClick={() => {
                navigate(URLS.CONTRACTORS);
              }}
              className="clickable"
              height={28}
              width={28}
              src={GetImages.leftChevronBlack}
            />
            <p className="newTenantFormHeading"> Add contractor</p>
          </div>

          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText">Select contractor type </p>
          </div>
          <div className="d-flex radioButtonContainer ">
            <Form.Check
              className="clickable"
              name="group1"
              type="radio"
              id="inHouseRadioButton"
              checked={isInHouseValue}
              onChange={() => setIsInHouseValue(true)}
            />
            <label className="clickable" htmlFor="inHouseRadioButton">
              In House
            </label>
            <Form.Check
              name="group1"
              type="radio"
              checked={!isInHouseValue}
              onChange={() => setIsInHouseValue(false)}
              id="thirdPartyRadioButton"
            />
            <label className="clickable" htmlFor="thirdPartyRadioButton">
              Third-Party
            </label>
          </div>
          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText">
              Enter basic details of contractor
            </p>
          </div>
          <div className="d-flex addContractorInputContainer">
            {formDetails.map((item) => {
              return (
                <EditCustomComponent
                  key={item.label}
                  label={item.label}
                  placeholder={item.placeholder}
                  value={item.value}
                  name={item.name}
                  onChangeFunction={handleChange}
                  onBlurFunction={item.blurFunction}
                  errorMessages={item.errorMessages}
                  isError={item.isError}
                  width="290px"
                />
              );
            })}

            <div className="typeContainer">
              <NewSelectAuto
                disabled={isServiceTypeDisabled}
                setDisabled={setIsServiceTypeDisabled}
                selected={selectedServiceType}
                setSelected={setSelectedServiceType}
                data={serviceTypeFields && serviceTypeFields}
                title={"Wait till the data fetches"}
                type="service type"
                error={serviceTypeSelectError}
                setError={setServiceTypeSelectError}
                fromContractor={true}
                label={"Service type"}
                width="290px"
              />

              {serviceTypeSelectError && (
                <p className="errorMessage"> {errorMessages.serviceType} </p>
              )}
            </div>

            {subServiceTypeFields.length > 0 && (
              <div className="typeContainer">
                <NewSelectAuto
                  disabled={isSubServiceTypeDisabled}
                  setDisabled={setIsSubServiceTypeDisabled}
                  selected={selectedSubServiceType}
                  setSelected={setSelectedSubServiceType}
                  data={subServiceTypeFields && subServiceTypeFields}
                  title={"Please select property first"}
                  type="service sub type"
                  error={subServiceTypeError}
                  setError={setSubServiceTypeError}
                  fromContractor={true}
                  label={"Service sub type"}
                  width="290px"
                />
                {subServiceTypeError && (
                  <p className="errorMessage">
                    {" "}
                    {errorMessages.subServiceType}{" "}
                  </p>
                )}
              </div>
            )}
          </div>

          <div
            className="newTenantDesciptionContainer mb-3"
            style={{ marginTop: "40px" }}
          >
            <p className="newTenantDescriptionText">
              License Detail (Optional)
            </p>
          </div>

          <div className="documentExpiryContainer d-flex flex-wrap">
            <div>
              <p className="formLabel">Upload license document</p>
              {uploadLicenseDocument ? (
                <div
                  style={{ width: "296px" }}
                  className="d-flex align-items-center justify-content-between documentContainerEditForm "
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      width={20}
                      height={24}
                      src={
                        insuranceDocumentFileType === "pdf"
                          ? GetImages.pdfImage
                          : GetImages.imageIcon
                      }
                    />
                    <p className="licenseDocumentText">
                      {uploadLicenseFileName}
                    </p>
                  </div>
                  <img
                    onClick={() => {
                      setUploadLicenseFileName("");
                      setUploadLicenseDocument(null);
                    }}
                    width={20}
                    height={20}
                    src={GetImages.deleteIconRed}
                  />
                </div>
              ) : (
                <FileUploader
                  classes="documentUploaderContractor clickable"
                  handleChange={handleUploadLicenseDocumentChange}
                  children={
                    <>
                      <div className="fileUploaderTenantDocument">
                        <img width={24} height={24} src={GetImages.upload} />
                        <p>
                          Drag & Drop or{" "}
                          <span className="highlightedText">Browse</span>
                        </p>
                      </div>
                    </>
                  }
                />
              )}
            </div>
            <div className="monthComponentItem">
              <p className="monthLabels">Valid until</p>
              <div>
                <Form.Group controlId="formDate">
                  <CustomDatePicker
                    name={"licenseExpiryDate"}
                    type={FORM_CONTROLFIELD_TYPES.DATE}
                    value={licenseExpiryDate}
                    onChange={(e) => handleLicenceExpiryDateChange(e)}
                    placeholder="Select a date"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </div>
              <div></div>
            </div>
          </div>

          <div className="newTenantDesciptionContainer mb-3">
            <p className="newTenantDescriptionText">
              Insurance Detail (Optional)
            </p>
          </div>

          <div className="documentExpiryContainer d-flex flex-wrap ">
            <div>
              <p className="formLabel">Upload insurance document</p>

              {uploadInsuranceDocument ? (
                <div
                  style={{ width: "296px" }}
                  className="d-flex align-items-center justify-content-between documentContainerEditForm "
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      width={20}
                      height={24}
                      src={
                        licenseDocumentFileType === "pdf"
                          ? GetImages.pdfImage
                          : GetImages.imageIcon
                      }
                    />
                    <p className="licenseDocumentText">
                      {uploadInsuranceFileName}
                    </p>
                  </div>
                  <img
                    onClick={() => {
                      setUploadInsuranceDocument(null);
                      setUploadInsuranceFileName("");
                    }}
                    width={20}
                    height={20}
                    src={GetImages.deleteIconRed}
                  />
                </div>
              ) : (
                <FileUploader
                  classes="documentUploaderContractor clickable"
                  handleChange={handleUploadInsuranceDocumentChange}
                  children={
                    <>
                      <div className="fileUploaderTenantDocument">
                        <img width={24} height={24} src={GetImages.upload} />
                        <p>
                          Drag & Drop or{" "}
                          <span className="highlightedText">Browse</span>
                        </p>
                      </div>
                    </>
                  }
                />
              )}
            </div>
            <div className="monthComponentItem">
              <p className="monthLabels">Valid until</p>
              <div>
                <Form.Group controlId="formDate">
                  <CustomDatePicker
                    name={"insuranceExpiryDate"}
                    type={FORM_CONTROLFIELD_TYPES.DATE}
                    value={insuranceExpiryDate}
                    onChange={(e) => handleInsuranceExpiryDateChange(e)}
                    placeholder="Select a date"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "75px" }} className="newTanentBottomBar">
          <div>
            <Button
              onClick={() => {
                navigate("/contractors");
              }}
              className="cancelButtonNewTanentBottomBar"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button type="submit" className="saveEditTenantButton">
              Add Contractor <img src={GetImages.WhiteForwardArrow} />
            </Button>
          </div>
        </div>
      </Form>

      {showSuccessAlert && (
        <SuccessAlert
          message={"Contractor Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </>
  );
};

export default AddContractor;
