import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Offcanvas, Spinner } from "react-bootstrap";
import {
  DocumentService,
  PaymentService,
  PropertyService,
  TenantService,
  UnitService,
  UnitTypesEnum,
  UnitVM,
  EntityType,
  AnalyticsService,
} from "@propertelligent/client-api";
import { AvatarWithLoading } from "../common/avator";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UserLoginData } from "../common/charts/Enums";
import { UnitCreate } from "./create";
import { Area, bed, Bath } from "../../assets/index";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import Tabs from "./tabs";
import { debouncedFunction, getSizeString } from "../../utils/helper";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import { URLS, getBathroomText } from "../../constants/constants";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import PaymentForm from "../common/Payment/PaymentForm";
import SuccessAlert from "../tenants/SuccessAlert";
import ScrollingContainer from "../common/scrollingContainer/ScrollingContainer";
import { useAnalyticsHook } from "../../hooks/useAnalyticsHook";
import { MenuContext } from "../../utils/context/MenuContext";

const EditUnit = () => {
  const { id } = useParams();
  const unitId = parseInt(id);
  const navigate = useNavigate();

  const initialName = "UnknownDoc";
  const initialTenantValues = {
    id: 0,
    firstName: "Vacant",
    lastName: "",
    currentLeaseId: -1,
  };

  const [show, setShow] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [properties, setProperties] = useState([]);
  const [unitData, setUnitData] = useState<UnitVM | any>({});
  const [tenantData, setTenantData] = useState(initialTenantValues);
  const [mainImage, setMainImage] = useState<any>(null);
  const [mainImageId, setMainImageId] = useState<number | null>(null);
  const [unitImages, setUnitImages] = useState([]);
  const [tenantIds, setTenantIds] = useState([]);
  const [availableTenants, setAvailableTenants] = useState([]);
  const [editableFields, setEditableFields] = useState<UnitVM | any>({});
  const [selectedProperty, setSelectedPropery] = useState("");
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [currentDocFileName, setCurrentDocoFileName] = useState(initialName);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPayementAlert, setShowPayementAlert] = useState(false);

  const [activeIndex, setActiveIndex] = useState(1);
  const [tenantApiData, setTenantApiData] = useState<any>({});
  const [tenantLoading, setTenantLoading] = useState(true); // Add loading state
  const [rev_monthValue, setRev_MonthValue] = useState(-12);
  const [fin_monthValue, setFin_MonthValue] = useState(-12);

  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    transactions: true,
    serviceRequest: true,
  });
  const [allDataLoading, setAllDataLoading] = useState(true);

  const customMonthField = "monthYear";
  const fileInputRef = useRef(null);
  const dataFetchedRef = useRef(false); // Ref to track if data has been fetched

  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

  const { loading, rows, fetchData } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountUnitlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  useEffect(() => {
    if (!loading) {
      setLoadingStates({
        ...loadingStates,
        overview: false,
        transactions: false,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (
      !loadingStates.overview &&
      !loadingStates.transactions &&
      !loadingStates.serviceRequest
    ) {
      setAllDataLoading(false);
    }
  }, [loadingStates]);

  const { cashFlow, expensebycategoryArr, fetchAndFormatExpensebycategory } =
    useAnalyticsHook({
      httpExpensebycategoryFn:
        AnalyticsService.getApiAnalyticsUnitExpensebycategory,
      httpCashflowFn: AnalyticsService.getApiAnalyticsUnitCashflow,
      customMonthField,
      parentEntityId: parseInt(id),
      fullyQualifiedFilters: {
        rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
        fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
      },
    });

  const arrangeCustomEditableFields = useCallback((unitObj: any) => {
    const {
      id,
      propertyId,
      mainImageId,
      unitTypeId,
      unitNumber,
      size,
      numberOfBathrooms,
      numberOfBedrooms,
      isBathAttached,
    } = unitObj;
    setEditableFields({
      id,
      propertyId,
      mainImageId,
      unitTypeId,
      unitNumber,
      size,
      numberOfBathrooms,
      numberOfBedrooms,
      isBathAttached,
    });
  }, []);

  const fetchMainImageInEditPage = useCallback((latestMainImageId: number) => {
    if (
      latestMainImageId &&
      latestMainImageId !== 0 &&
      latestMainImageId !== -1
    ) {
      DocumentService.getApiDocumentBase641(latestMainImageId)
        .then((imageResponse: any) => {
          const imageData = "data:image/*;base64," + imageResponse?.success;
          setMainImage(imageData);
        })
        .catch((imageErr: any) => {});
    }
  }, []);

  const fetchUnitDetails = useCallback(() => {
    // if (!dataFetchedRef.current) {

    // Only fetch data if it hasn't been fetched already
    UnitService.getApiUnit1(unitId)
      .then((unitDataRes: any) => {
        arrangeCustomEditableFields(unitDataRes?.success);
        setMainImageId(unitDataRes?.success?.mainImageId);
        setUnitData(unitDataRes?.success);
        setSelectedPropery(unitDataRes?.success?.propertyId);
        setTenantIds(unitDataRes?.success?.tenantIds);
        fetchMainImageInEditPage(unitDataRes?.success?.mainImageId);
        // dataFetchedRef.current = true; // Mark data as fetched
      })
      .catch((unitErr: any) => {});
    // }
  }, [unitId, arrangeCustomEditableFields, fetchMainImageInEditPage]);

  useEffect(() => {
    PropertyService.getApiPropertyOrganizationlookup(orgId)
      .then((response: any) => {
        setProperties(response?.success);
      })
      .catch((ptysErr) => {});

    fetchUnitDetails();

    TenantService.getApiTenantOrganizationlist(orgId)
      .then((tenantsRes: any) => {
        setAvailableTenants(tenantsRes?.success);
      })
      .catch((tenantErr: any) => {});

    PaymentService.getApiPaymentMethodUnitlist(unitId).then((res) => {
      if (res.success) {
        setPaymentMethodsArr(res.success);
      }
    });

    PaymentService.getApiPayeeAccountOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});
  }, [orgId, unitId, fetchUnitDetails]);

  useEffect(() => {
    if (
      tenantData?.currentLeaseId &&
      tenantData?.currentLeaseId !== -1 &&
      tenantData?.currentLeaseId !== 0
    ) {
      debouncedFunction(() => {
        DocumentService.getApiDocumentDetails(tenantData?.currentLeaseId)
          .then((docDetilsRes: any) => {
            if (docDetilsRes?.success) {
              setCurrentDocoFileName(docDetilsRes?.success?.fileName);
            }
          })
          .catch((docDetilsErr) => {});
      }, 500)();
    }
  }, [tenantData?.currentLeaseId]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(UnitTypesEnum).filter(
      (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
    );
    return keys.length > 0 ? keys[0] : undefined;
  }

  const handleSetMainImageId = (newMainImageId: number) => {
    setUnitData((oldData) => ({
      ...oldData,
      mainImageId: newMainImageId,
    }));
    setMainImageId(newMainImageId);
  };

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Unit,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    PaymentService.postApiPaymentDueAccount(updatedValues)
      .then((res: any) => {
        if (res?.success) {
          setShowModel(false);
          setShowPayementAlert(true);
          setTimeout(() => {
            setShowPayementAlert(false);
          }, 3000);
          setTimeout(() => {
            navigate(URLS.UNITS);
          }, 2000);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (unitData?.tenantIds?.[0]) {
      setTenantLoading(true);
      TenantService.getApiTenant1(unitData?.tenantIds?.[0])
        .then((ptRes) => {
          if (ptRes?.success) {
            setTenantApiData(ptRes.success);
          }
        })
        .catch((ptErr) => {})
        .finally(() => {
          setTenantLoading(false);
        });
    }
  }, [unitData?.tenantIds?.[0]]);

  const tabsArr = useMemo(
    () => [
      { id: 1, name: "Overview" },
      { id: 3, name: "Service Requests" },
      { id: 4, name: "Transactions" },
    ],
    []
  );

  const handleClick = useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const checkActive = useCallback(
    (index: number, className: string) =>
      activeIndex === index ? className : "",
    [activeIndex]
  );

  const { isOpen } = useContext(MenuContext);

  return (
    // <ScrollingContainer
    //   Content={() => {
    //     return (

    <>
      {allDataLoading && ( // Check loading state
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div
        style={{ display: loading == true && "none" }}
        className="property-details-container position-relative"
      >
        <div
          className={`sticky-top-property ${
            isOpen ? "sidebar-open" : "sidebar-closed"
          }`}
        >
          <Row>
            <RequestBreadcrumb firstName={"Unit"} link={"/units"} />
          </Row>
          <div
            className="about-property"
            style={{
              borderBottom: "1px solid #E5E7EB",
              paddingBottom: 12,
            }}
          >
            <div className="propertyFirstColumn">
              <div className="p-image">
                <AvatarWithLoading
                  docImage={unitData.mainImageBase64}
                  docNumber={mainImageId || unitData?.mainImageId}
                  avatar={true}
                  Size="Icon60"
                />
              </div>
              <div className="property-details">
                <span className="p-main-text">
                  {getKeyByValue(unitData?.unitTypeId)}
                </span>
                <span className="p-sub-text">{unitData?.unitNumber}</span>
                <div className="d-flex justify-content-start">
                  <div>
                    <div className="p-image" style={{ width: "48px" }}></div>
                    <div className="property-details">
                      {/* Here enemities */}
                      <div style={{ display: "flex" }}>
                        {unitData?.unitTypeId !== 3 ? (
                          <>
                            <span style={{ paddingRight: 4, fontSize: 12 }}>
                              <img src={bed} alt="My Icon" />
                              <span style={{ marginLeft: "4px" }}>
                                {unitData?.numberOfBedrooms}{" "}
                                {getBathroomText(
                                  unitData?.numberOfBedrooms,
                                  "Bedroom"
                                )}
                              </span>
                            </span>

                            <span style={{ paddingLeft: 8, fontSize: 12 }}>
                              <img src={Bath} alt="My Icon" />
                              <span style={{ marginLeft: "4px" }}>
                                {unitData?.numberOfBathrooms}{" "}
                                {getBathroomText(
                                  unitData?.numberOfBathrooms,
                                  "Bathroom"
                                )}
                              </span>
                            </span>
                          </>
                        ) : (
                          <span style={{ paddingLeft: 8, fontSize: 12 }}>
                            <img src={Bath} alt="My Icon" />
                            {unitData?.isBathAttached ? "Attach" : "Studio"}
                          </span>
                        )}
                        <span
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            fontSize: 12,
                          }}
                        >
                          <img src={Area} alt="My Icon" />
                          <span style={{ marginLeft: "4px" }}>
                            {getSizeString(unitData?.size)}
                          </span>
                        </span>
                        {unitData?.tenantIds !== null ? (
                          <>
                            <div
                              style={{ height: 20, lineHeight: 16 }}
                              className="dividerMediumDiv"
                            ></div>
                            <span
                              style={{
                                paddingLeft: 20,
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              ${unitData?.formatted?.Rent}/m (
                              {unitData?.formatted?.LeaseTypeId})
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="property-button">
              {tenantApiData &&
              Object.keys(tenantApiData).length > 0 &&
              rows?.length > 0 &&
              expensebycategoryArr?.length > 0 ? (
                <>
                  <h3 className="editUnitButton clickable" onClick={handleShow}>
                    Edit Unit Details
                  </h3>
                  <AddTraspButton
                    onClick={() => setShowModel(true)}
                    bname="Add Payment"
                    iconPosition="right"
                    border={true}
                  />
                </>
              ) : null}

              {showModel && (
                <Offcanvas
                  className="offcanvas"
                  show={showModel}
                  onHide={() => setShowModel(false)}
                  placement={"end"}
                  scroll={true}
                >
                  <Offcanvas.Header className="canvasHeader" closeButton>
                    <Offcanvas.Title>Add Payment</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <PaymentForm
                      showModel={showModel}
                      entityId={id && Number(id)}
                      payeeAccountsArr={payeeAccountsArr}
                      paymentMethodsArr={paymentMethodsArr}
                      onClose={() => setShowModel(false)}
                      onConfirm={(values: any) =>
                        handleSubmitPaymentAccountRecord(values)
                      }
                      title={"Add Entity Due Payment"}
                      amt={null}
                    />
                  </Offcanvas.Body>
                </Offcanvas>
              )}
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement={"end"}
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                height: "100vh",
                width: "580px",
                background: "white",
              }}
            >
              <Offcanvas.Header className="canvasHeader" closeButton>
                <Offcanvas.Title>Edit Unit</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <UnitCreate
                  showSuccessAlert={showSuccessAlert}
                  setShowSuccessAlert={setShowSuccessAlert}
                  setShow={setShow}
                  unitData={editableFields /*unitData*/}
                  generatedMainImage={mainImage}
                  fetchUnitDetails={fetchUnitDetails}
                  setMainImageId={handleSetMainImageId}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </div>

          <div className="p-tabs" style={{ gap: "20px" }}>
            {tabsArr.map((tab) => (
              <button
                key={`tabBtn${tab.id}`}
                className={`tab overview-sub-text ${checkActive(
                  tab.id,
                  "active"
                )}`}
                onClick={() => handleClick(tab.id)}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>
        <div
          className="panels panel-container bottom-container-unit"
          style={{
            marginTop: "194px",
            paddingTop: "20px",
          }}
        >
          <div
            className="py-3 rounded-2 bottom-container-unit-inside"
            style={{
              height: "calc(100vh - 280px)",
              overflow: "auto",
              border: "1px solid #E5E7EB",
            }}
          >
            <Tabs
              id={id}
              tenantId={unitData?.tenantIds?.[0]}
              paymentMethodsArr={paymentMethodsArr}
              payeeAccountsArr={payeeAccountsArr}
              transactionRows={rows}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              fetchData={fetchData}
              tenantApiData={tenantApiData}
              tenantLoading={tenantLoading}
              expensebycategoryArr={expensebycategoryArr}
              fetchAndFormatExpensebycategory={fetchAndFormatExpensebycategory}
              fin_monthValue={fin_monthValue}
              setFin_MonthValue={setFin_MonthValue}
              loadingStates={loadingStates}
              setLoadingStates={setLoadingStates}
            />
          </div>
        </div>

        {showSuccessAlert && (
          <SuccessAlert
            message={"Unit Edit Successfully"}
            setShowSuccessAlert={setShowSuccessAlert}
          />
        )}
        {showPayementAlert && (
          <SuccessAlert
            message={"Payment Added Successfully"}
            setShowSuccessAlert={setShowPayementAlert}
          />
        )}
      </div>
    </>
    //     );
    //   }}
    // />
  );
};

export default EditUnit;
