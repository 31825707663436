import React, { useState } from "react";
import { ChangeEvent, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { emailRegex } from "../common/Regex";
import { LoginService, OpenAPI, } from "@propertelligent/client-api";
import { TopBar } from "../common/TopBar";
import { MyContext } from "../../utils/context/LoginContext";
import { UserLoginData } from "../common/charts/Enums";
import Inputs from "../common/Inputs";
import OtpInput from "./OtpInput";
import { URLS, USERS, USER_ROLES } from "../../constants/constants";
import SuccessAlert from "../tenants/SuccessAlert";
import { GetImages } from "../../utils/GetImages";
import "./LoginStyles.css";

type Values = {
  email: string;
  password: string;
};

export const Login: React.FC<any> = () => {
  const { isLogin, setIsLogin } = useContext(MyContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [twoFacNotification, setTwoFacNotification] = useState<boolean>(false);
  const [loginFailNotification, setLoginFailNotification] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>(
    "Please enter a valid password"
  );

  const [comingFromSignUp, setComingFromSignUp] = useState<boolean>(false);
  const [comingFromLogout, setComingFromLogout] = useState(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [resendPassword, setResendPassword] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(59);
  const [code, setCode] = useState<string>("");
  const [intervalId, setIntervalId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [historyPageURL, setHistoryPageURL] = useState(window?.location?.pathname);

  const navigate = useNavigate();
  const { state } = useLocation();
  const [values, setValues] = useState<Values>({
    email: "",
    password: "",
  });

  const formatTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  useEffect(() => {
    state &&
      state.isComingFromSignUP &&
      setComingFromSignUp(state.isComingFromSignUP);

    if (state && state?.isComingFromLogout) {
      setComingFromLogout(!!state?.isComingFromLogout);
      setHistoryPageURL(URLS.HOME);
    }

    setTimeout(() => {
      setComingFromSignUp(false);
      setComingFromLogout(false);
    }, 3000);

  }, [state]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == "password") {
      setPasswordError(false);
    }

    if (event.target.name == "email") {
      setEmailError(false);
    }

    setLoginFailNotification(false);
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const toggleShowPassword = (): void => {
    setShowPassword((prevState) => !prevState);
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase(); // Convert email to lowercase
      setValues({ ...values, email: lowercaseEmail }); // Update the email in state with lowercase

      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        // setEmailError({
        //   ...errorMessages,
        //   email: "Please enter a valid email",
        // });
        return true;
      }
    }
    return false;
  };
  // const validateEmail = (): boolean => {
  //   if (values.email.length == 0) {
  //     return false;
  //   }

  //   if (emailRegex.test(values.email)) {
  //     setEmailError(false);
  //     return false;
  //   } else {
  //     setEmailError(true);
  //     return true;
  //   }
  // };

  const handleForgotPassClick = () => {
    navigate(URLS.FORGET_PASSWORD);
  };

  const startCountdown = () => {
    setSeconds(59);
    const id = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          setResendPassword(true);
          clearInterval(id); // Clear the interval when countdown reaches 0
          return prevSeconds; // Return the same value to prevent further state updates
        } else {
          return prevSeconds - 1; // Update the countdown value
        }
      });
    }, 1000);

    // Store the interval ID in state
    setIntervalId(id);
  };

  const stopCountdown = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval
      setIntervalId(null); // Reset the interval ID in state
    }
  };

  const submitLoginForm = (e) => {
    e.preventDefault();
    const emailErrorConst = validateEmail();
    setEmailError(emailErrorConst);

    if (values.email.trim().length === 0) {
      setEmailError(true);
      return;
    }
    if (values.password.trim().length === 0) {
      setPasswordError(true);
      return;
    }

    setLoading(true);

    if (!emailErrorConst) {
      LoginService.postApiLogin(values)
        .then((res: any) => {
          if (res.success.twoFactorRequired === true) {
            setTwoFacNotification(true);
            if (res.success) {
              setTwoFacNotification(true);
              setLoggedIn(true);
              startCountdown();
              setTimeout(() => {
                setTwoFacNotification(false);
              }, 3000);
            } else {
              setLoginFailNotification(true);
            }
            setLoading(false);
          } else {
            setLoading(false);
            localStorage.setItem(UserLoginData.token, res.success.token);
            OpenAPI.TOKEN = res.success.token;

            localStorage.setItem(
              UserLoginData.user_name,
              res.success.firstName + " " + res.success.lastName
            );
            localStorage.setItem(
              UserLoginData.user_id.toString(),
              res.success.userId
            );
            localStorage.setItem(
              UserLoginData.organization_id,
              res.success.organizationUserInfo.organizationId
            );
            localStorage.setItem(
              UserLoginData.currency_symbol,
              res.success.currencySymbol
            );

            localStorage.setItem("userOnboarded", res.success.userOnboarded);
            localStorage.setItem("userRoleId", res?.success?.userRoleId);

            if (USER_ROLES[res?.success?.userRoleId] === USERS.TENANT) {
              localStorage.setItem(
                "tenantInfo",
                JSON.stringify(res?.success?.tenantInfo)
              );
            } else if (
              USER_ROLES[res?.success?.userRoleId] === USERS.CONTRACTOR
            ) {
              localStorage.setItem(
                "contractorInfo",
                JSON.stringify(res?.success?.contractorInfo)
              );
            }

            setIsLogin(true);
            if (res?.success?.userOnboarded == false) {
              navigate(URLS.QUESTIONNAIRE);
            }
            else if (historyPageURL !== URLS.HOME) {
              navigate(historyPageURL);
            }
            else {
              navigate(URLS.HOME);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setLoginFailNotification(true);
        });
    } else {
      setLoading(false);
      setLoginFailNotification(true);
    }
  };

  const handleCodeInputSubmit = (value) => {
    setCodeError(false);
    setCode(value);
    const requestObject = {
      email: values.email,
      password: values.password,
      authenticationCode: value,
    };
    if (value.length == 6) {
      setLoading(true);
      setLoginFailNotification(false);
      // Perform 2FA Login
      LoginService.postApiLogin2Fa(requestObject)
        .then((res: any) => {
          if (res.success) {
            return res;
          } else {
            setLoading(false);
          }
        })
        .then((data) => {

          if (data) {
            localStorage.setItem(UserLoginData.token, data.success.token);
            OpenAPI.TOKEN = data.success.token;

            localStorage.setItem(
              UserLoginData.user_name,
              data.success.firstName + " " + data.success.lastName
            );
            localStorage.setItem(
              UserLoginData.user_id.toString(),
              data.success.userId
            );
            localStorage.setItem(
              UserLoginData.organization_id,
              data.success.organizationUserInfo.organizationId
            );
            localStorage.setItem(
              UserLoginData.currency_symbol,
              data.success.currencySymbol
            );

            localStorage.setItem("userOnboarded", data.success.userOnboarded);
            localStorage.setItem("userRoleId", data?.success?.userRoleId);

            if (USER_ROLES[data?.success?.userRoleId] === USERS.TENANT) {
              localStorage.setItem(
                "tenantInfo",
                JSON.stringify(data?.success?.tenantInfo)
              );
            } else if (
              USER_ROLES[data?.success?.userRoleId] === USERS.CONTRACTOR
            ) {
              localStorage.setItem(
                "contractorInfo",
                JSON.stringify(data?.success?.contractorInfo)
              );
            }
            stopCountdown();

            setIsLogin(true);

            if (data?.success?.userOnboarded == false) {
              navigate(URLS.QUESTIONNAIRE);
            } else {
              navigate(URLS.HOME);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setCodeError(true);
        });
    }
  };

  const goBack = (): void => {
    setLoading(false);
    setLoggedIn(false);
    setResendPassword(false);
  };

  return (
    <>
      {/* Full Screen and Background */}
      <div
        className="loginFullScreen"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${GetImages.background})`,
          paddingBottom: loggedIn || passwordError ? "30px" : "0px",
        }}
      >
        <TopBar haveAccount="Don’t have an account?" signInUp="Sign up" />
        <div className=" containerlogincontainer">
          {loggedIn ? (
            // Once User Logs In/ 2 FA authorization
            <div className="codeVerifier">
              <Form
                onSubmit={() => {
                  handleCodeInputSubmit(code);
                }}
              >
                <p className="codeVerifierTitle">2 Step Verification</p>

                <p className="codeVerifierSubText">
                  Please enter the 6-digit verification code sent on{" "}
                  {values.email}
                </p>

                <div style={{ marginBottom: codeError ? "8px" : "16px" }}>
                  <OtpInput
                    code={code}
                    setCode={setCode}
                    status={loggedIn}
                    handleCodeInputChange={() => {}}
                    comingFrom="login"
                  />
                </div>
                {codeError && (
                  <p
                    className="errorMessageStyles"
                    style={{
                      marginBottom: "16px",
                    }}
                  >
                    Incorrect code. Please try again
                  </p>
                )}

                <div className="resendPasswordBox">
                  {resendPassword ? (
                    <p className="resendpassword">
                      <span className="resendCodeText">Resend code</span>
                    </p>
                  ) : (
                    <p className="resendInText">
                      Resend In
                      <span className="resendCounter">
                        {formatTime(Math.floor(seconds / 60))}:
                        {formatTime(seconds % 60)}
                      </span>
                    </p>
                  )}
                </div>

                <div className="buttonContaier">
                  <Button
                    onClick={goBack}
                    className="goBackButton"
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="submitButton"
                    variant="contained"
                  >
                    {loading ? (
                      <img height={50} src={GetImages.loadingGif} />
                    ) : (
                      "Verify Code"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            // Before User Logs In
            <div className="loginFormWrapper">
              {/* Login Form */}
              <Form onSubmit={submitLoginForm}>
                <p className="loginTitle">Log in</p>

                {/* Email Input */}
                <Inputs
                  labelText="Email*"
                  errorName={emailError}
                  value={values.email}
                  onChangeFunction={handleChange}
                  onBlurFunction={validateEmail}
                  errorMessages={"Please enter a valid email"}
                  id="emailInput"
                  name="email"
                  type="text"
                  placeholder="Email Address"
                  isPassword={false}
                  focus={true}
                />

                {/* Password Input */}
                <Inputs
                  labelText="Password*"
                  errorName={passwordError}
                  value={values.password}
                  onChangeFunction={handleChange}
                  onBlurFunction={() => {}}
                  errorMessages={passwordErrorMessage}
                  id="passwordInput"
                  name="password"
                  type="text"
                  placeholder="Password"
                  isPassword={true}
                  focus={false}
                />

                {/* Login Fail Notification */}
                {loginFailNotification && (
                  <div className="errorBoxes">
                    <p className="errorMessageStyles">
                      Incorrect email or password
                    </p>
                  </div>
                )}

                {/* Remember me and Forget Password */}
                <div className="rememberAndForgetWrapper">
                  <div className="rememberMeBox">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <p className="rememberMe">Remember me</p>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <p
                      className="forgotPassword"
                      onClick={handleForgotPassClick}
                    >
                      Forgot Password?
                    </p>
                  </div>
                </div>

                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || loggedIn}
                  className="loginButton"
                >
                  {loading ? (
                    <img height={50} src={GetImages.loadingGif} />
                  ) : (
                    "Log In"
                  )}
                </Button>
              </Form>
            </div>
          )}

          {/* Two factor authorization message */}

          {twoFacNotification && (
            <SuccessAlert
              setShowSuccessAlert={twoFacNotification}
              message={"2FA Code has been sent to your email"}
            />
          )}

          {comingFromSignUp && (
            <SuccessAlert
              setShowSuccessAlert={comingFromSignUp}
              message={"Email verified successfully"}
            />
          )}

          {comingFromLogout && (
            <SuccessAlert
              setShowSuccessAlert={comingFromLogout}
              message={"Successfully Logged Out"}
            />
          )}

          {/* Reirected from sign up message */}
        </div>
      </div>
    </>
  );
};
