import React, { useState } from "react";
import showPasswordIcon from "../../assets/ShowPasswordIcon.svg";
import { GetImages } from "../../utils/GetImages";

const Inputs = ({
  labelText,
  errorName,
  value,
  onChangeFunction,
  onBlurFunction,
  errorMessages,
  id,
  name,
  type,
  placeholder,
  isPassword,
  focus,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="singleInputsWrapper">
      <p className="labelText">{labelText}</p>
      <div className="passwordInputBox">
        <input
          id={id}
          className="inputClass"
          style={{
            border: errorName ? "2px solid #F05252" : "1px solid #E5E7EB",
            marginBottom: errorName ? "8px" : "16px",
            outlineColor: errorName ? "#E02424" : "#1A56DB",
          }}
          name={name}
          type={isPassword ? (showPassword ? "text" : "password") : type}
          placeholder={placeholder}
          value={value}
          onChange={onChangeFunction}
          onBlur={onBlurFunction}
          autoFocus={focus}
        />

        {isPassword && value.length > 0 ? (
          <span
            onClick={toggleShowPassword}
            className="showPasswordIcon"
            style={{
              backgroundImage: !showPassword
                ? `url(${GetImages.showPasswordIcon})`
                : `url(${GetImages.hidePasswordIcon})`,
              transform: errorName ? "translateY(0%)" : "translateY(-50%)",
              top: errorName ? "30%" : "36%",
            }}
          ></span>
        ) : (
          ""
        )}
      </div>
      {errorName && (
        <div style={{ marginBottom: "16px" }}>
          <p className="errorMessageStyles">{errorMessages}</p>
        </div>
      )}
    </div>
  );
};

export default Inputs;
