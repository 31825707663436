import { UnitTypesEnum } from "@propertelligent/client-api";
import { Blackbed, Blackshower, Blackvector, } from "../../../assets/index";
import RedArrow from "../../../assets/redArrow.png";
import ProgressBar from "../../../components/common/ProgressBar";
import StatusTag from "../../common/StatusTag";
import { getBathroomText } from "../../../constants/constants";
import { AvatarWithLoading } from "../../common/avator";
import EmptyData from "../../common/EmptyData";
import { getSizeString } from "../../../utils/helper";
import "./styles.css";

export const ViewItem = ({ rows, loading, handleItemClick, handleInviteTenantClick, showTarget, notFoundPage = true }) => {

  function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(UnitTypesEnum).filter(
      (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
    );
    return keys.length > 0 ? keys[0] : undefined;
  }

  return (
    <>
      {(rows?.length == 0 && !loading) ? (
        notFoundPage &&
        <EmptyData
          mainText={"Unit list not found"}
          subText={undefined}
          button={undefined}
        />
      ) :
        rows?.map((record) => (
          <div key={record?.id}
            className={"flexFullRow itemContainer"}
            onClick={() => handleItemClick(record?.id)}
          >
            <div className="flexColumn">
              <div className="flexFullRow">
                <div className="flexColumn itemContainerimg">
                  <AvatarWithLoading
                    docNumber={record?.mainImageId}
                    avatar={true}
                    Size="Icon40"
                  />
                </div>
                <div className="flexColumn itemContainerimgDetail">
                  <div className="flexFullRow title">
                    <span style={{ marginRight: 5 }}>{record?.unitNumber || "BG102"}</span>
                    {record?.expensesDue !== 0 &&
                      <>{record?.tenantNames == null ?
                        <StatusTag color={"gray"} statusText={"Vacant "} isLarge={true} /> :
                        <StatusTag
                          color={"red"} icon={<img src={RedArrow} />} isLarge={false}
                          statusText={record?.formatted?.ExpensesDue ? `$ ${record?.formatted?.ExpensesDue} due ` : ""}
                        />}
                      </>}
                  </div>
                  <div className="flexFullRow">
                    <span className="featureType">
                      {record?.property?.address?.streetAddress || record?.property?.name}
                    </span>
                  </div>
                  <div className="flexFullRow">
                    <span className="featureType">
                      {getKeyByValue(record?.unitTypeId)}{" "}
                      {record?.tenantNames?.[0] && (
                        <span>{record?.tenantNames?.[0]}</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* End of first column */}
            <div className="unitNumberOfItem">
              {record?.unitTypeId !== 3 ? (
                <>
                  <div className="feature">
                    <img src={Blackbed} />
                    <span className="overview-small-text">
                      {record?.numberOfBedrooms}{" "}
                      {getBathroomText(record?.numberOfBedrooms, "Bedroom")}
                    </span>
                  </div>
                  <div className="dividerdiv"></div>
                  <div className="feature">
                    <img src={Blackshower} />
                    <span className="overview-small-text">
                      {record?.numberOfBathrooms}{" "}
                      {getBathroomText(record?.numberOfBathrooms, "Bathroom")}
                    </span>
                  </div>
                </>
              ) : (
                <div className="feature">
                  <img src={Blackshower} />
                  <span className="overview-small-text">
                    {record?.isBathAttached ? "Attach" : "Studio"}
                  </span>
                </div>
              )}
              <div className="dividerdiv"></div>
              <div className="feature">
                <img src={Blackvector} />
                <span className="overview-small-text">
                  {" "}
                  {getSizeString(record?.size)}
                </span>
              </div>
            </div>
            {/* End of 2nd column */}
            {showTarget &&
              <div className="unitListProgress" style={{ width: "20%" }}>
                {record?.leaseRemainingMonths !== null ? (
                  <ProgressBar monthsRemaining={record?.leaseRemainingMonths} leaseType={undefined} /> //     <Box className="rightSideContainer">
                ) : (
                  <div className="inviteTenant">
                    <p onClick={handleInviteTenantClick}> + Add Tenant</p>
                  </div>
                )}
              </div>
            }
          </div>
        ))}
    </>
  );
};
