import React from "react";
import { Button, Col, Form, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { UnitTypesEnum } from "@propertelligent/client-api";
import Uploader from "../../firstTimeUserExperience/commonCom/Uploader";
import CounterBox from "../../firstTimeUserExperience/commonCom/IncDecBox";
import GenericSearchAbleDropdown from "../../common/GenericSearchAbleDropdown";
import { CustomInput } from "../../common/formControls";
import AddButtonWithArrow from "../../firstTimeUserExperience/commonCom/AddButtonWithArrow";
import { GetImages } from "../../../utils/GetImages";
import { FORM_CONTROLFIELD_TYPES } from "../../../constants/constants";
import { convertENUMtoJSONArray } from "../../../utils/helper";
import Delete from "../../../assets/Delete.png";
import Replace from "../../../assets/Replace.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import the Bootstrap CSS
import "./style.css";
import SuccessAlert from "../../tenants/SuccessAlert";

interface Property {
  id: number;
  name: string;
  address?: {
    streetAddress: string;
  };
}

interface UnitFormProps {
  title?: string;
  properties?: Property[];
  selectedProperty: any;
  handleChange: any;
  handleDelete?: any;
  mainImage?: any;
  setFileName?: any;
  unitVal?: any;
  handleChangeImage?: any;
  setUnitVal?: any;
  setShow?: any;
  isEditForm?: boolean;
  showSuccessAlert?: any;
  setShowSuccessAlert?: any;
}

const UnitForm: React.FC<UnitFormProps> = ({
  title,
  properties,
  selectedProperty,
  handleChange,
  handleDelete,
  mainImage,
  setFileName,
  unitVal,
  handleChangeImage,
  setUnitVal,
  setShow,
  showSuccessAlert,
  setShowSuccessAlert,
  isEditForm = false,
}) => {
  const navigate = useNavigate();

  const unitTypesOptions = convertENUMtoJSONArray(UnitTypesEnum);

  return (
    <>
      <div className="paymentContainer mt-4 unitForm">
        <div className="paymentInputsContainer">
          <div className="borderLeftTitle">
            <p className="borderLeftText">SELECT PROPERTY</p>
          </div>
          <Row className="paymentinputrow">
            <Col>
              <Form.Group className="mb-2">
                <Form.Label className="overview-sub-text">
                  Select Property
                </Form.Label>
                <Form.Select
                  className="overview-sub-text"
                  required
                  value={unitVal?.propertyId}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Property</option>
                  {properties?.map((property) => (
                    <option key={property?.id} value={property?.id}>
                      {property?.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Label className="overview-sub-text">
                Can't see Property? To create new Property,{" "}
                <span
                  className="navigateMe"
                  onClick={() => navigate("/properties/create")}
                >
                  {" "}
                  click here
                </span>
              </Form.Label>
            </Col>
          </Row>
          <div className="borderLeftTitle">
            <p className="borderLeftText">UNIT DETAILS</p>
          </div>
          {/* Image component here */}

          <Row className="paymentinputrow">
            <Col>
              <div className="uploader-add-tenant">
                <Uploader
                  handleChange={handleChangeImage}
                  handleDelete={handleDelete}
                  mainImage={mainImage}
                  setFileName={setFileName}
                  name="Add image"
                  Size={{ size: "Icon80", iconSize: "Icon20" }}
                />
                {mainImage && (
                  <div>
                    <div className="deletereplacemain">
                      <div
                        onClick={() => setFileName(null)}
                        className="deletereplace"
                      >
                        <Image src={Replace} className="iconstyle" />
                        <p className="replace">Replace</p>
                      </div>
                      <div className="divider"></div>
                      <div onClick={handleDelete} className="deletereplace">
                        <Image src={Delete} className="iconstyle" />
                        <p className="delete">Delete</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="paymentinputrow">
            <Col sm="6">
              <Form.Group>
                <Form.Label className="overview-sub-text">
                  Select unit type*
                </Form.Label>

                <GenericSearchAbleDropdown
                  fieldName={"name"}
                  initialOptions={unitTypesOptions}
                  name={"unitTypeId"}
                  isFormControl={true}
                  value={unitVal?.unitTypeId || ""}
                  required={true}
                  onChange={(e) =>
                    setUnitVal({
                      ...unitVal,
                      unitTypeId: parseInt(e.target.value, 10),
                    })
                  }
                  placeholder="Select Unit Type"
                  myClassName="overview-sub-text"
                />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Label className="overview-sub-text">
                  Unit number*
                </Form.Label>

                <CustomInput
                  name={"unitNumber"}
                  isFormControl
                  value={unitVal?.unitNumber}
                  required
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  onChange={(e) =>
                    setUnitVal({ ...unitVal, unitNumber: e.target.value })
                  }
                  placeholder="Enter Unit number"
                  myClassName="overview-sub-text"
                />
                <Form.Control.Feedback type="invalid">
                  Enter unit number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Form.Group>
                <Form.Label className="labelTextAddUnitTenant">
                  Area Sqft*
                </Form.Label>

                <CustomInput
                  name={"size"}
                  isFormControl={true}
                  value={unitVal?.size}
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  required={true}
                  onChange={(e) =>
                    setUnitVal({ ...unitVal, size: e.target.value })
                  }
                  placeholder="Enter Unit Size in Sqft"
                  myClassName="overview-sub-text"
                />
                <Form.Control.Feedback type="invalid">
                  Enter Area
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="paymentinputrow">
            {unitVal?.unitTypeId == UnitTypesEnum.CondoApartment ? (
              <>
                <div className="parentDiv">
                  <Col sm="6">
                    <Form.Label className="labelTextAddUnitTenant">
                      Bedroom
                    </Form.Label>
                    <CounterBox
                      unitval={unitVal}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitVal?.numberOfBedrooms}
                    />
                  </Col>
                  <Col sm="6">
                    <Form.Label className="labelTextAddUnitTenant">
                      Bathroom
                    </Form.Label>
                    <CounterBox
                      unitval={unitVal}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitVal?.numberOfBathrooms}
                    />
                  </Col>
                </div>
              </>
            ) : unitVal?.unitTypeId == UnitTypesEnum.Room ? (
              <div className="parentDiv">
                <div className="mb-3">
                  <Form.Label className="labelTextAddUnitTenant">
                    Bathroom
                  </Form.Label>
                  <Form.Check
                    inline
                    label="Attached"
                    type="radio"
                    id="attached"
                    style={{ marginLeft: 20 }}
                    checked={unitVal?.isBathAttached === true}
                    onChange={() =>
                      setUnitVal({ ...unitVal, isBathAttached: true })
                    }
                  />
                  <Form.Check
                    inline
                    label="Common"
                    type="radio"
                    id="common"
                    checked={unitVal?.isBathAttached === false}
                    onChange={() =>
                      setUnitVal({ ...unitVal, isBathAttached: false })
                    }
                  />
                </div>
              </div>
            ) : unitVal?.unitTypeId == UnitTypesEnum.Studio ? null : (
              // (
              //   <div className="parentDiv">
              //     <Form.Label className="labelTextAddUnitTenant">
              //       Studio
              //     </Form.Label>
              //   </div>
              // )
              ""
            )}
          </Row>
          <Row className="paymentinputrow mt-4">
            <Col>
              <div className="tenantFormFooter">
                <div className="footerRightButtons">
                  <Button onClick={() => setShow(false)} variant="light">
                    Cancel
                  </Button>
                  <AddButtonWithArrow
                    buttonname={`${isEditForm ? "Edit" : "Add"} Unit`}
                    onClick={undefined}
                    disabled={undefined}
                    iconDirection="left"
                    Icon={GetImages.WhiteForwardArrow}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          message={"Unit added successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </>
  );
};

export default UnitForm;
