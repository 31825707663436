import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";
import { Button, Card, Offcanvas } from "react-bootstrap";
import AddServiceRequestForm from "../AddServiceRequestForm";
import {
  DocumentService,
  LookupService,
  LookupTypesEnum,
  PropertyService,
  ServiceRequestService,
  UnitService,
} from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import { UserLoginData } from "../../common/charts/Enums";
import {
  setShowSuccessAlert,
  setShowSuccessMessage,
} from "../../../redux/actions/commanAction";
import { useDispatch } from "react-redux";

const TopFixedView = ({
  rowCount,
  searchValue,
  setSearchValue,
  handleSort,
  currentSortDirection,
}) => {
  const dispatch: any = useDispatch();
  const [ServiceTypes, setServiceTypes] = useState([]);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //populate service types
    LookupService.getApiLookup(LookupTypesEnum.ServiceTypes)
      .then((response: any) => {
        setServiceTypes(response.success);
      })
      .catch((err) => {
        console.log("Error occured while fetching ServiceTypes->", err);
      });
    PropertyService.getApiPropertyOrganizationlookup(
      parseInt(localStorage.getItem(UserLoginData.organization_id))
    )
      .then((response: any) => {
        setProperties(response.success);
      })
      .catch((err) => {
        console.log("Error occured while fetching Properties->", err);
      });
    UnitService.getApiUnitOrganizationlookup(
      parseInt(localStorage.getItem(UserLoginData.organization_id))
    )
      .then((response: any) => {
        setUnits(response.success);
      })
      .catch((err) => {
        console.log("Error occured while fetching Units->", err);
      });
  }, []);

  const handleSubmitAddServiceRequest = async ({ values, docsArr }) => {
    setIsLoading(true);
    ServiceRequestService.postApiServiceRequest(values)
      .then((servReqRes) => {
        if (servReqRes?.success) {
          dispatch(setShowSuccessAlert(true));
          dispatch(setShowSuccessMessage("Service Added successfully"));
          setTimeout(() => {
            dispatch(setShowSuccessAlert(false));
          }, 3000);
          if (docsArr?.length > 0) {
            const uploadDocsArr = docsArr?.map(
              ({ _rawFile, ...apiDocObj }: any) =>
                DocumentService.postApiDocument({
                  ...apiDocObj,
                  EntityId: servReqRes?.success?.id,
                })
            );
          }
          setIsLoading(false);
          setShowModel(false);
          //fetchData()
        }
      })
      .catch((errServAdd) => {
        setIsLoading(false);
        console.log("Error occured while adding ServiceRequest->", errServAdd);
      });
  };

  useEffect(() => {
    return () => {
      setServiceTypes([]);
      setProperties([]);
      setUnits([]);
    };
  }, []);

  return (
    <>
      <Offcanvas
        className="offcanvas serviceRequestForm"
        show={showModel}
        onHide={() => setShowModel(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Service Request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddServiceRequestForm
            ServiceTypes={ServiceTypes}
            properties={properties}
            units={units}
            isLoading={isLoading}
            onClose={() => setShowModel(false)}
            onConfirm={handleSubmitAddServiceRequest}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <TopFixedBar
        rowCount={rowCount}
        rowCountLabel="Service Requests"
        searchPlaceholder="Search by Description"
        addButtonLabel="Add Service Request"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        addCallback={() => setShowModel(true)}
      />
      <TopFixedSubBar
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
      />
    </>
  );
};

export default TopFixedView;
