import React from "react";

import { GetImages } from "../../utils/GetImages";
const OrganizationSidebar = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="organizationSidebarContainer d-flex flex-column gap-2">
      <div
        className={
          selectedTab === 1
            ? "organizationSidebarTabSelected clickable "
            : "organizationSidebarTab clickable"
        }
        onClick={() => setSelectedTab(1)}
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === 1 ? GetImages.homeIconBlue : GetImages.homeIconBlack
          }
        />
        <p>Basic details</p>
      </div>
      {/* <div
        onClick={() => setSelectedTab(2)}
        className={
          selectedTab === 2
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={selectedTab === 2 ? timeBlue : timeIcon}
        />
        <p>Hours of operations</p>
      </div> */}
      <div
        onClick={() => setSelectedTab(3)}
        className={
          selectedTab === 3
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={
            selectedTab === 3
              ? GetImages.blueDollarIcon
              : GetImages.sharpDollarIcon
          }
        />
        <p>Payment account</p>
      </div>
      <div
        onClick={() => setSelectedTab(4)}
        className={
          selectedTab === 4
            ? "organizationSidebarTabSelected clickable"
            : "organizationSidebarTab clickable"
        }
      >
        <img
          height={20}
          width={20}
          src={selectedTab === 4 ? GetImages.blueUser : GetImages.BlackUser}
        />
        <p>Manage users</p>
      </div>
    </div>
  );
};

export default OrganizationSidebar;
