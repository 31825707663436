import React, { useState } from 'react'
import CircularProgress from './CircularProgress'
import "./uploader.css"
import { GetImages } from '../../../utils/GetImages'

const Uploader = ({name,setMainImage ,mainImage,setFileName,progress,setProgress ,Size}) => {
  const [loading,setLoading]= useState(false);
 
const clear = () => {
    const interval = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? clearInterval(interval) : prevProgress + 10));
    }, 100);
  };

  const handleImageUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];

    clear();

    setTimeout(() => {
      setLoading(false);
      setMainImage(new Blob([file], { type: "image/png|jpg" }));
       setFileName(file.name)
    }, 2000);
  };

  return (
    <>
    <div className="uploaderContainer">
    <div   className={`box ${Size.size}`}>
       {loading ?
       <>
       <div className='d-flex flex-column align-items-center gap-2'>
       <CircularProgress size={Size.pSize} thickness={2} color="blue" progress={progress} uploadImage={Size.uploadimage} />
       <p className='FW500_LH16_sub_text_blue '>Uploading...</p>
       </div>
       </>
     :
       mainImage ?(
           <img src={mainImage instanceof Blob ? URL.createObjectURL(mainImage) : mainImage} alt="uploaded"   className={`${Size.size}`} />
       ):(
           <label htmlFor="upload" className="upload-label">
            <div className="emptyImage">
              <img className={Size.iconSize} src={GetImages.BlueAdd} alt="add" />
              <div className="FW500_LH16_sub_text_blue">{name}</div>
              </div>
               <input 
                name="fileName"
               type="file" id="upload"
               className="upload-input"
               accept="image/*"
               onChange={handleImageUpload}
               />
           </label>
       )}
    </div>
    </div>
    </>
  )
}

export default Uploader
