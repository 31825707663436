import React from "react";
import { useNavigate } from "react-router";

const MenuItemCustom = ({
  to,
  primaryText,
  leftIcon,
  className,
  alt = null,
}) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(to)} className={className}>
      <img src={leftIcon} />

      <p>{primaryText}</p>
    </div>
  );
};

export default MenuItemCustom;
