import React, { useState, useEffect, useRef } from 'react'
import useWindowDimensions from '../../../hooks/windowDimensionsHook';
import './topFixedContainer.css';

const ContentTopBottom = ({ FixedTopContent, FixedBottomContent }) => {

  const [fixedTopContainerHeight, setFixedTopContainerHeight] = useState(0)
  const ref = useRef(null)
  const { height, width } = useWindowDimensions();  
  const appBarHeight = 48;
  const [scrollContainerHeight, setScrollContainerHeight] = useState(height - appBarHeight - fixedTopContainerHeight);

  useEffect(() => {
    setFixedTopContainerHeight(ref.current.clientHeight);
    setScrollContainerHeight(height - appBarHeight - fixedTopContainerHeight);
  });

  return (
    <>
    <div className="position-relative">
    <div className="topFixedContainer" ref={ref}>
        <FixedTopContent />
      </div>
      <div className="scrollingContainer" style={{ height : scrollContainerHeight }}>
        <div className="scrollingContent">
          <FixedBottomContent />
        </div>
      </div>
    </div>
      </>
  );
};

export default ContentTopBottom;
