import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Col, Form, Row } from "react-bootstrap";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  UnitService,
  UnitVM,
} from "@propertelligent/client-api";
import AddTraspButton from "./commonCom/AddTraspButton";
import RadioButton from "../common/RadioButton";
import SuccessAlert from "../tenants/SuccessAlert";
import { getTenantList } from "../../redux/actions/unitAction";
import { GetImages } from "../../utils/GetImages";
import AddButtonWithArrow from "./commonCom/AddButtonWithArrow";
import Uploader from "../common/CustomUploader/Uploader";
import { CustomInput } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES } from "../../constants/constants";
import CounterBox from "./commonCom/IncDecBox";
import Delete from "../../assets/Delete.png";
import Replace from "../../assets/Replace.png";

const UnitTanentForm = ({
  id,
  setShowModel,
  unitId,
  isEdit,
  setISEdit,
  canvasbody,
  showSuccessAlert = false,
  setShowSuccessAlert = (x) => console.log(x),
  showDeleteAlert = false,
  setShowDeleteAlert = (x) => console.log(x),
  showUpdateAlert = false,
  setShowUpdateAlert = (x) => console.log(x),
  setAlertMessage = (x) => console.log(x),
}) => {
  const [fileName, setFileName] = useState("");
  const [mainImage, setMainImage] = useState(null);
  // const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [validated, setValidated] = useState(false);
  const [unitval, setUnitVal] = useState<UnitVM | any>({ propertyId: id });
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { unitList } = useSelector((state: any) => state.unitList);

  const fetchUnitList = async () => {
    await UnitService.getApiUnitPropertylist(Number(id));
  };

  useMemo(() => {}, [unitList?.length, isEdit, unitId]);

  useEffect(() => {
    if (isEdit === true) {
      UnitService.getApiUnit1(unitId)
        .then(async (unitDataRes: any) => {
          setUnitVal(unitDataRes?.success);

          if (unitDataRes?.success?.mainImageId !== 0) {
            DocumentService.getApiDocumentBase641(
              unitDataRes?.success?.mainImageId
            )
              .then((imageResponse: any) => {
                const imageData =
                  "data:image/*;base64," + imageResponse?.success;
                setMainImage(imageData);
              })
              .catch((imageErr: any) => {});
          }
        })
        .catch((unitErr: any) => {});
    } else {
      setUnitVal({
        propertyId: id,
        unitNumber: "",
        size: "",
        unitTypeId: "",
        mainImageId: 0,
        numberOfBathrooms: 0,
        numberOfBedrooms: 0,
        isBathAttached: false,
      });
      setMainImage("");
      setFileName("");
    }
  }, [unitId, isEdit, unitval?.mainImageId, unitList?.length, id]);

  const handleDelete = () => {
    setProgress(0);
    if (!isEdit) {
      setMainImage(null);
      setFileName(null);
      dispatch(getTenantList(id));
    } else {
      DocumentService.deleteApiDocument(unitval?.mainImageId)
        .then((res) => {
          setMainImage(null);
          setFileName(null);

          dispatch(getTenantList(id));
        })
        .catch((error) => {});
    }
  };

  const handleOnChange = (e) => {
    setUnitVal({ ...unitval, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    // Reset form values
    setUnitVal({
      unitNumber: "",
      size: "",
      unitTypeId: "",
      numberOfBathrooms: "",
      numberOfBedrooms: "",
      isBathAttached: false,
    });
    setMainImage("");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    } else {
      if (!isEdit) {
        const response = await UnitService.postApiUnit(unitval);
        console.log("Post Api", response);

        if (mainImage) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Unit,
            EntityId: Number(response?.success?.id),
            DocumentTypeId: DocumentTypesEnum.Other,
            IsMain: true,
            Document: mainImage,
            FileName: fileName,
          };
          await DocumentService.postApiDocument(imgData);
        }
        setAlertMessage("Unit added successfully");
        setShowSuccessAlert(true);
        resetForm();
        // setTimeout(() => {
        setShowModel(false);
        // }, 500);
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);

        dispatch(getTenantList(id));
        fetchUnitList();
        setValidated(false);
      } else {
        const unitData = {
          id: unitval.id,
          propertyId: unitval.propertyId,
          unitNumber: unitval.unitNumber,
          unitTypeId: unitval.unitTypeId,
          size: unitval.size,
          numberOfBedrooms: unitval.numberOfBedrooms,
          numberOfBathrooms: unitval.numberOfBathrooms,
          isBathAttached: unitval.isBathAttached,
        };

        UnitService.putApiUnit(unitData).then((res: any) => {
          if (res.success) {
            setShowUpdateAlert(true);
            setShowModel(false);
            setTimeout(() => {
              setISEdit(false);
            }, 1000);
            setTimeout(() => {
              setShowUpdateAlert(false);
            }, 3000);
          }
        });
      }
    }
  };

  const handleDeleteUnit = () => {
    try {
      UnitService.deleteApiUnit(unitId).then((res) => {
        if (res.success) {
          setShowDeleteAlert(true);
          setShowModel(false);
          setTimeout(() => {
            setISEdit(false);
          }, 1000);
          setTimeout(() => {
            setShowDeleteAlert(false);
          }, 3000);
          resetForm();
          dispatch(getTenantList(id));
        }
      });
    } catch (error) {}
  };

  return (
    <>
      {!isEdit ? (
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
          style={{ marginBottom: "50px" }}
          className={`${
            canvasbody == true ? "unitTanentCanForm" : " unitTanentForm m-3"
          }`}
        >
          <div
            className={`${
              canvasbody == true
                ? "uploader-add-tenant p-3"
                : " uploader-add-tenant  "
            }`}
          >
            <Uploader
              name="Add image"
              setMainImage={setMainImage}
              mainImage={mainImage}
              setFileName={setFileName}
              progress={progress}
              setProgress={setProgress}
              Size={{
                size: "Icon80",
                iconSize: "Icon11",
                pSize: 30,
                uploadimage: "iconstyle10",
              }}
            />

            <div>
              {mainImage && (
                <>
                  <div className="deletereplacemain">
                    <div
                      onClick={() => setFileName(null)}
                      className="deletereplace"
                    >
                      <img src={GetImages.BlueReplace} className="iconstyle" />
                      <p className="replace">Replace</p>
                    </div>

                    <div className="divider"></div>
                    <div onClick={handleDelete} className="deletereplace">
                      <img
                        src={GetImages.deleteIconRed}
                        alt={GetImages.deleteIconRed}
                        className="iconstyle"
                      />
                      <p className="delete">Delete</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="inputParentBox p-3">
            <Form.Group>
              <Form.Label className="FW400_LH20_small_text">
                Select unit type*
              </Form.Label>

              <Form.Select
                className="selectTagAddUnitTenant FW400_LH20_small_text"
                required
                value={unitval?.unitTypeId || ""}
                onChange={(e) =>
                  setUnitVal({
                    ...unitval,
                    unitTypeId: Number(e.target.value),
                  })
                }
                placeholder="Select unit type"
              >
                <option className="FW400_LH20_small_text" value="">
                  Select unit type
                </option>
                <option className="FW400_LH20_small_text" value={1}>
                  Condominium
                </option>
                <option className="FW400_LH20_small_text" value={3}>
                  Room
                </option>
                <option className="FW400_LH20_small_text" value={2}>
                  Studio
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div
            className={`${
              canvasbody == true ? "parentDiv flex-column " : " parentDiv"
            }`}
          >
            <div className="inputParentBox p-3">
              <Form.Group>
                <Form.Label className="FW400_LH20_small_text">
                  Unit number*
                </Form.Label>
                <CustomInput
                  name="unitNumber"
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  value={unitval?.unitNumber}
                  required
                  onChange={(e) => {
                    const inputValue = e?.target?.value;
                    if (parseFloat(inputValue) < 0) {
                      return;
                    }
                    setUnitVal({ ...unitval, unitNumber: inputValue });
                  }}
                  placeholder="Enter unit number"
                  myClassName="containerTextField"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="inputParentBox p-3">
              <Form.Group>
                <Form.Label className="FW400_LH20_small_text">
                  Area Sqft*
                </Form.Label>

                <CustomInput
                  name={"size"}
                  isFormControl={true}
                  value={unitval?.size}
                  required
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  onChange={(e) => {
                    const inputValue = e?.target?.value;

                    if (parseFloat(inputValue) < 0) {
                      return;
                    }
                    setUnitVal({ ...unitval, size: parseInt(inputValue) });
                  }}
                  placeholder="Enter Unit Size in Sqft"
                  myClassName="containerTextField"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          {unitval.unitTypeId === 1 ? (
            <>
              <div
                className={`${
                  canvasbody == true
                    ? "CondominiumSection p-3"
                    : " CondominiumSection "
                }`}
              >
                <div className="condominium">
                  <div className="bedroom">
                    <p className="FW400_LH20_small_text">Bedroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitval.numberOfBedrooms}
                    />
                  </div>
                  <div className="bathroom">
                    <p className="FW400_LH20_small_text">Bathroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitval.numberOfBathrooms}
                    />
                    <div></div>
                  </div>
                </div>
              </div>
            </>
          ) : unitval.unitTypeId === 3 ? (
            <div
              className={`${
                canvasbody == true ? "parentDiv p-3" : " parentDiv "
              }`}
            >
              <div className="inputParentBox">
                <p className="FW400_LH20_small_text">Bathrooms</p>
                <div className="paymentRadioButton">
                  <RadioButton
                    label="Attached"
                    name="isBathAttached"
                    value={unitval.isBathAttached}
                    checked={unitval.isBathAttached === true}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(true),
                      });
                    }}
                  />
                  <RadioButton
                    label="Common"
                    name="isBathAttached"
                    value={unitval?.isBathAttached}
                    checked={unitval?.isBathAttached === false}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(false),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : unitval.unitTypeId === 2 ? (
            ""
          ) : (
            ""
          )}

          <div className="editFormFooter start-0">
            <AddTraspButton
              bname="Cancel"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={() => setShowModel(false)}
              border={false}
            />

            <AddButtonWithArrow
              buttonname="Add Unit"
              disabled={undefined}
              onClick={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </Form>
      ) : (
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => handleSubmit(e)}
          className="unitTanentForm m-3"
        >
          <div className="uploader-add-tenant">
            {/* <Form.Group> */}
            <Uploader
              name="Add image"
              setMainImage={setMainImage}
              mainImage={mainImage}
              setFileName={setFileName}
              progress={progress}
              setProgress={setProgress}
              Size={{
                size: "Icon80",
                iconSize: "Icon11",
                pSize: 30,
                uploadimage: "iconstyle10",
              }}
            />
            <div>
              {mainImage && (
                <>
                  <div className="deletereplacemain">
                    <div
                      onClick={() => setFileName(null)}
                      className="deletereplace"
                    >
                      <img src={Replace} className="iconstyle" />
                      <p className="replace">Replace</p>
                    </div>

                    <div className="divider"></div>
                    <div onClick={handleDelete} className="deletereplace">
                      <img src={Delete} className="iconstyle" />
                      <p className="delete">Delete</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <Row>
            <Col className="inputParentBox">
              <Form.Group>
                <Form.Label className="FW400_LH20_small_text">
                  Select unit type*
                </Form.Label>
                <Form.Select
                  className="selectTagAddUnitTenant "
                  required
                  name="unitTypeId"
                  value={unitval?.unitTypeId || ""}
                  onChange={(e) =>
                    setUnitVal({
                      ...unitval,
                      unitTypeId: Number(e.target.value),
                    })
                  }
                  placeholder="Select unit type"
                >
                  <option value="">Select unit type</option>
                  <option value={1}>Condominium</option>
                  <option value={3}>Room</option>
                  <option value={2}>Studio</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  This field is require
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="parentDiv">
            <Col className="inputParentBox">
              <Form.Group>
                <Form.Label className="FW400_LH20_small_text">
                  Unit number*
                </Form.Label>
                <CustomInput
                  name="unitNumber"
                  isFormControl
                  type={FORM_CONTROLFIELD_TYPES.TEXT}
                  value={unitval?.unitNumber}
                  required
                  onChange={(e) => handleOnChange(e)}
                  placeholder="Enter unit number"
                  myClassName="containerTextField"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="inputParentBox">
              <Form.Group>
                <Form.Label className="FW400_LH20_small_text">
                  Area sq. ft*
                </Form.Label>
                <CustomInput
                  name={"size"}
                  isFormControl={true}
                  value={unitval?.size}
                  required
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  onChange={(e) => {
                    const inputValue = e?.target?.value;

                    if (parseFloat(inputValue) < 0) {
                      return;
                    }
                    setUnitVal({ ...unitval, size: parseInt(inputValue) });
                  }}
                  placeholder="Area in square feet"
                  myClassName="containerTextField"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {unitval.unitTypeId === 1 ? (
            <>
              <div className="CondominiumSection">
                <div className="condominium ">
                  <div className=" bathroom">
                    <p className="   FW400_LH20_small_text">Bathroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bathroom"
                      initialValue={unitval.numberOfBathrooms}
                    />
                  </div>
                  <div className="bedroom">
                    <p className="FW400_LH20_small_text">Bedroom</p>
                    <CounterBox
                      unitval={unitval}
                      setUnitVal={setUnitVal}
                      type="bedroom"
                      initialValue={unitval?.numberOfBedrooms}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : unitval.unitTypeId === 3 ? (
            <div className="parentDiv">
              <div className="inputParentBox">
                <p className="FW400_LH20_small_text">Bathrooms</p>
                <div className="paymentRadioButton">
                  <RadioButton
                    label="Attached"
                    name="isBathAttached"
                    value={unitval.isBathAttached}
                    checked={unitval.isBathAttached === true}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(true),
                      });
                    }}
                  />
                  <RadioButton
                    label="Common"
                    name="isBathAttached"
                    value={unitval?.isBathAttached}
                    checked={unitval?.isBathAttached === false}
                    onChange={(e) => {
                      setUnitVal({
                        ...unitval,
                        isBathAttached: Boolean(false),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : unitval.unitTypeId === 2 ? (
            <div className="parentDiv">
              <p className="FW400_LH20_small_text"> Studio</p>
            </div>
          ) : (
            ""
          )}

          <div className="editFormFooter start-0">
            <div
              className="deletereplace"
              style={{ position: "absolute", left: "12px" }}
              onClick={handleDeleteUnit}
            >
              <img src={Delete} className="iconstyle" />
              <p className="delete">Delete</p>
            </div>

            <AddTraspButton
              bname="Cancel"
              iconPosition={undefined}
              imageValue={undefined}
              onClick={() => setISEdit(false)}
              border={false}
            />

            <AddButtonWithArrow
              buttonname="Update details"
              disabled={undefined}
              onClick={undefined}
              iconDirection="left"
              Icon={GetImages.WhiteForwardArrow}
            />
          </div>
        </Form>
      )}

      {/* {true && (
        <SuccessAlert
          message={"Unit Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )} */}
      {showDeleteAlert && (
        <SuccessAlert
          message={"Unit Deleted Successfully"}
          setShowSuccessAlert={setShowDeleteAlert}
        />
      )}
      {showUpdateAlert && (
        <SuccessAlert
          message={"Unit Updated Successfully"}
          setShowSuccessAlert={setShowDeleteAlert}
        />
      )}
    </>
  );
};

export default UnitTanentForm;
