import {createStore,applyMiddleware, combineReducers} from "redux"
import {thunk} from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"
import { SinglePropertyListReducer, propertyListReducer } from "./redux/reducers/propertyReducer";
import { SingleUnitReducer, unitListReducer } from "./redux/reducers/unitReducer";
import { ServiceRequestReducer } from './redux/reducers/serviceRequestReducer';
import { CommanReducer } from "./redux/reducers/commanReducer";


 const rootReducers = combineReducers({
    propertyList:propertyListReducer,
    singleProperty:SinglePropertyListReducer,
    unitList:unitListReducer,
    singleUnit:SingleUnitReducer,
    ServiceRequest: ServiceRequestReducer,
    Comman: CommanReducer
    
})

const initialState ={
    propertyList:{propertyListReducer},
    singleProperty:{propertyListReducer},
    unitList:{unitListReducer},
    singleUnit:{SingleUnitReducer},
    ServiceRequest: {ServiceRequestReducer},
    Comman: {CommanReducer}

}


const middleware = [thunk]

const store = createStore(rootReducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store 