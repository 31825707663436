import { Route, Routes } from "react-router";
import EditServiceRequest from "./editServiceRequest"
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import { ServiceRequestService } from "@propertelligent/client-api";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";
import { useEffect, useState } from "react";
import "./servicerequest.css"

export const ServiceRequests = () => {

  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [fulllyQualifiedSort, SetFulllyQualifiedSort] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { rows, loading, hasMore, rowCount, fetchData, currentSortDirection, handleSort, currentPage } = infiniteScrollHook({
    httpFetchDataFn: ServiceRequestService.getApiServiceRequestOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
    FullyQualifiedSorts: fulllyQualifiedSort
  });

  useEffect(() => {
    if (searchValue !== "") {
      SetFullyQualifiedFilter(`Description.ToLower().Contains("${searchValue.toLowerCase()}")`);
    } else {
      SetFullyQualifiedFilter(null);
    }
  }, [searchValue]);

  useEffect(() => {
    SetFulllyQualifiedSort('RequestDate ' + currentSortDirection);
  }, [currentSortDirection]);

  return (
    <Routes>
      <Route index element={
        <ContentTopBottom
          FixedTopContent={() => <TopFixedView rowCount={rowCount} currentSortDirection={currentSortDirection} handleSort={handleSort} setSearchValue={setSearchValue} searchValue={searchValue} />}
          FixedBottomContent={() => <MainScrollView rows={rows} loading={loading} hasMore={hasMore} fetchData={fetchData} currentPage={currentPage} />} />
      } />
      <Route path="/edit/:id" element={<EditServiceRequest />} />
    </Routes>
  );
};
