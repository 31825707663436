import TopFixedBar from "../../common/listPageLayout/TopFixedBar";
import TopFixedSubBar from "../../common/listPageLayout/TopFixedSubBar";

const TopFixedView = ({rowCount, searchValue, setSearchValue, handleSort, currentSortDirection}) => {
  return (
    <>
      <TopFixedBar 
        rowCount={rowCount}
        rowCountLabel="Contractors"
        searchPlaceholder="Search by Contractor Name"
        addButtonLabel="Add Contractor"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        navigateTo="create" />
      <TopFixedSubBar 
        currentSortDirection={currentSortDirection}
        handleSort={handleSort}
      />
    </>
  );
};

export default TopFixedView;
