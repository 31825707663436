import React from "react";
import { GetImages } from "../../utils/GetImages";
import { CustomInput } from "../common/formControls";
import "./tenantStyles.css";

const EditCustomComponent = ({
  label,
  placeholder,
  value,
  name,
  onChangeFunction,
  onBlurFunction,
  errorMessages,
  isError,
  width = "216px",
  borderRadius = "8px",
  linkWebsite = false,
  disabled = false
}) => {
  const goToWebsiteHandler = () => {
    window.open("https://" + value, "_blank");
  };

  return (
    <div
      style={{
        width: width,
        borderRadius: borderRadius,
      }}
      className="customEditTenantInputContainerStyles"
    >
      <div>
        <p className="formLabel">{label}</p>
      </div>
      <div className="editInputContainer">
        <CustomInput
          name={name}
          value={value} isFormControl
          onChange={(e) => onChangeFunction(e)}
          placeholder={placeholder}
          myClassName={width == "203px" ? "width_203" : width == "290px" ? "width_290" : "width_216"}
          onBlur={onBlurFunction}
          isError={isError}
          errorMessage={errorMessages}
          disabled={disabled}
        />
        {linkWebsite && value.length > 0 ? (
          <span
            onClick={goToWebsiteHandler}
            className="showPasswordIcon"
            style={{
              backgroundImage: `url(${GetImages.goToWebsiteIcon})`,
              top: "50%",
            }}
          ></span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditCustomComponent;
