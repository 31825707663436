import {
  PropertyService,
  PropertyTypesEnum,
  DocumentService,
  DocumentEntityTypesEnum,
  DocumentTypesEnum,
  UtilityService,
  ServiceRequestService,
  EntityType,
  PaymentService,
  AnalyticsService,
} from "@propertelligent/client-api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceRequestList from "../common/ServiceRequestList";
import EntityPaymentAccountList from "../common/EntityPaymentAccountList";
import { useAnalyticsHook } from "../../hooks/useAnalyticsHook";
import { UserLoginData } from "../common/charts/Enums";

type Values = {
  zip: string;
  city: string;
  stateAbr: string;
};

export const PropertyEdit = () => {
  const [PropertTypes, setPropertTypes] = useState([]);
  const [propertyData, setPropertyData] = useState<any>();
  const [selectedPropertyType, setSelectedProperyType] = useState("");
  const [imageBase64Data, setImageData] = useState<any>();
  const [propertyImages, setPropertyImages] = useState([]);
  const [mainImageId, setMainImageId] = useState<any>();
  const [uploadNew, setUploadNew] = useState(false);
  const [ptyTypeId, setPtyTypeId] = useState();
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [values, setValues] = useState<Values>({
    zip: "",
    city: "",
    stateAbr: "",
  });
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);

  const { id } = useParams();
  const propertyId = parseInt(id);
  const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
  const navigate = useNavigate();
  const customMonthField = "monthYear";
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    unit: true,
    serviceRequest: true,
  });
  const { cashFlow, expensebycategoryArr } = useAnalyticsHook({
    httpExpensebycategoryFn:
      AnalyticsService.getApiAnalyticsPropertyExpensebycategory,
    httpCashflowFn: AnalyticsService.getApiAnalyticsPropertyCashflow,
    customMonthField,
    parentEntityId: propertyId,
    fullyQualifiedFilters: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const FetchZIPCode = (event: any) => {
    if (values.zip != "") {
      UtilityService.getApiUtilityCityStateInfoByZip(values.zip).then(
        (res: any) => {
          if (res?.success) {
            setValues({
              ...values,
              city: res.success.city,
              stateAbr: res.success.stateAbr,
              zip: res?.success?.zip,
            });
            setCity(res?.scucces?.city);
            setState(res?.scucces?.stateAbr);
          }
        }
      );
    }
  };

  useEffect(() => {
    //populate property types
    const lstPropertyTypes = [];
    for (const [propertyKey, propertyValue] of Object.entries(
      PropertyTypesEnum
    )) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      lstPropertyTypes.push({
        id: propertyValue,
        name: propertyKey.replace(/([A-Z])/g, " $1").trim(),
      });
    }
    setPropertTypes(lstPropertyTypes);

    PropertyService.getApiProperty1(propertyId).then((response: any) => {
      setMainImageId(response?.success?.mainImageId);
      setPropertyData(response.success);
      setSelectedProperyType(response.success.propertyTypeId);
      setValues({
        ...values,
        city: response.success?.address.city,
        stateAbr: response.success?.address.state,
        zip: response.success?.address.zip,
      });
      setCity(response?.success?.address?.city);
      setState(response?.success?.address?.stateAbr);
      FetchZIPCode("");
      if (response?.success?.mainImageId !== 0) {
        
      }
      

      PaymentService.getApiPaymentMethodOrganizationlist(orgId)
        .then((ptRes) => {
          if (ptRes?.success) {
            setPaymentMethodsArr(ptRes?.success);
          }
        })
        .catch((ptErr) => { });
    });
    PaymentService.getApiPayeeAccountOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => { });
  }, []);

  const handleChangePropertyImage = async (e) => {
    const blob = new Blob([e], { type: "image/png|jpg" });

    let imgData = {
      EntityTypeId: DocumentEntityTypesEnum.Property,
      EntityId: Number(id),
      DocumentTypeId: DocumentTypesEnum.BeforeImage,
      IsMain: false,
      Document: blob,
      FileName: e?.name,
    };
    try {
      await DocumentService.postApiDocument(imgData);
    } catch (error) {
      // Handle image upload error
    }
  };

  const handleChangeMainImage = async (e) => {
    const blob = new Blob([e], { type: "image/png|jpg" });
    setImageData(blob);
    setUploadNew(true);

    const imgData = {
      EntityTypeId: DocumentEntityTypesEnum.Property,
      EntityId: Number(id),
      DocumentTypeId: DocumentTypesEnum.BeforeImage,
      IsMain: true,
      FileName: e?.name,
      Document: blob,
    };

    try {
      await DocumentService.postApiDocument(imgData);
    } catch (error) {
      // Handle image upload error
    }
  };

  const handleSubmit = (e: any) => {
    PropertyService.putApiProperty(e.id, e)
      .then((res: any) => {
        if (res.success) {
          navigate("/properties");
        }
      })
      .catch((ptyErr) => { });
  };

  const handleDeleteMainImage = async (
    imgId: number,
    isMain: boolean = false
  ) => {
    try {
      await DocumentService.deleteApiDocument(imgId);
      if (isMain) {
        setImageData(null);
        setUploadNew(false);
      } else {
        const newUnitImages = propertyImages?.filter((u) => u?.id != imgId);
        setPropertyImages(newUnitImages);
      }
    } catch (error) {
      console.error("Error deleting main image:", error);
    }
  };

  const defaultValues = propertyData ? { defaultValues: propertyData } : {};

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap-reverse",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "45%",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "55%",
          paddingLeft: "20px",
        }}
      >
        <ServiceRequestList
        loadingStates={loadingStates}
        setLoadingStates={setLoadingStates}
          setTotalServiceRequest={null}
          id={id}
          httpFetchDataFn={
            ServiceRequestService.getApiServiceRequestPropertylist
          }
        />
        {/* Should we refresh Expense info when new Payee due account reord is inserted? */}
        <EntityPaymentAccountList
          id={id}
          payMethodField={"name"}
          paymentMethodsArr={paymentMethodsArr}
          payeeAccountsArr={payeeAccountsArr}
          entityTypeId={EntityType.Property}
          httpFetchDataFn={PaymentService.getApiPaymentDueAccountPropertylist}
        />
      </div>
    </div>
  );
};
