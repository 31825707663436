import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router";
import { PropertyService } from "@propertelligent/client-api/services/PropertyService";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  LookupTypesEnum,
  TenantService,
  UnitService,
} from "@propertelligent/client-api";
import { UserLoginData } from "../common/charts/Enums";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import CustomTenantInput from "./CustomTenantInput";
import NewSelectAuto from "./NewSelectAuto";
import SuccessAlert from "./SuccessAlert";
import { GetImages } from "../../utils/GetImages";
import { CurrencyInput, CustomDatePicker } from "../common/formControls";
import { FORM_CONTROLFIELD_TYPES, URLS } from "../../constants/constants";
import "./tenantStyles.css";

const CreateForm = () => {
  const navigate = useNavigate();
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [unitSelectError, setUnitSelectError] = useState<boolean>(false);
  const [propertySelectError, setPropertySelectError] =
    useState<boolean>(false);
  const [propertyData, setPropertyData] = useState<any>([]);
  const [unitData, setUnitData] = useState<any>([]);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [isPropertyDisabled, setIsPropertyDisabled] = useState<boolean>(true);
  const [isUnitDisabled, setIsUnitDisabled] = useState<boolean>(true);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<string>("1");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [documentError, setDocumentError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<any>("");
  const [mainImage, setMainImage] = useState<any>();
  const [fileType, setFileType] = useState<string>("image");
  const [uploadDocument, setUploadDocument] = useState<any>();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<any>({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
    unit: "This field is required",
    property: "This field is required",
    startDate: "This field is required",
    endDate: "This field is required",
    amount: "This field is required",
    document: "This field is required",
  });

  const [values, setValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    unitId: selectedUnit,
    userId: null,
  });

  const handleStartDateChange = (e) => {
    setStartDateError(false);
    setStartDate(e.target.value);
    // Perform any additional actions you want when the date changes
  };

  const handleImageChange = (selectedFile: File | null) => {
    setDocumentError(false);

    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setMainImage(blob);
    } else {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setFileType("file");
      fetchImageBlob();
    }
  };

  const fetchImageBlob = async () => {
    try {
      const response = await fetch(GetImages.pdfImage);
      const blob = await response.blob();
      setMainImage(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDateError(false);
    setEndDate(e.target.value);
    // Perform any additional actions you want when the date changes
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
    // Perform any additional actions you want when the radio button changes
  };

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }

    if (!selectedUnit) {
      setUnitSelectError(true);
    }

    if (!selectedProperty) {
      setPropertySelectError(true);
    }

    if (startDate.trim() === "") {
      setStartDateError(true);
    }

    if (endDate.trim() === "") {
      setEndDateError(true);
    }
    if (!amount && selectedOption == "2") {
      setAmountError(true);
    }

    if (!uploadDocument) {
      setDocumentError(true);
    }
  };

  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 20
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name should have 2-20 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 20
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name should have 2-20 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      const lowercaseEmail = values.email.trim().toLowerCase(); // Convert email to lowercase
      setValues({ ...values, email: lowercaseEmail }); // Update the email in state with lowercase

      if (emailRegex.test(lowercaseEmail)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };

  // const validateEmail = (): boolean => {
  //   if (values.email.trim().length > 0) {
  //     if (emailRegex.test(values.email)) {
  //       setEmailError(false);
  //     } else {
  //       setEmailError(true);
  //       setErrorMessages({
  //         ...errorMessages,
  //         email: "Please enter a valid email",
  //       });
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const submitTimeError = () => {
    console.log(uploadDocument, "<===34567");
    
    if (selectedOption === "2") {
      return (
        firstNameError ||
        lastNameError ||
        emailError ||
        propertySelectError ||
        unitSelectError ||
        phoneNumberError ||
        startDateError ||
        endDateError ||
        !uploadDocument ||
        amountError ||
        documentError ||
        values.firstName.trim() === "" ||
        values.lastName.trim() === "" ||
        values.phoneNumber.trim() === "" ||
        values.email.trim() === "" ||
        amount === "" ||
        !selectedUnit
      );
    } else {
      return (
        firstNameError ||
        lastNameError ||
        emailError ||
        propertySelectError ||
        unitSelectError ||
        phoneNumberError ||
        startDateError ||
        amountError ||
        values.firstName.trim() === "" ||
        !uploadDocument ||
        values.lastName.trim() === "" ||
        values.phoneNumber.trim() === "" ||
        values.email.trim() === "" ||
        startDate.trim() === "" ||
        amount === "" ||
        !selectedUnit
      );
    }
  };

  const formSubmitHandler = (e) => {
    checkEmptyFields();
    e.preventDefault();
    if (submitTimeError()) {
    } else {
      console.log("<====hjhjhjhjhj");

      const userId = Number(localStorage.getItem(UserLoginData.user_id));

      const newValues = { ...values, userId: userId, unitId: selectedUnit };
      TenantService.postApiTenant(newValues)

        .then((res) => {
          const tenantId = res.success.id;
          const unitId = newValues.unitId;
          TenantService.putApiTenantAssociate(tenantId, unitId)
            .then((res1) => {
              if (res1) {
                const formattedStartDate = new Date(startDate).toISOString();
                // const formattedEndDate = new Date(endDate).toISOString();

                const requestBody = {
                  tenantId: tenantId,
                  tenantLeaseTypeId: Number(selectedOption),
                  leaseStart: formattedStartDate,
                  // leaseEnd: formattedEndDate,
                  rent: Number(amount),
                };
                TenantService.postApiTenantLease(requestBody)
                  .then((res2) => {
                    if (uploadDocument) {
                      const docData = {
                        EntityTypeId: DocumentEntityTypesEnum.Tenant,
                        EntityId: res2?.success?.id,
                        DocumentTypeId: DocumentTypesEnum.LeaseAgreement,
                        IsMain: false,
                        FileName: fileName,
                        Document: uploadDocument,
                      };

                      DocumentService.postApiDocument(docData)
                        .then((res3) => {
                          setUploadDocument(null);
                          setMainImage(null);
                          setShowSuccessAlert(true);
                          setSelectedProperty("");
                          setStartDate("");
                          setEndDate("");
                          setAmount("");
                          setSelectedUnit("");
                          setValues({
                            ...values,
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            unitId: null,
                          });
                          setTimeout(() => {
                            setShowSuccessAlert(false);
                            navigate(URLS.TENANTS);
                          }, 3000);
                        })
                        .catch((error) => {});
                    }
                  })
                  .catch((error) => {});
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {
          alert("error");
        });
    }
  };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    getPropertyList();
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      UnitService.getApiUnitPropertylist(selectedProperty)
        .then((res) => {
          setUnitData(res.success);
          setIsUnitDisabled(false);
        })
        .catch((err) => {});
    }
  }, [selectedProperty]);

  const getPropertyList = async () => {
    await PropertyService.getApiPropertyOrganizationlookup(organization_id)
      .then((res) => {
        setPropertyData(res.success);
        setIsPropertyDisabled(false);
      })
      .catch((error) => {});
  };

  const formDetails = [
    {
      label: "First name",
      placeholder: "First name",
      value: values.firstName,
      name: "firstName",
      blurFunction: validateFirstName,
      errorMessages: errorMessages.firstName,
      isError: firstNameError,
    },
    {
      label: "Last name",
      placeholder: "Last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: validateLastName,
      errorMessages: errorMessages.lastName,
      isError: lastNameError,
    },
    {
      label: "Email address",
      placeholder: "Email address",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
    },
    {
      label: "Phone number",
      placeholder: "Phone number",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: validateNumber,
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
    },
  ];

  useEffect(() => {
    if (selectedProperty) {
      UnitService.getApiUnitPropertylist(selectedProperty)
        .then((res) => {
          setUnitData(res.success);
        })
        .catch((err) => {});
    }
  }, [selectedProperty]);

  return (
    <div id="tenantFormContainer">
      <div className="singleTenantTopbar">
        <div
          onClick={() => {
            navigate(URLS.TENANTS);
          }}
          className="clickable"
        >
          <p className="singleTenantTopbarHeading">Tenant</p>
        </div>
        <img src={GetImages.BlackChevronRight} />
        <p className="singleTenantTopbarSubHeading">Add tenant</p>
      </div>
      <form className="createTenantForm" onSubmit={formSubmitHandler}>
        <div className="newTenantFormContainer">
          <div className="newTenantFormTopBar d-flex align-items-center">
            <img
              className="clickable me-2"
              onClick={() => {
                navigate(URLS.TENANTS);
              }}
              height={13}
              width={7}
              src={GetImages.leftChevronBlack}
            />
            <p className="newTenantFormHeading"> Add tenant</p>
          </div>
          <div className="newTenantDesciptionContainer">
            <p className="newTenantDescriptionText">
              Enter basic details of tenant
            </p>
          </div>
          <div className="tenantFormContainer">
            {formDetails.map((item) => (
              <CustomTenantInput
                key={item.label}
                label={item.label}
                placeholder={item.placeholder}
                value={item.value}
                name={item.name}
                onChangeFunction={handleChange}
                onBlurFunction={item.blurFunction}
                errorMessages={item.errorMessages}
                isError={item.isError}
              />
            ))}
          </div>
          <div className="newTenantDesciptionContainer">
            <p className="newTenantDescriptionText">Select Property & Unit </p>
          </div>
          <div className="newTenantSelectInputs d-flex">
            <NewSelectAuto
              disabled={isPropertyDisabled}
              setDisabled={setIsPropertyDisabled}
              selected={selectedProperty}
              setSelected={setSelectedProperty}
              data={propertyData && propertyData}
              title={"Wait till the data fetches"}
              type="property"
              setResetDependencies={setUnitData}
              error={propertySelectError}
              setError={setPropertySelectError}
            />
            <NewSelectAuto
              disabled={isUnitDisabled}
              setDisabled={setIsUnitDisabled}
              selected={selectedUnit}
              setSelected={setSelectedUnit}
              data={unitData && unitData}
              title={"Please select property first"}
              type="unit"
              error={unitSelectError}
              setError={setUnitSelectError}
            />

            {unitSelectError && (
              <p className="errorMessage"> {errorMessages.unit} </p>
            )}
          </div>

          <div className="newTenantDesciptionContainer">
            <p className="newTenantDescriptionText">Select Lease type </p>
          </div>

          <div className="radioButtonContainer d-flex">
            <div
              onClick={() => handleRadioChange("2")}
              className="radioButtonItem d-flex justify-content-between clickable"
              style={{ outline: selectedOption === "2" && "1px solid #1A56DB" }}
            >
              <div className="d-flex align-items-center gap-3 ">
                <img src={GetImages.home} alt="Home" />
                <p className="radioButtonOptionText">Fixed type</p>
              </div>
              <div className="d-flex align-items-center">
                <Form.Check
                  name="group1"
                  type="radio"
                  checked={selectedOption === "2"}
                  onChange={() => handleRadioChange("2")}
                />
              </div>
            </div>

            <div
              onClick={() => handleRadioChange("1")}
              className="radioButtonItem d-flex justify-content-between clickable"
              style={{ outline: selectedOption === "1" && "1px solid #1A56DB" }}
            >
              <div className="d-flex align-items-center gap-3 ">
                <img src={GetImages.home} alt="Home" />
                <p className="radioButtonOptionText">Month to Month</p>
              </div>
              <div className="d-flex align-items-center">
                <Form.Check
                  name="group1"
                  type="radio"
                  style={{ outlineColor: selectedOption === "1" && "#1A56DB" }}
                  checked={selectedOption === "1"}
                  onChange={() => handleRadioChange("1")}
                />
              </div>
            </div>
          </div>

          {selectedOption == "2" ? (
            // fixed selected input

            <div className="d-flex fixedComponentContainer ">
              <div className="fixedComponentItem">
                <p className="fixedLabels">Select start date</p>
                <div>
                  <Form.Group controlId="formDate">
                    <CustomDatePicker
                      name={"startDate"}
                      type={FORM_CONTROLFIELD_TYPES.DATE}
                      isFormControl
                      value={startDate || null}
                      onChange={(e) => handleStartDateChange(e)}
                      placeholder="Select a date"
                      myClassName="containerTextField"
                      isError={startDateError}
                      errorMessage={errorMessages.startDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="fixedComponentItem">
                <p className="fixedLabels">Select end date</p>
                <div>
                  <Form.Group controlId="formDate">
                    <CustomDatePicker
                      name={"endDate"}
                      type={FORM_CONTROLFIELD_TYPES.DATE}
                      isFormControl
                      value={endDate || null}
                      onChange={(e) => handleEndDateChange(e)}
                      placeholder="Select a date"
                      myClassName="containerTextField"
                      isError={endDateError}
                      errorMessage={errorMessages.endDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="fixedComponentItem">
                <p className="fixedLabels">Enter amount</p>
                <CurrencyInput
                  name={"amount"}
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  value={amount}
                  isFormControl
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountError(false);
                  }}
                  placeholder="Enter amount"
                  myClassName="containerTextField"
                  isError={amountError}
                  errorMessage={errorMessages.amount}
                />
              </div>
            </div>
          ) : (
            // Month selected input

            <div className="d-flex monthComponentContainer">
              <div className="monthComponentItem">
                <p className="monthLabels">Select start date</p>
                <div>
                  <Form.Group controlId="formDate">
                    <CustomDatePicker
                      name={"startDate"}
                      type={FORM_CONTROLFIELD_TYPES.DATE}
                      isFormControl
                      value={startDate || null}
                      onChange={(e) => handleStartDateChange(e)}
                      placeholder="Select a date"
                      myClassName="containerTextField"
                      isError={startDateError}
                      errorMessage={errorMessages.startDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="monthComponentItem">
                <p className="monthLabels">Enter amount</p>
                <CurrencyInput
                  name={"amount"}
                  type={FORM_CONTROLFIELD_TYPES.NUMBER}
                  value={amount}
                  isFormControl
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountError(false);
                  }}
                  placeholder="Enter amount"
                  myClassName="containerTextField"
                  isError={amountError}
                  errorMessage={errorMessages.amount}
                />
              </div>
            </div>
          )}

          <div className="documentUploadContainer">
            <FileUploader
              handleChange={handleImageChange}
              classes="documentUploader"
              children={
                <>
                  <div className="fileUploaderTenant">
                    <img src={GetImages.upload} />
                    <p className="uploadDoumentHeadingTenant">
                      Upload Documents
                    </p>
                    <p className="uploadDoumentHeadingTenantSubText">
                      Max. size 5MB. .pdf, .png, .jpg
                    </p>
                  </div>
                </>
              }
            />

            {uploadDocument && (
              <div className="documentPreview">
                <img width={21} height={24} src={GetImages.pdfImage} />
                <p>{fileName ? fileName : "Lease Document"}</p>
              </div>
            )}

            {documentError && (
              <p className="errorMessage"> {errorMessages.document} </p>
            )}
          </div>
        </div>

        <div className="newTanentBottomBar">
          <div>
            <Button
              onClick={() => {
                navigate(URLS.TENANTS);
              }}
              className="cancelButtonNewTanentBottomBar"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button type="submit" className="addButtonNewTanentBottomBar">
              Add Tenant
            </Button>
          </div>
        </div>
      </form>

      {showSuccessAlert && (
        <SuccessAlert
          message={"Tenant Invited Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </div>
  );
};

export default CreateForm;
