import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  TenantService,
} from "@propertelligent/client-api";
import { GetImages } from "../../utils/GetImages";

const UploadDocumentModal = ({
  showDocumentModal,
  handleClose,
  setShowDocumentModal,
  id,
  setAlertMessage,
  setShowSuccessAlert,
}) => {
  const [selectedOption, setSelectedOption] = useState("2");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [amount, setAmount] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [fileName, setFileName] = useState<any>("");
  const [uploadDocument, setUploadDocument] = useState<any>();
  const [mainImage, setMainImage] = useState<any>();
  const [fileType, setFileType] = useState("image");
  const [errorMessages, setErrorMessages] = useState({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
    unit: "This field is required",
    property: "This field is required",
    startDate: "This field is required",
    endDate: "This field is required",
    amount: "This field is required",
    document: "This field is required",
  });

  const handleRadioChange = (value) => {
    setSelectedOption(value);
    // Perform any additional actions you want when the radio button changes
  };

  const handleStartDateChange = (e) => {
    setStartDateError(false);
    setStartDate(e.target.value);
    // Perform any additional actions you want when the date changes
  };
  const handleEndDateChange = (e) => {
    setEndDateError(false);
    setEndDate(e.target.value);
    // Perform any additional actions you want when the date changes
  };

  const fetchImageBlob = async () => {
    try {
      const response = await fetch(GetImages.pdfImage);
      const blob = await response.blob();
      setMainImage(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const handleImageChange = (selectedFile: File | null) => {
    setDocumentError(false);
    if (["jpg", "png", "jpeg"].includes(selectedFile?.name.split(".")[1])) {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setMainImage(blob);
      setFileType("img");
    } else {
      setFileName(selectedFile?.name);
      const blob = new Blob([selectedFile]);
      setUploadDocument(blob);
      setFileType("pdf");
      fetchImageBlob();
    }
  };

  const checkEmptyFields = (): void => {
    if (startDate.trim() === "") {
      setStartDateError(true);
    }

    if (endDate.trim() === "") {
      setEndDateError(true);
    }
    if (!amount && selectedOption == "2") {
      setAmountError(true);
    }

    if (!uploadDocument) {
      setDocumentError(true);
    }
  };

  const submitTimeError = () => {
    if (selectedOption === "2") {
      return (
        startDateError ||
        endDateError ||
        amountError ||
        documentError ||
        amount === ""
      );
    } else {
      return startDateError || startDate.trim() === "";
    }
  };

  const handleDcoumentUploadSubmit = (e) => {
    checkEmptyFields();
    e.preventDefault();
    let formattedStartDate = null;
    let formattedEndDate = null;
    if (startDate) {
      formattedStartDate = new Date(startDate).toISOString();
    }
    if (endDate) {
      formattedEndDate = new Date(endDate).toISOString();
    }

    if (!submitTimeError()) {
      const requestBody = {
        tenantId: id,
        tenantLeaseTypeId: Number(selectedOption),
        leaseStart: formattedStartDate,
        leaseEnd: formattedEndDate,
        rent: Number(amount),
      };

      TenantService.postApiTenantLease(requestBody)
        .then((res2) => {
          if (uploadDocument) {
            const docData = {
              EntityTypeId: DocumentEntityTypesEnum.Tenant,
              EntityId: res2?.success?.id,
              DocumentTypeId: DocumentTypesEnum.LeaseAgreement,
              IsMain: false,
              FileName: fileName,
              Document: uploadDocument,
            };

            DocumentService.postApiDocument(docData)
              .then((res3) => {
                setAlertMessage("Document uploaded successfully");
                setShowSuccessAlert(true);
                setShowDocumentModal(false);
                setUploadDocument(null);
                setMainImage(null);
                setStartDate("");
                setEndDate("");
                setAmount("");
                setTimeout(() => {
                  setShowSuccessAlert(false);
                }, 3000);
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div>
      <Modal
        className="uploadDocumentModalClass"
        show={showDocumentModal}
        onHide={handleClose}
      >
        <form onSubmit={handleDcoumentUploadSubmit}>
          <div>
            <div className="newTenantDesciptionContainer">
              <p className="newTenantDescriptionText">Select Lease type </p>
            </div>

            <div className="radioButtonContainer d-flex">
              <div
                onClick={() => handleRadioChange("2")}
                className="radioButtonItem d-flex justify-content-between"
              >
                <div className="d-flex align-items-center gap-3 ">
                  <img src={GetImages.home} alt="Home" />
                  <p className="radioButtonOptionText">Fixed type</p>
                </div>
                <div className="d-flex align-items-center">
                  <Form.Check
                    name="group1"
                    type="radio"
                    checked={selectedOption === "2"}
                    onChange={() => handleRadioChange("2")}
                  />
                </div>
              </div>

              <div
                onClick={() => handleRadioChange("1")}
                className="radioButtonItem d-flex justify-content-between"
              >
                <div className="d-flex align-items-center gap-3 ">
                  <img src={GetImages.home} alt="Home" />
                  <p className="radioButtonOptionText">Month to Month</p>
                </div>
                <div className="d-flex align-items-center">
                  <Form.Check
                    name="group1"
                    type="radio"
                    checked={selectedOption === "1"}
                    onChange={() => handleRadioChange("1")}
                  />
                </div>
              </div>
            </div>

            {selectedOption == "2" ? (
              // fixed selected input

              <div className="d-flex fixedComponentContainer ">
                <div className="fixedComponentItem">
                  <p className="fixedLabels">Select start date</p>
                  <div>
                    <Form.Group controlId="formDate">
                      <Form.Control
                        type="date"
                        value={startDate}
                        placeholder="Select a date"
                        onChange={handleStartDateChange}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    {startDateError && (
                      <p className="errorMessage">
                        {" "}
                        {errorMessages.startDate}{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className="fixedComponentItem">
                  <p className="fixedLabels">Select end date</p>
                  <div>
                    <Form.Group controlId="formDate">
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    {endDateError && (
                      <p className="errorMessage"> {errorMessages.endDate} </p>
                    )}
                  </div>
                </div>
                <div className="fixedComponentItem">
                  <p className="fixedLabels">Enter amount</p>
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      style={{ paddingRight: "40px" }}
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setAmountError(false);
                      }}
                      placeholder="Enter amount"
                    />
                    <img
                      className="dollarImage"
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      width={20}
                      height={20}
                      src={GetImages.greyDollarSign}
                    />
                  </div>
                  <div>
                    {amountError && (
                      <p className="errorMessage"> {errorMessages.amount} </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              // Month selected input

              <div className="d-flex monthComponentContainer">
                <div className="monthComponentItem">
                  <p className="monthLabels">Select start date</p>
                  <div>
                    <Form.Group controlId="formDate">
                      <Form.Control
                        type="date"
                        value={startDate}
                        placeholder="Select a date"
                        onChange={handleStartDateChange}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    {startDateError && (
                      <p className="errorMessage">
                        {" "}
                        {errorMessages.startDate}{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className="monthComponentItem">
                  <p className="monthLabels">Enter amount</p>
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <input
                      style={{ paddingRight: "40px" }}
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setAmountError(false);
                      }}
                      placeholder="Enter amount"
                    />
                    <img
                      className="dollarImage"
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      width={20}
                      height={20}
                      src={GetImages.greyDollarSign}
                    />
                  </div>
                  <div>
                    {amountError && (
                      <p className="errorMessage"> {errorMessages.amount} </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="documentUploadContainer">
              <FileUploader
                handleChange={handleImageChange}
                classes="documentUploader"
                children={
                  <>
                    <div className="fileUploaderTenant">
                      <img src={GetImages.upload} />
                      <p className="uploadDoumentHeadingTenant">
                        Upload Documents
                      </p>
                      <p className="uploadDoumentHeadingTenantSubText">
                        Max. size 5MB. .pdf, .png, .jpg
                      </p>
                    </div>
                  </>
                }
              />

              {uploadDocument && (
                <div className="documentPreview">
                  <img
                    width={21}
                    height={24}
                    src={
                      fileType === "pdf"
                        ? GetImages.pdfImage
                        : GetImages.imageIcon
                    }
                  />
                  <p>{fileName ? fileName : "Lease Document"}</p>
                </div>
              )}

              {documentError && (
                <p className="errorMessage"> {errorMessages.document} </p>
              )}
            </div>

            <div className="addDocumentModalFooter">
              <p
                onClick={() => setShowDocumentModal(false)}
                className="cancelEdit clickable"
              >
                Cancel
              </p>
              <button type="submit" className="uploadDocumentTenantButton">
                Upload <img src={GetImages.WhiteForwardArrow} />
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default UploadDocumentModal;
