import { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./organizationStyles.css";
import OrganizationSidebar from "./OrganizationSidebar";
import BasicDetailsComponent from "./BasicDetailsComponent";
import PaymentAccount from "./PaymentAccount";
import ManageUsers from "./ManageUsers";
import HoursOfOperation from "./HoursOfOperation";
import { GetImages } from "../../utils/GetImages";
import ScrollingContainer from "../common/scrollingContainer/ScrollingContainer";

const MyOrganizationView = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(state ? 4 : 1);

  return (
    // <ScrollingContainer Content={() => {
    //   return (
    <div style={{ background: "white" }}>
      <div className="singleTenantTopbar">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="clickable"
        >
          <p className="singleTenantTopbarHeading">Dashboard</p>
        </div>
        <img src={GetImages.BlackChevronRight} />
        <p className="singleTenantTopbarSubHeading">My Organisation</p>
      </div>
      <div className="organizationHeading">
        <p>My Organization</p>
      </div>
      <div className="organizationContentContainer d-flex">
        <OrganizationSidebar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="rightSideContentOrganization">
          {selectedTab === 1 && <BasicDetailsComponent />}
          {/* {selectedTab === 2 && <HoursOfOperation />} */}
          {selectedTab === 3 && <PaymentAccount />}
          {selectedTab === 4 && <ManageUsers />}
        </div>
      </div>
    </div>
    //   )
    // }} />
  );
};

export default memo(MyOrganizationView);
